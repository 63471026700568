/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { Applogo } from "../../Routes/ImagePath";
// import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider, Field } from "formik";
// import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { useDispatch } from "react-redux";
// import { login } from "../../../user";
// import { resetFunctionwithlogin } from "../../../components/ResetFunction";
import { adminMe, userSignIn } from "../appRedux/actions/auth";
import { Applogo } from "../Routes/ImagePath";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { hideMessage, showMessage as messageShow } from "../appRedux/actions/common";
// import { login } from "../../../user";

const INITIAL_STATE = {
  email: "",
  password: "",
};

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const messageStatus = useSelector((state) => state.common.messageStatus);
  const message = useSelector((state) => state.common.message);
  const showMessage = useSelector((state) => state.common.showMessage);
  useEffect(() => {
    console.log({ showMessage, message, messageStatus });
    if (showMessage && message !== "") {
      if (messageStatus === "error") {
        toast.error(message);
      } else {
        toast.success(message);
      }
    }
  }, [showMessage, message, messageStatus]);

  const [eye, seteye] = useState(true);
  const [loader, setLoader] = useState(false);

  const onEyeClick = () => {
    seteye(!eye);
  };
  useEffect(() => {
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
  }, []);
  const [formInfo, setFormInfo] = useState(INITIAL_STATE);
  const FormSchema = Yup.object().shape({
    email: Yup.string().email("Please enter a valid email address").required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .max(20, "Password must be at most 20 characters")
      .required("Password is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInfo,
    validationSchema: FormSchema,
    onSubmit: (data) => {
      setLoader(true);
      dispatch(hideMessage());
      dispatch(userSignIn({ ...data, authLevel: 1 }))
        .then((accessToken) => {
          if (accessToken !== "") {
            dispatch(adminMe(accessToken))
              .then((data) => {
                dispatch(
                  messageShow({ userMessage: "Login Successful!", status: 200, error: false })
                );
                setLoader(false);
              })
              .catch(() => {
                setLoader(false);
                dispatch(
                  messageShow({
                    userMessage: "Some error has occurred",
                    status: 500,
                    error: true,
                  })
                );
              });
          }
        })
        .catch(() => {
          setLoader(false);
          // dispatch(
          //   messageShow({
          //     userMessage: "Some error has occurred",
          //     status: 500,
          //     error: true,
          //   })
          // );
        });
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;
  return (
    <div>
      {/* <ToastContainer /> */}
      <div className="account-page">
        <div className="main-wrapper">
          <div className="account-content">
            {/* <Link to="/job-list" className="btn btn-primary apply-btn">
              Apply Job
            </Link> */}
            <div className="container">
              {/* Account Logo */}
              <div className="account-logo">
                <Link to="/">
                  <img src={Applogo} alt="Dreamguy's Technologies" />
                </Link>
              </div>
              {/* /Account Logo */}
              <div className="account-box">
                <div className="account-wrapper">
                  <h3 className="account-title">Login</h3>
                  <p className="account-subtitle">Access to our dashboard</p>
                  {/* Account Form */}
                  <div>
                    <FormikProvider value={formik}>
                      <Form id="contactForm" autoComplete="off" noValidate onSubmit={handleSubmit}>
                        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                        <div className="input-block mb-4">
                          <label className="col-form-label">Email Address</label>
                          <input
                            className={`form-control ${errors?.email ? "error-input" : ""}`}
                            type="email"
                            autoComplete="true"
                            {...getFieldProps("email")}
                          />
                          <span className="text-danger"> {touched.email && errors.email} </span>
                        </div>
                        <div className="input-block mb-4">
                          {/* <div className="row">
                            <div className="col">
                              <label className="col-form-label">Password</label>
                            </div>
                            <div className="col-auto">
                              <Link className="text-muted" to="/forgot-password">
                                Forgot password?
                              </Link>
                            </div>
                          </div> */}
                          <div style={{ position: "relative" }}>
                            <input
                              className={`form-control ${errors?.password ? "error-input" : ""}`}
                              type={eye ? "password" : "text"}
                              {...getFieldProps("password")}
                            />
                            <span
                              style={{
                                position: "absolute",
                                right: "5%",
                                top: "30%",
                              }}
                              onClick={onEyeClick}
                              className={`fa-solid ${eye ? "fa-eye-slash" : "fa-eye"} `}
                            />
                          </div>
                          <span className="text-danger">
                            {" "}
                            {touched.password && errors.password}{" "}
                          </span>
                        </div>
                        <div className="input-block text-center">
                          <button className="btn btn-primary account-btn" type="submit">
                            {loader ? <i class="fas fa-spinner fa-spin me-2" /> : ""}
                            Login
                          </button>
                        </div>
                        {/* </form> */}
                      </Form>
                    </FormikProvider>
                    {/* <div className="account-footer">
                      <p>
                        Don't have an account yet? <Link to="/register">Register</Link>
                      </p>
                    </div> */}
                  </div>
                  {/* /Account Form */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
