import React from "react";
import { Link } from "react-router-dom";

function PagesHeader(props) {
  const {
    pageTitle,
    homePageUrl,
    homePageText,
    innerPageUrl,
    innerPageText,
    activePageText,
    floatRight,
  } = props;

  return (
    <div className="page-header">
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">{pageTitle}</h3>
          <ul className="breadcrumb">
            {homePageText && (
              <li className="breadcrumb-item">
                <Link to={homePageUrl}>{homePageText}</Link>
              </li>
            )}
            {innerPageUrl && innerPageText && (
              <li className="breadcrumb-item">
                <Link to={innerPageUrl}>{innerPageText}</Link>
              </li>
            )}
            {activePageText && (
              <li className="breadcrumb-item active">{activePageText}</li>
            )}
          </ul>
        </div>
        <div className="col-auto float-right ml-auto">{floatRight}</div>
      </div>
    </div>
  );
}

export default PagesHeader;
