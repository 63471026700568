export const PLATFORM_CREATE = "PLATFORM_CREATE";
export const PLATFORM_CREATE_SUCCESS = "PLATFORM_CREATE_SUCCESS";
export const PLATFORM_CREATE_ERROR = "PLATFORM_CREATE_ERROR";
export const PLATFORM_LIST = "PLATFORM_LIST";
export const PLATFORM_LIST_SUCCESS = "PLATFORM_LIST_SUCCESS";
export const PLATFORM_LIST_ERROR = "PLATFORM_LIST_ERROR";
export const PLATFORM_INFO = "PLATFORM_INFO";
export const PLATFORM_INFO_SUCCESS = "PLATFORM_INFO_SUCCESS";
export const PLATFORM_INFO_ERROR = "PLATFORM_INFO_ERROR";
export const PLATFORM_UPDATE_SUCCESS = "PLATFORM_UPDATE_SUCCESS";
export const PLATFORM_UPDATE_ERROR = "PLATFORM_UPDATE_ERROR";
export const PLATFORM_DELETE_SUCCESS = "PLATFORM_DELETE_SUCCESS";
export const PLATFORM_FILTER_HANDLING = "PLATFORM_FILTER_HANDLING";
export const PLATFORM_SEARCH_NAME = "PLATFORM_SEARCH_NAME";
export const PLATFORME_CATEGORY_SUCCESS = "PLATFORM_CATEGORY_SUCCESS";
export const PLATFORM_CATEGORY_CREATE_SUCCESS = "PLATFORM_CATEGORY_CREATE_SUCCESS";
export const PLATFORM_CATEGORY_UPDATE_SUCCESS = "PLATFORM_CATEGORY_UPDATE_SUCCESS";
export const CLEAR_PLATFORM_INFO = "CLEAR_PLATFORM_INFO";
export const PLATFORM_CATEGORY_INFO = "PLATFORM_CATEGORY_INFO";

export const PLATFORM_FILTER_SUCCESS = "PLATFORM_FILTER_SUCCESS";
