import {
  COURSE_LIST,
  COURSE_CREATE_SUCCESS,
  COURSE_LIST_SUCCESS,
  COURSE_INFO_SUCCESS,
  COURSE_FILTER_HANDLING,
  COURSE_SEARCH_NAME,
  COURSE_UPDATE_SUCCESS,
  COURSE_CATEGORY_SUCCESS,
  COURSE_CATEGORY_CREATE_SUCCESS,
  COURSE_CATEGORY_UPDATE_SUCCESS,
  CLEAR_COURSE_INFO,
  COURSE_CATEGORY_INFO,
  COURSE_FILTER_SUCCESS,
} from "../actionTypes/courses";

import {
  COLUMN_HANDLING,
  _CATEGORY_DATA,
  _COURSE_FILTER_DATA,
  _LANGUAGE_DATA,
  _MERCHANT_DATA,
} from "../../_mocks_/course";
// import { COLUMN_HANDLING } from "../../_mocks_/course";

const INIT_STATE = {
  courseFilters: _COURSE_FILTER_DATA,
  courseList: [],
  isSuccess: false,
  courses: [],
  courseInfo: {},
  categories: [],
  categoryInfo: {},
  queryInfo: {
    page: 1,
    size: 10,
    next: false,
    sortByUpdatedAt: "desc",
  },
  totalRecords: 0,
  page: 1,
  pages: 0,
  // columnHandling: COLUMN_HANDLING,
  searchName: "",
  searchStatus: false,
  courseType: "Active",
  setOldQueryInfo: false,
  isUpdate: false,
  isCategory: true,
  isInfo: false,
  currentStep: 0,
  isEdit: false,
};

function CourseReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case COURSE_FILTER_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        courseFilters: { ..._COURSE_FILTER_DATA, ...payload },
      };
    }
    case COURSE_CATEGORY_INFO: {
      return {
        ...state,
        categoryInfo: payload,
      };
    }
    case CLEAR_COURSE_INFO: {
      return {
        ...state,
        courseInfo: {},
      };
    }
    case COURSE_LIST: {
      console.log({ payload });
      return {
        ...state,
        courses: payload,
      };
    }
    case COURSE_CATEGORY_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        categories: payload,
        isCategory: false,
      };
    }
    case COURSE_CATEGORY_CREATE_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        categories: { payload, ...state.categories },
        isCategory: false,
      };
    }
    case COURSE_CATEGORY_UPDATE_SUCCESS: {
      var list = state.categories.map((row) => (row._id === payload._id ? payload : row));
      // console.log({ payload, info });
      return {
        ...state,
        categories: list.filter((row) => row.active),
        isCategory: false,
      };
    }
    case COURSE_LIST_SUCCESS: {
      return {
        ...state,
        searchStatus: false,
        courseList: payload.data,
        queryInfo: {
          ...payload.queryInfo,
        },
        totalRecords: payload.count,
        pages: payload.pages,
        page: payload.page,
        isSuccess: false,
        isCategory: true,
        setOldQueryInfo: false,
        // page: payload.queryInfo.page || 0
      };
    }

    case COURSE_CREATE_SUCCESS: {
      return {
        ...state,
        courseList: [payload, ...state.courseList],
        courseInfo: payload,
        currentStep: payload.currentStep + 1,
        isSuccess: true,
        isEdit: payload && payload._id ? true : false,
        setOldQueryInfo: true,
      };
    }

    case COURSE_UPDATE_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        courseList: state.courseList.map(
          (obj) => (payload.courseId === obj._id ? payload : obj)
          // data.columnHandling.map((row) => obj.id === row)
        ),
        courseInfo: payload,
        isSuccess: true,
        isUpdate: true,
        setOldQueryInfo: true,
      };
    }

    case COURSE_INFO_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        courseInfo: payload,
        isInfo: true,
        isEdit: true,
      };
    }
    case COURSE_FILTER_HANDLING: {
      console.log({ payload });
      return {
        ...state,
        columnHandling: payload.columnHandling,
        courseType: payload.courseType,
      };
    }
    case COURSE_SEARCH_NAME: {
      console.log({ payload });
      return {
        ...state,
        searchName: payload,
        searchStatus: true,
        queryInfo: { page: 1, size: state.queryInfo.size },
      };
    }

    default:
      return state;
  }
}

export default CourseReducer;
