import { combineReducers } from "redux";
import organization from "./organization";
import auth from "./auth";
import common from "./common";
import dashboard from "./dashboard";

import user from "./user";
import courses from "./courses";
import platforms from "./platforms";
import affiliations from "./affiliations";
import categories from "./categories";
import languages from "./languages";
import coursetypes from "./coursetypes";
import staffs from "./staffs";
import faqs from "./faqs";
import level from "./level";
import mode from "./mode";
export default combineReducers({
  organization,
  auth,
  common,
  dashboard,
  courses,
  platforms,
  affiliations,
  categories,
  level,
  mode,
  languages,
  coursetypes,
  staffs,
  faqs,
  user,
});
