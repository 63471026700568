export const STAFF_CREATE = "STAFF_CREATE";
export const STAFF_CREATE_SUCCESS = "STAFF_CREATE_SUCCESS";
export const STAFF_CREATE_ERROR = "STAFF_CREATE_ERROR";
export const STAFF_LIST = "STAFF_LIST";
export const STAFF_LIST_SUCCESS = "STAFF_LIST_SUCCESS";
export const STAFF_LIST_ERROR = "STAFF_LIST_ERROR";
export const STAFF_INFO = "STAFF_INFO";
export const STAFF_INFO_SUCCESS = "STAFF_INFO_SUCCESS";
export const STAFF_INFO_ERROR = "STAFF_INFO_ERROR";
export const STAFF_UPDATE_SUCCESS = "STAFF_UPDATE_SUCCESS";
export const STAFF_UPDATE_ERROR = "STAFF_UPDATE_ERROR";
export const STAFF_DELETE_SUCCESS = "STAFF_DELETE_SUCCESS";
export const STAFF_FILTER_HANDLING = "STAFF_FILTER_HANDLING";
export const STAFF_SEARCH_NAME = "STAFF_SEARCH_NAME";
export const STAFFE_CATEGORY_SUCCESS = "STAFF_CATEGORY_SUCCESS";
export const STAFF_CATEGORY_CREATE_SUCCESS = "STAFF_CATEGORY_CREATE_SUCCESS";
export const STAFF_CATEGORY_UPDATE_SUCCESS = "STAFF_CATEGORY_UPDATE_SUCCESS";
export const CLEAR_STAFF_INFO = "CLEAR_STAFF_INFO";
export const STAFF_CATEGORY_INFO = "STAFF_CATEGORY_INFO";

export const STAFF_FILTER_SUCCESS = "STAFF_FILTER_SUCCESS";
export const STAFF_ROLE_LIST_SUCCESS = "STAFF_ROLE_LIST_SUCCESS";
export const STAFF_PERMISSION_FILTER_SUCCESS = "STAFF_PERMISSION_FILTER_SUCCESS";
