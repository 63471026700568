export const MODE_CREATE = "MODE_CREATE";
export const MODE_CREATE_SUCCESS = "MODE_CREATE_SUCCESS";
export const MODE_CREATE_ERROR = "MODE_CREATE_ERROR";
export const MODE_LIST = "MODE_LIST";
export const MODE_LIST_SUCCESS = "MODE_LIST_SUCCESS";
export const MODE_LIST_ERROR = "MODE_LIST_ERROR";
export const MODE_INFO = "MODE_INFO";
export const MODE_INFO_SUCCESS = "MODE_INFO_SUCCESS";
export const MODE_INFO_ERROR = "MODE_INFO_ERROR";
export const MODE_UPDATE_SUCCESS = "MODE_UPDATE_SUCCESS";
export const MODE_UPDATE_ERROR = "MODE_UPDATE_ERROR";
export const MODE_DELETE_SUCCESS = "MODE_DELETE_SUCCESS";
export const MODE_FILTER_HANDLING = "MODE_FILTER_HANDLING";
export const MODE_SEARCH_NAME = "MODE_SEARCH_NAME";
export const MODEE_MODE_SUCCESS = "MODE_MODE_SUCCESS";
export const MODE_MODE_CREATE_SUCCESS = "MODE_MODE_CREATE_SUCCESS";
export const MODE_MODE_UPDATE_SUCCESS = "MODE_MODE_UPDATE_SUCCESS";
export const CLEAR_MODE_INFO = "CLEAR_MODE_INFO";
export const MODE_MODE_INFO = "MODE_MODE_INFO";

export const MODE_FILTER_SUCCESS = "MODE_FILTER_SUCCESS";
