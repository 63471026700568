import React from "react";
// import "antd/dist/antd.css";
// import "./index.css";
import { Select, Tag } from "antd";
// import "antd/dist/antd.css";
// import "../antdstyle.css";
import moment from "moment";
const { Option } = Select;

const SelectMultipleCustom = (props) => {
  const {
    value,
    onChange,
    options,
    showSearch,
    placeholder,
    tag = "name",
    valueOption = "_id",
    useMoment,
    disabled,
    showArrow = true,
  } = props;
  console.log({ props });
  return (
    <div id="selectCustom">
      {" "}
      <Select
        mode="multiple"
        showarrow={showArrow.toString()}
        showSearch={showSearch}
        style={{ width: "100%" }}
        placeholder={placeholder}
        value={value && value !== "" && value}
        onChange={onChange}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
        }
        maxTagCount="responsive"
        disabled={disabled}
      >
        {options.map((item, key) => {
          return (
            <Option key={Math.random()} value={item[valueOption]}>
              {useMoment ? moment(item[tag]).format("Do MMM YYYY") : item[tag]}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default SelectMultipleCustom;
