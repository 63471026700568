import React from "react";
import DefaultEditor from "react-simple-wysiwyg";

const TextEditor = ({ description, onChange, height }) => {
  return (
    <div id="summernote">
      <DefaultEditor
        value={description}
        // onChange={handleTextChange}
        onChange={(e) => onChange(e.target.value)}
        containerProps={{ style: { height: height } }}
      />
    </div>
  );
};

export default TextEditor;
