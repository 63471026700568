import { DASHBOARD_INFO } from "../actionTypes/dashboard";
const INIT_STATE = {
  count: {
    course: 0,
    affiliation: 0,
    platform: 0,
    enrolled: 0,
  },
};
function DashboardReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case DASHBOARD_INFO: {
      console.log({ payload });
      return {
        ...state,
        count: { ...state.count, ...payload },
      };
    }

    default:
      return state;
  }
}

export default DashboardReducer;
