import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { currencyConverter } from "../../utils/currency";
import ReactStars from "react-stars";
const SafeComponent = ({ htmlContent }) => {
  // Assume htmlContent is sanitized and trusted
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};
const CourseInfo = () => {
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const courseInfo = useSelector((state) => state.courses.courseInfo);
  const currencyData = useSelector((state) => state.common.currencyData);
  const selectedCurrency = useSelector(
    (state) => state.common.selectedCurrency
  );
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const {
    title,
    headlines,
    description,
    image_url,
    merchant,
    rating,
    affiliation,
    author,
    mode,
    duration,
    course_type,
    category,
    website_url,
    out_come,
    review_count,
    language = "English",
  } = courseInfo;
  const merchantName = merchant ? merchant : "edx";
  const price = currencyConverter(
    courseInfo.price,
    courseInfo.price_detail,
    selectedCurrency,
    currencyData
  );

  const discount =
    parseFloat(
      currencyConverter(
        courseInfo.discount,
        courseInfo.price_detail,
        selectedCurrency,
        currencyData
      )
    ) + parseFloat(price);

  let fallbackImage = "";
  //   try {
  //     fallbackImage = require(`../../assets/img/${merchantName}_370x226.webp`).default;
  //   } catch (e) {
  //     fallbackImage = require(`../../assets/img/pluralsight_370x266.webp`).default; // Use a default fallback image
  //   }
  const imageUrl =
    image_url && image_url !== ""
      ? image_url
          .replace("125_H", "480x270")
          .replace("dpr=2", "dpr=0")
          .replace("blur=200", "blur=0")
          .replace("px=8", "px=0")
      : fallbackImage;

  const outComeList =
    out_come && out_come !== ""
      ? out_come.includes("/n")
        ? out_come.includes("/n,")
          ? out_come.split("/n,")
          : out_come.split("/n")
        : out_come.split("*")
      : [];

  const aboutUsList =
    description && description !== ""
      ? description.includes("/n")
        ? description.includes("/n,")
          ? description.split("/n,")
          : description.split("/n")
        : description.split("*")
      : [];

  const duration_per_week = courseInfo.duration_per_week;
  let output = "";
  if (merchant == "edx") {
    output = " at " + duration_per_week;
  } else {
    output = " ";
  }
  return (
    <Fragment>
      {courseInfo && courseInfo._id ? (
        <main>
          <section className="course-details">
            <div className="container">
              <div className="row">
                <div className="col-sm-9 course-details-left">
                  <div
                    className="course-cat-link "
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Link to="#" style={{ color: "#00A79D" }}>
                      {(category && category.name) || ""}
                    </Link>
                  </div>
                  <figure>
                    <img src={affiliation.image} />
                  </figure>
                  <h1>{title}</h1>
                  <p>{headlines}</p>

                  <ul
                    className="ratingTxt"
                    style={{ display: "flex", marginTop: "0" }}
                  >
                    {rating && rating !== "" ? (
                      <ReactStars
                        count={5}
                        // onChange={ratingChanged}
                        value={
                          rating && rating !== ""
                            ? `${rating}`.replace(" stars", "")
                            : "0"
                        }
                        // size={24}
                        edit={false}
                        color2={"#ffd700"}
                        style={{ width: "40px", height: "40px" }}
                      />
                    ) : (
                      <ReactStars
                        count={5}
                        // onChange={ratingChanged}
                        value={"0"}
                        // size={24}
                        edit={false}
                        color2={"#ffd700"}
                        style={{ width: "40px", height: "40px" }}
                      />
                    )}
                    &nbsp;
                    {rating && rating !== "" ? (
                      <li className="list-inline-item me-2">
                        {`${rating}`.replace(" stars", "")}
                      </li>
                    ) : (
                      0
                    )}
                    &nbsp;|
                    <li className="list-inline-item ms-3">
                      Reviews (&nbsp;
                      {review_count
                        ? `${review_count}`
                            .replace("(", "")
                            .replace(")", "")
                            .replace("reviews", "")
                            .trim() || 0
                        : "0"}{" "}
                      )
                    </li>
                  </ul>

                  <div className="price">
                    <div className="price-text ">
                      <h3
                        className="fw-bold"
                        style={{
                          color: "#00A79D",
                          fontSize: "30px",
                          fontWeight: 600,
                          marginBottom: "40px",
                        }}
                      >
                        {price && price !== "" && price !== "0" && price !== 0
                          ? `${selectedCurrency.symbol}${price}`
                          : "Free"}
                      </h3>
                    </div>
                    <div className="price-discount-text ">
                      <h3
                        className="fw-bold"
                        style={{
                          color: "#00A79D",
                          fontSize: "30px",
                          fontWeight: 600,
                        }}
                      >
                        {discount
                          ? `${selectedCurrency.symbol}${discount.toFixed(2)}`
                          : ""}
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="col-sm-3 course-details-right">
                  <figure>
                    <img
                      src={imageUrl}
                      alt="Course Image"
                      style={{ borderRadius: "10px", marginTop: "45px" }}
                    />
                  </figure>

                  <button
                    className="btn add-btn  m-r-5 w-100"
                    title={website_url}
                    onClick={
                      () => window.open(website_url, "_blank")
                      // navigate("/course/" + course._id)
                    }
                    style={{
                      borderRadius: "5px",
                      background: "#00A79D",
                      border: "none",
                      padding: "8px 0",
                      fontSize: "15px",
                    }}
                  >
                    Enroll Now{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#fff"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M18 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h5M15 3h6v6M10 14L20.2 3.8" />
                      </g>
                    </svg>
                  </button>
                </div>

                <div className="col-sm-12">
                  <div className="course-details-bottom ">
                    <h2
                      className="fw-bold"
                      style={{ color: "#00A79D", fontSize: "24px" }}
                    >
                      About Course{" "}
                    </h2>
                    {description.includes("</") ? (
                      <SafeComponent htmlContent={description} />
                    ) : (
                      <>
                        {aboutUsList.map((row, index) => (
                          <p key={index + "-description"}>
                            {row.replace("/n", "")}
                          </p>
                        ))}
                      </>
                    )}

                    {out_come && out_come !== "" ? (
                      <div className="my-2 courseDetailContent mt-5">
                        <h2
                          className="fw-bold"
                          style={{ color: "#00A79D", fontSize: "24px" }}
                        >
                          What You Will Learn?{" "}
                        </h2>
                        <ul>
                          {out_come.includes("</") ? (
                            <SafeComponent htmlContent={out_come} />
                          ) : (
                            <>
                              {outComeList
                                .filter((row) => row.replace("/n", "") !== "")
                                .map((row, index) => (
                                  <li key={index + "-out_come"}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="10"
                                      viewBox="0 0 14 10"
                                      fill="none"
                                    >
                                      <path
                                        d="M13 1L4.75 9L1 5.364"
                                        stroke="black"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                    {row.replace("/n", "")}.
                                  </li>
                                ))}
                            </>
                          )}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className=" px-2 px-lg-0 my-2 mt-5">
                    <h2
                      className="fw-bold mb-3"
                      style={{ color: "#00A79D", fontSize: "24px" }}
                    >
                      This Course Includes{" "}
                    </h2>

                    <div
                      className="row p-3"
                      style={{ boxShadow: "0px 16px 40px 0px #7090B033" }}
                    >
                      <div className="col-lg-3 col-12 mb-4">
                        {
                          <img
                            src={require("../../assets/img/globe.svg").default}
                            alt="icon"
                            style={{ marginRight: ".5rem" }}
                          />
                        }
                        <span>{language}</span>
                      </div>

                      <div className="col-lg-3 col-12 mb-4">
                        {
                          <img
                            src={
                              require("../../assets/img/monitor.svg").default
                            }
                            alt="icon"
                            style={{ marginRight: ".5rem" }}
                          />
                        }
                        <span>Access on Mobile and TV</span>
                      </div>

                      <div className="col-lg-3 col-12 mb-4">
                        {
                          <img
                            src={
                              require("../../assets/img/calendar.svg").default
                            }
                            alt="icon"
                            style={{ marginRight: ".5rem" }}
                          />
                        }
                        <span>
                          {duration} {output}
                        </span>
                      </div>

                      <div className="col-lg-3 col-12 mb-4">
                        {
                          <img
                            src={
                              require("../../assets/img/certificate.svg")
                                .default
                            }
                            alt="icon"
                            style={{ marginRight: ".5rem" }}
                          />
                        }
                        <span>{course_type && course_type.name}</span>
                      </div>

                      <div className="col-lg-3 col-12 mb-4">
                        {
                          <img
                            src={
                              require("../../assets/img/platform.svg").default
                            }
                            alt="icon"
                            style={{ marginRight: ".5rem" }}
                          />
                        }
                        <span>{merchant}</span>
                      </div>

                      <div className="col-lg-3 col-12 mb-4">
                        {
                          <img
                            src={
                              require("../../assets/img/affiliate.svg").default
                            }
                            alt="icon"
                            style={{ marginRight: ".5rem" }}
                          />
                        }
                        <span>{(affiliation && affiliation.name) || "--"}</span>
                      </div>

                      <div className="col-lg-3 col-12 mb-4">
                        {
                          <img
                            src={require("../../assets/img/star.svg").default}
                            alt="icon"
                            style={{ marginRight: ".5rem" }}
                          />
                        }
                        <span>
                          {`${
                            rating
                              ? rating.toString().replace(" stars", "")
                              : "0"
                          } (${
                            review_count
                              ? `${review_count}`
                                  .replace("(", "")
                                  .replace(")", "")
                                  .replace("reviews", "")
                                  .trim() || 0
                              : "0"
                          } Students)`}
                        </span>
                      </div>

                      <div className="col-lg-3 col-12 mb-4">
                        {
                          <img
                            src={
                              require("../../assets/img/learning.svg").default
                            }
                            alt="icon"
                            style={{ marginRight: ".5rem" }}
                          />
                        }
                        <span>{(mode && mode.name) || "--"}</span>
                      </div>
                    </div>
                  </div>

                  <div className=" px-2 px-lg-0 my-2 mt-5">
                    <h2
                      className="fw-bold mb-3"
                      style={{ color: "#00A79D", fontSize: "24px" }}
                    >
                      Web Url{" "}
                    </h2>
                    <div
                      className="row p-3"
                      style={{ boxShadow: "0px 16px 40px 0px #7090B033" }}
                    >
                      {website_url}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      ) : (
        <div
          className="vh-100
        ]"
        ></div>
      )}
    </Fragment>
  );
};

export default CourseInfo;
