import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormView, DialogModal } from ".";
import { showDialog } from "../../appRedux/actions/common";
import { Link, useNavigate } from "react-router-dom";

function EditInfo({ info }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { dialog } = useSelector((state) => ({
    dialog: state.common.dialog,
  }));
  console.log({ info, dialog, open });

  useEffect(() => {
    console.log({ info, dialog, open }, "-----99");
    // if (!dialog) {
    //   console.log({ info, dialog, open }, "---");
    //   setOpen(dialog);
    // }
  }, []);
  const onClick = (info1) => {
    setOpen(true);
    dispatch(showDialog({ title: "Update Profile", size: "lg", id: info1._id }));
  };

  return (
    <Fragment>
      <button className="edit-icon" onClick={() => onClick(info)}>
        <i className="fa-solid fa-pencil"></i>
      </button>
      {/* <button
        className="dropdown-item"
        onClick={() => onClick(info)}
        // onClick={() => navigate("/staff/update/" + info._id)}
        key={"edit_info"}
      >
        <i className="fa fa-pencil m-r-5" /> Edit 
      </button> */}
      {open && (
        <DialogModal
          infoId={info._id}
          key={Math.random()}
          bodyView={<FormView isUpdate={true} reJoin={!info.active} />}
        ></DialogModal>
      )}
    </Fragment>
  );
}

export default EditInfo;
