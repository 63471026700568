import React, { useEffect, useState } from "react";
import { Filter, FloatRight, ListView, PagesHeader } from "../../components/User";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getStaffFilter,
  getStaffPermissionList,
  getStaffRoleList,
  getStaffsList,
} from "../../appRedux/actions/staffs";
import Page from "../../components/Common/Page";

const UserList = () => {
  const dispatch = useDispatch();
  const [initialFetchComplete, setInitialFetchComplete] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [viewStatus, setViewStatus] = useState("listView"); //"listView", "gridView"
  const staffList = useSelector((state) => state.staffs.staffList);
  console.log({ staffList });

  useEffect(() => {
    dispatch(getStaffsList({})).then(() => {
      setInitialFetchComplete(true);
    });
  }, [dispatch]);
  return (
    <Page title="User">
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <PagesHeader
            pageTitle={"User List"}
            homePageUrl="/"
            homePageText="Dashboard"
            // innerPageUrl={""}
            // innerPageText={""}
            activePageText={"User's"}
            floatRight={""}
          />
          {/* /Page Header */}
          <Filter {...{ initialFetchComplete, searchText, setSearchText }} />
          {/* <EmployeeListFilter /> */}

          <div className="card">
            <div className="card-body">
              <ListView {...{ searchText }} />
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </Page>
  );
};

export default UserList;
