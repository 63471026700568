import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { AddInfo } from ".";
function FloatRight(props) {
  const { type,viewStatus, onChange } = props;
  const navigate = useNavigate();
  return (
    <div>
      <AddInfo type={type} />
      {/* <button
        className="btn add-btn  m-r-5"
        onClick={() => {
          navigate("/courses/owner");
        }}
      >
        <i className="fa fa-bars" /> Course Owners
      </button> */}
    </div>
  );
}

export default FloatRight;
