import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Row, Col, Pagination } from "antd";
import { Link } from "react-router-dom";
import { EditInfo, DeleteModal } from ".";
import { getFaqsList } from "../../appRedux/actions/faqs";

function ListView({ type }) {
  const dispatch = useDispatch();
  const { faqList, queryInfo } = useSelector((state) => ({
    faqList: state.faqs.faqList,
    queryInfo: state.faqs.queryInfo,
  }));

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    dispatch(getFaqsList({ type }));
  }, [dispatch, type]);

  const handleTableChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentFaqList = faqList.slice(startIndex, endIndex);

  return (
    <div className="row m-0 mt-3">
      <div className="col-md-12 p-0">
        {currentFaqList.map((item) => (
          <div className="card" key={item._id}>
            <div className="card-body">
              <Col xs={24} sm={24} md={24} lg={24}>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h4>{item.question}</h4>
                    <div className="dropdown dropdown-action">
                      <Link
                        to="#"
                        className="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="material-icons">more_vert</i>
                      </Link>
                      <div className="dropdown-menu dropdown-menu-right">
                        <EditInfo type={type} info={item} key={"edit_info"} />
                        <DeleteModal type={type} info={item} />
                      </div>
                    </div>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                </div>
              </Col>
            </div>
          </div>
        ))}

        <div className="card">
          <div className="card-body ">
            <div className="pagination float-right" style={{ float: "right" }}>
              <Pagination
                total={faqList.length}
                current={currentPage}
                pageSize={pageSize}
                pageSizeOptions={["5", "10", "20", "50"]}
                showSizeChanger
                showTotal={(total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`
                }
                onShowSizeChange={(current, pageSize) => handleTableChange(current, pageSize)}
                itemRender={itemRender}
                onChange={(page, pageSize) => handleTableChange(page, pageSize)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListView;
