export const AFFILIATION_CREATE = "AFFILIATION_CREATE";
export const AFFILIATION_CREATE_SUCCESS = "AFFILIATION_CREATE_SUCCESS";
export const AFFILIATION_CREATE_ERROR = "AFFILIATION_CREATE_ERROR";
export const AFFILIATION_LIST = "AFFILIATION_LIST";
export const AFFILIATION_LIST_SUCCESS = "AFFILIATION_LIST_SUCCESS";
export const AFFILIATION_LIST_ERROR = "AFFILIATION_LIST_ERROR";
export const AFFILIATION_INFO = "AFFILIATION_INFO";
export const AFFILIATION_INFO_SUCCESS = "AFFILIATION_INFO_SUCCESS";
export const AFFILIATION_INFO_ERROR = "AFFILIATION_INFO_ERROR";
export const AFFILIATION_UPDATE_SUCCESS = "AFFILIATION_UPDATE_SUCCESS";
export const AFFILIATION_UPDATE_ERROR = "AFFILIATION_UPDATE_ERROR";
export const AFFILIATION_DELETE_SUCCESS = "AFFILIATION_DELETE_SUCCESS";
export const AFFILIATION_FILTER_HANDLING = "AFFILIATION_FILTER_HANDLING";
export const AFFILIATION_SEARCH_NAME = "AFFILIATION_SEARCH_NAME";
export const AFFILIATIONE_CATEGORY_SUCCESS = "AFFILIATION_CATEGORY_SUCCESS";
export const AFFILIATION_CATEGORY_CREATE_SUCCESS = "AFFILIATION_CATEGORY_CREATE_SUCCESS";
export const AFFILIATION_CATEGORY_UPDATE_SUCCESS = "AFFILIATION_CATEGORY_UPDATE_SUCCESS";
export const CLEAR_AFFILIATION_INFO = "CLEAR_AFFILIATION_INFO";
export const AFFILIATION_CATEGORY_INFO = "AFFILIATION_CATEGORY_INFO";

export const AFFILIATION_FILTER_SUCCESS = "AFFILIATION_FILTER_SUCCESS";
