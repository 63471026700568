import React, { useEffect, useState } from "react";
import { Filter, FloatRight, ListView, PagesHeader } from "../../components/Coursetypes";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Page from "../../components/Common/Page";
import { getCoursetypesList } from "../../appRedux/actions/coursetypes";

const CoursetypeList = () => {
  const dispatch = useDispatch();
  const [initialFetchComplete, setInitialFetchComplete] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [viewStatus, setViewStatus] = useState("listView");
  const  coursetypeList = useSelector((state) => state.coursetypes.coursetypeList);
  console.log({ coursetypeList });

  useEffect(() => {
    dispatch(getCoursetypesList({ page: 1, size: 10 })).then(() => {
      setInitialFetchComplete(true);
    });
  }, [dispatch]);
  return (
    <Page title="Coursetype">
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <PagesHeader
            pageTitle={"Coursetype List"}
            homePageUrl="/"
            homePageText="Dashboard"
            // innerPageUrl={""}
            // innerPageText={""}
            activePageText={"Coursetype's"}
            floatRight={
            <FloatRight viewStatus={viewStatus} onChange={(status) => setViewStatus(status)} />
            }
            />
          {/* /Page Header */}
          <Filter {...{ initialFetchComplete,searchText, setSearchText }} />
          {/* <EmployeeListFilter /> */}

          <div className="card">
            <div className="card-body">
              <ListView {...{searchText}}/>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </Page>
  );
};

export default CoursetypeList;
