import React, { useState } from "react";
import axios from "axios";
import { apiURL } from "../../utils/config";
import { message } from "antd";

const ImageUpload = ({ setImageUrl }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/")) {
      setErrorMessage("");
      const formData = new FormData();
      formData.append("image", file);
      axios
        .post(`${apiURL}/v1/course/imageUpload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          setImageUrl(response.data.results);
          //   message.success("File uploaded successfully!");
        })
        .catch((error) => {
          message.error("Failed to upload file.");
        });
    } else {
      setErrorMessage("Please select a valid image file.");
    }
  };

  return (
    <div>
      <input className="upload" type="file" accept="image/*" onChange={handleFileChange} />
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
    </div>
  );
};

export default ImageUpload;
