// import { SIGNIN_USER_ERROR, SIGNIN_USER_SUCCESS } from '../actionTypes/auth';
import { initURL } from "../actions/common";
import { getStorageData, setStorageData } from "../../utils/useLocalStorage";
import {
  CURRENCY_DATA,
  CHANGE_CURRENCY,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  ON_HIDE_MESSAGE,
  ON_SHOW_MESSAGE,
  INIT_URL,
  SHOW_DIALOG,
  HIDE_DIALOG,
  SHOW_DIALOG_INNER,
  HIDE_DIALOG_INNER,
  ON_SHOW_MESSAGE_IMAGE,
  ON_HIDE_MESSAGE_IMAGE,
  SEARCH_NAME,
  SWITCH_TO,
} from "../actionTypes/common";

const INIT_STATE = {
  loader: false,
  showMessage: false,
  messageStatus: "",
  message: "",
  imageValidation: false,
  initURL: "",
  dialog: false,
  dialogInner: false,
  title: "",
  titleInner: "",
  searchName: "",
  hideHeader: false,
  switchOn: localStorage.getItem("switchOn") || "Employee",
  currencyData: (await getStorageData("currencyData"), []) || [],
  currencyList: [
    {
      symbol: "₹",
      currency: "INR",
    },
    {
      symbol: "$",
      currency: "USD",
    },
  ],
  selectedCurrency: {
    symbol: "₹",
    currency: "INR",
  },
};

function AuthReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_CURRENCY: {
      return { ...state, selectedCurrency: payload };
    }

    case CURRENCY_DATA: {
      setStorageData("currencyData", JSON.stringify(payload));
      console.log({ payload });
      return {
        ...state,
        currencyData: payload,
      };
    }
    case SEARCH_NAME: {
      return { ...state, searchName: payload };
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: payload,
      };
    }

    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }

    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
        // showMessage: false,
      };
    }

    case ON_SHOW_MESSAGE: {
      console.log({ payload }, "ON_SHOW_MESSAGE");
      return {
        ...state,
        loader: false,
        showMessage: true,
        messageStatus: payload.error ? "error" : "success",
        message: payload.userMessage,
      };
    }
    case ON_HIDE_MESSAGE: {
      console.log({ payload }, "ON_HIDE_MESSAGE");
      return {
        ...state,
        loader: false,
        showMessage: false,
        messageStatus: "",
        message: "",
      };
    }
    case ON_SHOW_MESSAGE_IMAGE: {
      console.log({ payload }, "ON_SHOW_MESSAGE");
      return {
        ...state,
        loader: false,
        showMessage: true,
        imageValidation: true,
        messageStatus: payload.error ? "error" : "success",
        message: payload.userMessage,
      };
    }
    case ON_HIDE_MESSAGE_IMAGE: {
      console.log({ payload });
      return {
        ...state,
        loader: false,
        showMessage: false,
        imageValidation: false,
      };
    }
    case SHOW_DIALOG: {
      return {
        ...state,
        dialog: true,
        title: payload.title,
        size: payload.size,
        hideHeader: payload.hideHeader,
        id: payload.id,
      };
    }

    case HIDE_DIALOG: {
      return {
        ...state,
        loader: false,
        dialog: false,
      };
    }

    case SHOW_DIALOG_INNER: {
      return {
        ...state,
        dialogInner: true,
        title: payload.title,
        size: payload.size,
        hideHeader: payload.hideHeader,
        id: payload.id,
      };
    }

    case HIDE_DIALOG_INNER: {
      return {
        ...state,
        loader: false,
        dialogInner: false,
      };
    }
    case SWITCH_TO: {
      localStorage.setItem("switchOn", payload);
      return {
        ...state,
        switchOn: payload,
      };
    }
    default:
      return state;
  }
}

export default AuthReducer;
