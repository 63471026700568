import React from "react";
import { Formik, Form, Field } from "formik";
import { metaTags } from "../../utils/metaTags"; // Import predefined meta tags
import AddMeta from "./AddMeta";

const MetaTag = ({ values, errors, touched, setFieldValue }) => {
  const upperCaseName = (value) => {
    return value
      .split(/[:_]/) // Split by ':' or '_' into an array
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1)) // Capitalize each part
      .join(" "); // Join them with spaces
  };
  const lowerCaseName = (value) => {
    return value
      .split(/[:_]/) // Split by ':' or '_' into an array
      .map((part) => part.toLowerCase()) // Capitalize each part
      .join(" "); // Join them with spaces
  };
  return (
    <>
      <div className="d-flex align-items-center">
        <h4 className="mr-3">Tags</h4>
        <div className="ms-auto">
          <AddMeta
            metaTags={metaTags} // Pass predefined meta tags
            values={values} // Pass Formik values
          />
        </div>
      </div>
      <hr />

      <div className="mt-4 mx-3">
        {/* <h5>Selected Meta Tags:</h5> */}
        <div className="row mx-1">
          {values.meta.map((metaTag, index) => (
            <div key={index} className="col-6 align-items-center mb-3">
              <div className="d-flex align-items-center  ">
                <div className="input-block flex-grow-1 mb-0">
                  <label className="col-form-label">
                    {upperCaseName(
                      metaTag.name || metaTag.property || metaTag.httpEquiv
                    )}
                    {/* {metaTag.name || metaTag.property || "Meta Tag"} */}
                  </label>
                  <Field
                    name={`meta[${index}].content`}
                    placeholder={`Enter ${lowerCaseName(
                      metaTag.name || metaTag.property || metaTag.httpEquiv
                    )}`}
                    className="form-control"
                  />
                </div>
                <div
                  style={{
                    height: "74px",
                    width: "60px",
                    position: "relative",
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-danger ms-2  "
                    style={{
                      height: "38px",
                      margin: "auto",
                      position: "absolute",
                      top: "36px",
                    }}
                    onClick={() => {
                      const updatedMetaTags = [...values.meta];
                      updatedMetaTags.splice(index, 1); // Remove the tag at the given index
                      setFieldValue("meta", updatedMetaTags);
                    }}
                    disabled={["title", "description"].includes(metaTag.name)}
                  >
                    X
                  </button>
                </div>
              </div>
              {touched.meta?.[index]?.content &&
                errors.meta?.[index]?.content && (
                  <div className="error-msg text-danger">
                    {upperCaseName(
                      metaTag.name || metaTag.property || metaTag.httpEquiv
                    )}{" "}
                    {errors.meta[index].content}
                  </div>
                )}
              {/* <div className="row">
                                <div className="col-11">
                                    <div className="input-block">
                                        <label className="col-form-label">
                                            {metaTag.name || metaTag.property || "Meta Tag"}
                                        </label>
                                        <Field
                                            name={`meta[${index}].content`}
                                            placeholder="Enter content"
                                            className="form-control"
                                        />
                                        {touched.meta?.[index]?.content && errors.meta?.[index]?.content && (
                                            <div className="error-msg text-danger">
                                                {errors.meta[index].content}
                                            </div>
                                        )} 
                                    </div>
                                </div>
                                <div class="col-1 d-flex justify-content-end   pt-3 px-3 mt-3"  >
                                    <button
                                        type="button"
                                        class="btn btn-danger mt-2  mx-2"
                                        style={{ height: "38px", padding: "0 12px " }}
                                        onClick={() => {
                                            const updatedMetaTags = [...values.meta];
                                            updatedMetaTags.splice(index, 1); // Remove the tag at the given index
                                            setFieldValue("meta", updatedMetaTags);
                                        }}>
                                        X
                                    </button>
                                </div>


                            </div> */}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MetaTag;
