import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormView, DialogModal } from ".";
import { useNavigate } from "react-router-dom";
import { showDialog } from "../../appRedux/actions/common";
function AddInfo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { dialog } = useSelector((state) => ({
    dialog: state.common.dialog,
  }));
  useEffect(() => {
    if (!dialog) {
      setOpen(dialog);
    }
  }, [dialog]);
  return (
    <Fragment>
      <button
        className="btn add-btn  m-r-5"
        onClick={() => {
          // navigate("/affiliation/create");
          setOpen(true);
          dispatch(showDialog({ title: "Add Affiliation", size: "lg" }));
        }}
      >
        <i className="fa fa-plus" /> Add Affiliation
      </button>
      {open && (
        <DialogModal bodyView={<FormView isUpdate={false} key={Math.random()} />}></DialogModal>
      )}
    </Fragment>
  );
}

export default AddInfo;
