import React, { useEffect, useState } from "react";
import { Filter, FloatRight, ListView, PagesHeader } from "../../components/Platforms";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Page from "../../components/Common/Page";
import { getPlatformsList } from "../../appRedux/actions/platforms";

const PlatformList = () => {
  const dispatch = useDispatch();
  const [initialFetchComplete, setInitialFetchComplete] = useState(false);

  const [viewStatus, setViewStatus] = useState("listView"); //"listView", "gridView"
  const platformList = useSelector((state) => state.platforms.platformList);
  console.log({ platformList });

  useEffect(() => {
    // dispatch(getPlatformsList({ page: 1, size: 10 })).then(() => {
    //   setInitialFetchComplete(true);
    // });
  }, [dispatch]);
  return (
    <Page title="Platform">
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <PagesHeader
            pageTitle={"Platform List"}
            homePageUrl="/"
            homePageText="Dashboard"
            // innerPageUrl={""}
            // innerPageText={""}
            activePageText={"Platform's"}
            floatRight={
              <FloatRight viewStatus={viewStatus} onChange={(status) => setViewStatus(status)} />
            }
          />
          {/* /Page Header */}
          <Filter {...{ initialFetchComplete }} />
          {/* <EmployeeListFilter /> */}

          <div className="card">
            <div className="card-body">
              <ListView />
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </Page>
  );
};

export default PlatformList;
