import {
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_KEY,
  UPDATE_USER_PERSONAL_INFORMATIONS,
  UPDATE_USER_EMERGENCY_CONTACT,
  UPDATE_USER_BANK_INFORMATIONS,
  UPDATE_USER_FAMILY_INFORMATIONS,
  UPDATE_USER_EDUCATION_INFORMATIONS,
  UPDATE_USER_EXPERIENCE_INFORMATIONS,
  SET_USER_EDUCATION_INFORMATIONS,
  SET_USER_EXPERIENCE_INFORMATIONS,
  GET_USER_EDUCATION_INFORMATIONS,
  GET_USER_EXPERIENCE_INFORMATIONS,
  CREATE_USER_EDUCATION_INFORMATIONS,
  CREATE_USER_EXPERIENCE_INFORMATIONS,
  CREATE_USER_FAMILY_INFORMATIONS,
  GET_USER_FAMILY_INFORMATIONS,
  SET_USER_FAMILY_INFORMATIONS,
  DELETE_USER_INFORMATIONS,
  GET_USER_PROJECTS,
  GET_USER_INFO_SETTING,
} from "../actionTypes/user";
import { AUTH_INIT_STATE } from "./auth";

const INIT_STATE = {
  personalInformations: {},
  emergencyContact: {},
  bankInformations: {},
  familyInformations: [],
  educationInformations: [],
  experienceInformations: [],
  educationInfo: {},
  experienceInfo: {},
  familyInfo: {},
  user: {},
  userInfoSettings: {},
  projects: [],
};

// type: GET_USER_INFO_SETTING,
// payload: data.userInfoSettings,
// informationType: "EducationInformations",
function UserProfileReducer(state = { ...AUTH_INIT_STATE, ...INIT_STATE }, action) {
  const { type, payload } = action;
  switch (type) {
    case DELETE_USER_INFORMATIONS: {
      return {
        ...state,
        familyInformations:
          payload.informationType === "FamilyInformations"
            ? state.familyInformations
                .map((obj) => (payload._id === obj._id ? {} : obj))
                .filter((info) => info._id)
            : state.familyInformations,
        educationInformations:
          payload.informationType === "EducationInformations"
            ? state.educationInformations
                .map((obj) => (payload._id === obj._id ? {} : obj))
                .filter((info) => info._id)
            : state.educationInformations,
        experienceInformations:
          payload.informationType === "ExperienceInformations"
            ? state.experienceInformations
                .map((obj) => (payload._id === obj._id ? {} : obj))
                .filter((info) => info._id)
            : state.experienceInformations,
        // educationInformations: payload,
        // experienceInformations: payload,
      };
    }
    case GET_USER_PROJECTS: {
      return {
        ...state,
        projects: payload,
      };
    }
    case SET_USER_FAMILY_INFORMATIONS: {
      return {
        ...state,
        familyInfo: payload,
      };
    }
    case SET_USER_EDUCATION_INFORMATIONS: {
      return {
        ...state,
        educationInfo: payload,
      };
    }
    case SET_USER_EXPERIENCE_INFORMATIONS: {
      return {
        ...state,
        experienceInfo: payload,
      };
    }
    case UPDATE_USER_PROFILE: {
      // console.log({ payload });
      return {
        ...state,
        user: { ...state.user, ...payload.data },
      };
    }
    case UPDATE_USER_PROFILE_KEY: {
      return {
        ...state,
        user: { ...state.user, ...payload },
      };
    }
    case UPDATE_USER_PERSONAL_INFORMATIONS: {
      return {
        ...state,
        personalInformations: payload,
      };
    }
    case UPDATE_USER_EMERGENCY_CONTACT: {
      return {
        ...state,
        emergencyContact: payload,
      };
    }
    case UPDATE_USER_BANK_INFORMATIONS: {
      return {
        ...state,
        bankInformations: payload || {},
      };
    }
    case GET_USER_FAMILY_INFORMATIONS: {
      return {
        ...state,
        familyInformations: payload,
      };
    }
    case GET_USER_INFO_SETTING: {
      return {
        ...state,
        userInfoSettings: payload,
      };
    }
    case CREATE_USER_FAMILY_INFORMATIONS: {
      return {
        ...state,
        familyInformations: [...state.familyInformations, payload],
      };
    }
    case UPDATE_USER_FAMILY_INFORMATIONS: {
      return {
        ...state,
        // familyInformations: payload,
        familyInformations: state.familyInformations.map((obj) =>
          payload._id === obj._id ? payload : obj
        ),
      };
    }
    case GET_USER_EDUCATION_INFORMATIONS: {
      return {
        ...state,
        educationInformations: payload,
      };
    }
    case GET_USER_EXPERIENCE_INFORMATIONS: {
      return {
        ...state,
        experienceInformations: payload,
      };
    }
    case CREATE_USER_EDUCATION_INFORMATIONS: {
      return {
        ...state,
        educationInformations: [...state.educationInformations, payload],
      };
    }
    case CREATE_USER_EXPERIENCE_INFORMATIONS: {
      return {
        ...state,
        experienceInformations: [...state.experienceInformations, payload],
      };
    }
    case UPDATE_USER_EDUCATION_INFORMATIONS: {
      return {
        ...state,
        educationInformations: state.educationInformations.map((obj) =>
          payload._id === obj._id ? payload : obj
        ),
      };
    }
    case UPDATE_USER_EXPERIENCE_INFORMATIONS: {
      return {
        ...state,
        // experienceInformations: payload,
        experienceInformations: state.experienceInformations.map((obj) =>
          payload._id === obj._id ? payload : obj
        ),
      };
    }
    default:
      return state;
  }
}

export default UserProfileReducer;
