import {
    FAQ_LIST,
    FAQ_CREATE_SUCCESS,
    FAQ_LIST_SUCCESS,
    FAQ_INFO_SUCCESS,
    FAQ_FILTER_HANDLING,
  
    FAQ_UPDATE_SUCCESS,
    CLEAR_FAQ_INFO,
    
  } from "../actionTypes/faqs";
  
  const INIT_STATE = {
    // faqFilters: _FAQ_FILTER_DATA,
    faqList: [],
    isSuccess: false,
    faqs: [],
    faqInfo: {},
    queryInfo: {
      page: 1,
      size: 10,
      next: false,
    },
    totalRecords: 0,
    page: 1,
    pages: 0,
    // columnHandling: COLUMN_HANDLING,
    searchName: "",
    searchStatus: false,
    faqType: "Active",
    isUpdate: false,
    isCategory: true,
    isInfo: false,
    currentStep: 0,
    isEdit: false,
  };
  
  function FaqReducer(state = INIT_STATE, action) {
    const { type, payload } = action;
  
    switch (type) {
     
      case CLEAR_FAQ_INFO: {
        return {
          ...state,
          faqInfo: {},
        };
      }
      case FAQ_LIST: {
        console.log({ payload });
        return {
          ...state,
          faqs: payload,
        };
      }
      
      case FAQ_LIST_SUCCESS: {
        return {
          ...state,
          searchStatus: false,
          faqList: payload.data,
          queryInfo: {
            ...payload.queryInfo,
          },
          totalRecords: payload.count,
          pages: payload.pages,
          isSuccess: false,
          isCategory: true,
          // page: payload.queryInfo.page || 0
        };
      }
  
      case FAQ_CREATE_SUCCESS: {
        return {
          ...state,
          faqList: [payload, ...state.faqList],
          faqInfo: payload,
          currentStep: payload.currentStep + 1,
          isSuccess: true,
          isEdit: payload && payload._id ? true : false,
        };
      }
  
      case FAQ_UPDATE_SUCCESS: {
        console.log({ payload });
        return {
          ...state,
          faqList: state.faqList.map(
            (obj) => (payload._id === obj._id ? payload : obj)
            // data.columnHandling.map((row) => obj.id === row)
          ),
          faqInfo: payload,
          currentStep: payload.currentStep + 1,
          isSuccess: true,
          isUpdate: true,
        };
      }
  
      case FAQ_INFO_SUCCESS: {
        console.log({ payload });
        return {
          ...state,
          faqInfo: payload,
          isInfo: true,
          isEdit: true,
        };
      }
      case FAQ_FILTER_HANDLING: {
        console.log({ payload });
        return {
          ...state,
          columnHandling: payload.columnHandling,
          faqType: payload.faqType,
        };
      }
    
      
  
      default:
        return state;
    }
  }
  
  export default FaqReducer;
  