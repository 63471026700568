export const ORGANIZATION_CREATE = "ORGANIZATION_CREATE";
export const ORGANIZATION_CREATE_SUCCESS = "ORGANIZATION_CREATE_SUCCESS";
export const ORGANIZATION_CREATE_ERROR = "ORGANIZATION_CREATE_ERROR";
export const ORGANIZATION_LIST = "ORGANIZATION_LIST";
export const ORGANIZATION_LIST_SUCCESS = "ORGANIZATION_LIST_SUCCESS";
export const ORGANIZATION_LIST_ERROR = "ORGANIZATION_LIST_ERROR";
export const ORGANIZATION_INFO = "ORGANIZATION_INFO";
export const ORGANIZATION_INFO_SUCCESS = "ORGANIZATION_INFO_SUCCESS";
export const ORGANIZATION_INFO_ERROR = "ORGANIZATION_INFO_ERROR";
export const ORGANIZATION_UPDATE_SUCCESS = "ORGANIZATION_UPDATE_SUCCESS";
export const ORGANIZATION_UPDATE_ERROR = "ORGANIZATION_UPDATE_ERROR";
export const ORGANIZATION_DELETE_SUCCESS = "ORGANIZATION_DELETE_SUCCESS";
export const ORGANIZATION_FILTER_HANDLING = "ORGANIZATION_FILTER_HANDLING";
export const ORGANIZATION_SEARCH_NAME = "ORGANIZATION_SEARCH_NAME";
