import React, { useEffect, useState } from "react";
import { Filter, FloatRight, ListView, PagesHeader } from "../../components/Staffs";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getStaffFilter,
  getStaffPermissionList,
  getStaffRoleList,
  getStaffsList,
} from "../../appRedux/actions/staffs";
import Page from "../../components/Common/Page";
import { getFreeCurrencyApi } from "../../appRedux/actions/common";

const StaffList = () => {
  const dispatch = useDispatch();
  const [initialFetchComplete, setInitialFetchComplete] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [viewStatus, setViewStatus] = useState("listView"); //"listView", "gridView"
  const staffList = useSelector((state) => state.staffs.staffList);
  console.log({ staffList });

  useEffect(() => {
    dispatch(getStaffPermissionList());
    dispatch(getStaffRoleList()).then((roles) => {
      console.log({ roles });
      const idsString = roles.map((role) => role._id).join(",");
      dispatch(getStaffsList({ role: idsString })).then(() => {
        setInitialFetchComplete(true);
      });
    });
  }, [dispatch]);
  return (
    <Page title="Staff">
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <PagesHeader
            pageTitle={"Staff List"}
            homePageUrl="/"
            homePageText="Dashboard"
            // innerPageUrl={""}
            // innerPageText={""}
            activePageText={"Staff's"}
            floatRight={""
              // <FloatRight viewStatus={viewStatus} onChange={(status) => setViewStatus(status)} />
            }
          />
          {/* /Page Header */}
          <Filter {...{ initialFetchComplete, searchText, setSearchText }} />
          {/* <EmployeeListFilter /> */}

          <div className="card">
            <div className="card-body">
              <ListView {...{ searchText }} />
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </Page>
  );
};

export default StaffList;
