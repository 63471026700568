import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormReSetPassword, DialogInnerModal } from ".";
import { showDialogInner } from "../../appRedux/actions/common";
import { setStaffsInfo } from "../../appRedux/actions/staffs";
import { useNavigate } from "react-router-dom";

function EditInfo({ info }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { dialog } = useSelector((state) => ({
    dialog: state.common.dialogInner,
  }));
  console.log({ info, dialog, open });

  useEffect(() => {
    console.log({ info, dialog, open }, "-----99");
    // if (!dialog) {
    //   console.log({ info, dialog, open }, "---");
    //   setOpen(dialog);
    // }
  }, []);
  const onClick = (info1) => {
    setOpen(true);
    dispatch(setStaffsInfo(info1));
    dispatch(showDialogInner({ title: "Reset Password", size: "sm", id: info1._id }));
  };

  return (
    <Fragment>
      <button
        className="dropdown-item"
        onClick={() => onClick(info)}
        // onClick={() => navigate("/staff/update/" + info._id)}
        key={"reset"}
      >
        <i className="fa fa-key  m-r-5" /> Reset Password
      </button>
      {open && (
        <DialogInnerModal
          infoId={info._id}
          key={Math.random()}
          bodyView={<FormReSetPassword isUpdate={true} />}
        ></DialogInnerModal>
      )}
    </Fragment>
  );
}

export default EditInfo;
