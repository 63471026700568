import { UPDATE_PROFILE } from "../actionTypes/profile";
import { ON_SHOW_MESSAGE, HIDE_DIALOG } from "../actionTypes/common";
import axiosHttp from "../services/axiosCommon";
import { userSignOut } from "./auth";

export const updateProfile = (userInfo) => async (dispatch) => {
  try {
    const result = await axiosHttp.put("v2/admin/update-UserProfile", userInfo);
    const { data } = result;
    if (!data.status) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: UPDATE_PROFILE,
        payload: data.results,
      });
      dispatch({ type: HIDE_DIALOG });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
