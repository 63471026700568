import React, { useEffect, useState } from "react";
import {
  Filter,
  FloatRight,
  ListView,
  PagesHeader,
} from "../../components/Level";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Page from "../../components/Common/Page";
import { getLevelList } from "../../appRedux/actions/level";

const LevelList = () => {
  const dispatch = useDispatch();
  const [initialFetchComplete, setInitialFetchComplete] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [viewStatus, setViewStatus] = useState("listView"); //"listView", "gridView"
  const levelList = useSelector((state) => state.level.levelList);
  console.log({ levelList });

  useEffect(() => {
    dispatch(getLevelList({ page: 1, size: 10 })).then(() => {
      setInitialFetchComplete(true);
    });
  }, [dispatch]);
  return (
    <Page title="Level">
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <PagesHeader
            pageTitle={"Level List"}
            homePageUrl="/"
            homePageText="Dashboard"
            // innerPageUrl={""}
            // innerPageText={""}
            activePageText={"Levels"}
            floatRight={
              <FloatRight
                viewStatus={viewStatus}
                onChange={(status) => setViewStatus(status)}
              />
            }
          />
          {/* /Page Header */}
          <Filter {...{ initialFetchComplete, searchText, setSearchText }} />
          {/* <EmployeeListFilter /> */}

          <div className="card">
            <div className="card-body">
              <ListView {...{ searchText }} />
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </Page>
  );
};

export default LevelList;
