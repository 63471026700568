import React, { useEffect, useState } from "react";
import {  FormView, PagesHeader } from "../../components/Languages";
import Page from "../../components/Common/Page";

const LanguageCreate = () => {
 
  return (
    <Page title="Language">
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <PagesHeader
            pageTitle={"Language Create"}
            homePageUrl="/"
            homePageText="Dashboard"
            innerPageUrl={"/language/list"}
            innerPageText={"Language List"}
            activePageText={"Add New Language"}
            floatRight={""}
          />
          <div className="card">
            <div className="card-body">
              <FormView/>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </Page>
  );
};

export default LanguageCreate;
