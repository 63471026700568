import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DialogModal } from ".";
import { useNavigate } from "react-router-dom";
import { showDialog } from "../../appRedux/actions/common";
import AddMetaTag from "./AddMetaTag";
function AddMeta({ metaTags, values, FieldArray }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { dialog } = useSelector((state) => ({
    dialog: state.common.dialog,
  }));
  useEffect(() => {
    if (!dialog) {
      setOpen(dialog);
    }
  }, [dialog]);
  return (
    <Fragment>
      <button
        type="button"
        className="btn add-btn  m-r-5 float-end"
        onClick={() => {
          // navigate("/affiliation/create");
          setOpen(true);
          dispatch(showDialog({ title: "Select Tag", size: "lg" }));
        }}
      >
        <i className="fa fa-list" /> Select Tag
      </button>
      {open && (
        <DialogModal
          bodyView={
            <AddMetaTag
              metaTags={metaTags}
              values={values}
              FieldArray={FieldArray}
            />
          }
        ></DialogModal>
      )}
    </Fragment>
  );
}

export default AddMeta;
