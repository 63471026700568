import React, { useEffect, useState } from "react";
import { Filter, FloatRight, ListView, PagesHeader } from "../../components/Affiliations";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Page from "../../components/Common/Page";
import { getAffiliationsList } from "../../appRedux/actions/affiliations";

const AffiliationList = () => {
  const dispatch = useDispatch();
  const [initialFetchComplete, setInitialFetchComplete] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [viewStatus, setViewStatus] = useState("listView"); //"listView", "gridView"
  const affiliationList = useSelector((state) => state.affiliations.affiliationList);
  console.log({ affiliationList });

  useEffect(() => {
    dispatch(getAffiliationsList({ page: 1, size: 10 })).then(() => {
      setInitialFetchComplete(true);
    });
  }, [dispatch]);
  return (
    <Page title="Affiliation">
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <PagesHeader
            pageTitle={"Affiliation List"}
            homePageUrl="/"
            homePageText="Dashboard"
            // innerPageUrl={""}
            // innerPageText={""}
            activePageText={"Affiliations"}
            floatRight={
              <FloatRight viewStatus={viewStatus} onChange={(status) => setViewStatus(status)} />
            }
          />
          {/* /Page Header */}
          <Filter {...{ initialFetchComplete,searchText, setSearchText }} />
          {/* <EmployeeListFilter /> */}

          <div className="card">
            <div className="card-body">
              <ListView {...{searchText}}/>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </Page>
  );
};

export default AffiliationList;
