import React, { useEffect, useState } from "react";
import { FloatRight, ListView, PagesHeader } from "../../components/FaqsAndPrivacy";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Page from "../../components/Common/Page";
import { getFaqsList } from "../../appRedux/actions/faqs";

const PrivacyPolicyList = () => {
  const dispatch = useDispatch();
  const [initialFetchComplete, setInitialFetchComplete] = useState(false);
  const [viewStatus, setViewStatus] = useState("listView");

  return (
    <Page title="Privacy Policy">
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <PagesHeader
            pageTitle={"Privacy Policy List"}
            homePageUrl="/"
            homePageText="Dashboard"
            // innerPageUrl={""}
            // innerPageText={""}
            activePageText={"Privacy Policies"}
            floatRight={
              <FloatRight
                type="privacy"
                viewStatus={viewStatus}
                onChange={(status) => setViewStatus(status)}
              />
            }
          />
          {/* /Page Header */}
          {/* <Filter {...{ initialFetchComplete }} /> */}
          {/* <EmployeeListFilter /> */}

          <ListView type="privacy" />
        </div>
        {/* /Page Content */}
      </div>
    </Page>
  );
};

export default PrivacyPolicyList;
