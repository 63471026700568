import React, { useEffect, useState } from "react";
import { Filter, FloatRight, FormView, PagesHeader } from "../../components/Staffs";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getStaffFilter, getStaffsList } from "../../appRedux/actions/staffs";
import Page from "../../components/Common/Page";
import { getFreeCurrencyApi } from "../../appRedux/actions/common";

const StaffCreate = () => {
  const dispatch = useDispatch();
  const [initialFetchComplete, setInitialFetchComplete] = useState(false);

  const [viewStatus, setViewStatus] = useState("listView"); //"listView", "gridView"
  const staffList = useSelector((state) => state.staffs.staffList);
  console.log({ staffList });

  useEffect(() => {
   
  }, [dispatch]);
  return (
    <Page title="Staff">
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <PagesHeader
            pageTitle={"Staff Create"}
            homePageUrl="/"
            homePageText="Dashboard"
            innerPageUrl={"/staff/list"}
            innerPageText={"Staff List"}
            activePageText={"Add New Staff"}
            floatRight={""}
          />
          <div className="card">
            <div className="card-body">
              <FormView />
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </Page>
  );
};

export default StaffCreate;
