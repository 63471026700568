import React, { useEffect, useState } from "react";
import { FloatRight, ListView, PagesHeader } from "../../components/FaqsAndPrivacy";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Page from "../../components/Common/Page";
import { getFaqsList } from "../../appRedux/actions/faqs";

const FAQList = () => {
  const dispatch = useDispatch();
  const [initialFetchComplete, setInitialFetchComplete] = useState(false);

  const [viewStatus, setViewStatus] = useState("listView"); //"listView", "gridView"
  const faqList = useSelector((state) => state.faqs.faqList);
  console.log({ faqList });

  // useEffect(() => {
  //   dispatch(getFaqsList({type:{}})).then(() => {
  //     setInitialFetchComplete(true);
  //   });
 // }, [dispatch]);
  return (
    <Page title="FAQ">
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <PagesHeader
            pageTitle={"FAQ List"}
            homePageUrl="/"
            homePageText="Dashboard"
            // innerPageUrl={""}
            // innerPageText={""}
            activePageText={"FAQs"}
            floatRight={
              <FloatRight
                type="faq"
                viewStatus={viewStatus}
                onChange={(status) => setViewStatus(status)}
              />
            }
          />
          {/* /Page Header */}
          {/* <Filter {...{ initialFetchComplete }} /> */}
          {/* <EmployeeListFilter /> */}

          <ListView type="faq" />
        </div>
        {/* /Page Content */}
      </div>
    </Page>
  );
};

export default FAQList;
