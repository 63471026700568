export const COLUMN_HANDLING = [];

export const _MERCHANT_DATA = [
  { _id: "01", name: "Merchant", code: "", disabled: true },
  // { _id: "02", name: "Udemy", code: "udemy" },
  // { _id: "03", name: "Skillshare", code: "skillshare" },
  { _id: "04", name: "Edx", code: "edx" },
  { _id: "05", name: "Coursera", code: "coursera" },
];
export const _LANGUAGE_DATA = [
  { name: "Language", code: "", disabled: true },
  { name: "English", code: "eng" },
];

export const _CATEGORY_DATA = [
  { _id: "01", name: "Business Skills", code: "Business Skills" },
  { _id: "02", name: "Management Accounting", code: "Management Accounting" },
  { _id: "03", name: "Earth Sciences", code: "Earth Sciences" },
  { _id: "05", name: "Insurance", code: "Insurance" },
];

export const _COURSE_FILTER_DATA = {
  category: [{ code: "architectural_restoration", name: "Architectural Restoration" }],
  mode: ["online", "offline"],
  platform: [
    {
      code: "edx",
      name: "edx",
    },
    {
      code: "coursera",
      name: "coursera",
    },
  ],
  affiliation: [],
  language: [
    {
      code: "english",
      name: "english",
    },
  ],
  rating: ["2★ & above", "3★ & above", "4★ & above"],
  level: [
    {
      code: "beginner",
      name: "Beginner",
    },
    {
      code: "intermediate",
      name: "Intermediate",
    },
    {
      code: "advanced",
      name: "Advanced",
    },
    {
      code: "associated",
      name: "Associated",
    },
  ],
  courseType: [
    {
      code: "course",
      name: "course",
    },
  ],
};
