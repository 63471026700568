import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider, Field } from "formik";

import { Input, Select as SelectAnt } from "antd";
import { createFaqs, getFaqsList, updateFaqs } from "../../appRedux/actions/faqs";
import { SelectCustom, SelectCustomLable, Texteditor } from ".";
import { hideDialog } from "../../appRedux/actions/common";

const { Option } = SelectAnt;
const INITIAL_STATE = {
  question: "",
  answer: "",
  priority: 1,
};
function FormView(props) {
  const { faqInfo, faqList } = useSelector((state) => ({
    faqInfo: state.faqs.faqInfo,
    faqList: state.faqs.faqList,
  }));

  const { isUpdate, type } = props;
  const [formInfo, setFormInfo] = useState(INITIAL_STATE);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // dispatch(hideMessage());
    if (isUpdate && faqInfo && faqInfo._id) {
      setFormInfo({
        ...INITIAL_STATE,
        ...faqInfo,
      });
    } else {
      //console.log("");
      setFormInfo({
        ...INITIAL_STATE,
        priority: faqList.length + 1,
      });
    }
  }, [isUpdate, faqInfo]);

  const FormSchema = Yup.object().shape({
    question: Yup.string().required("Question is required."),
    answer: Yup.string().required("Answer is required."),
    priority: Yup.number(),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInfo,
    validationSchema: FormSchema,
    onSubmit: (data) => {
      console.log({ data });
      if (isUpdate && faqInfo && faqInfo._id) {
        dispatch(
          updateFaqs({
            ...data,
            faqId: faqInfo._id,
            type: type,
            _id: undefined,
          })
        ).then(() => {
          // navigate(type === "faq" ? "/faq/list" : "/privacy/list");
          dispatch(getFaqsList({ type }));
          dispatch(hideDialog());
        });
      } else {
        dispatch(
          createFaqs({
            ...data,
            type: type,
          })
        ).then(() => {
          // navigate(type === "faq" ? "/faq/list" : "/privacy/list");
          dispatch(getFaqsList({ type }));
          dispatch(hideDialog());
        });
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;
  console.log({ errors, touched, values });

  return (
    <FormikProvider value={formik}>
      <Form
        id="contactForm"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        className="signin-form"
      >
        <div className="row m-0">
          <div className="col-md-10">
            <div className="input-block mb-3">
              <label className="col-form-label">
                Question <span className="text-danger">*</span>
              </label>
              <textarea className="form-control" type="text" {...getFieldProps("question")} />
              <p className="error-msg text-danger">{touched.question && errors.question}</p>
            </div>
          </div>
          <div className="col-md-2">
            <div className="input-block mb-3">
              <label className="col-form-label">
                Priority <span className="text-danger">*</span>
              </label>
              <SelectCustom
                className="pb-3 text-capitalize"
                placeholder="Select Affiliation"
                showSearch={true}
                options={faqList.map((option, index) => index + 1)}
                value={values.priority}
                onChange={(value) => {
                  if (value !== "select") {
                    setFieldValue("priority", value);
                  }
                }}
                onBlur={() => {
                  handleBlur({ target: { name: "priority" } });
                }}
                disabled={true}
              />
              {/* <input className="form-control" type="text" {...getFieldProps("priority")} /> */}
              <p className="error-msg text-danger">{touched.priority && errors.priority}</p>
            </div>
          </div>

          <div className="col-sm-12">
            <div className="form-group">
              <label className="col-form-label">
                Answer<span className="text-danger">*</span>
              </label>
              <Texteditor
                description={values.answer}
                onChange={(value) => setFieldValue("answer", value)}
                height="350px"
              />
              <p className="error-msg text-danger">{touched.answer && errors.answer}</p>
            </div>
          </div>
        </div>
        <div className="submit-section mb-5">
          <button type="submit" className="btn btn-primary submit-btn">
            {isUpdate && faqInfo && faqInfo._id ? "Update" : "Submit"}
          </button>
        </div>
      </Form>
    </FormikProvider>
  );
}

export default FormView;
