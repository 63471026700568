import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";

import { createStaffs, getStaffsList, updateStaffs } from "../../appRedux/actions/staffs";
import { SelectCustomLable, SelectMultipleCustom, UploadImage } from ".";
import { apiURL } from "../../utils/config";
import { hideDialog } from "../../appRedux/actions/common";

const INITIAL_STATE = {
  name: "",
  email: "",
  password: "",
  confirmPassword: "",
  role: "",
  permission: [],
  contactNo: "",
  imageurl: "",
  address: "",
  empolyeeId: "",
};
function FormView(props) {
  const { staffInfo, roleList, permissionList } = useSelector((state) => ({
    staffInfo: state.staffs.staffInfo,
    roleList: state.staffs.roleList,
    permissionList: state.staffs.permissionList,
  }));

  const { isUpdate, reJoin } = props;
  const [formInfo, setFormInfo] = useState(INITIAL_STATE);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // dispatch(hideMessage());
    if (isUpdate && staffInfo && staffInfo._id) {
      setFormInfo({
        ...INITIAL_STATE,
        ...staffInfo,
        role: staffInfo.role && staffInfo.role._id ? staffInfo.role._id : "",
        permission:
          staffInfo.role && staffInfo.role.permissionIds ? staffInfo.role.permissionIds : [],
        password: "",
      });
    } else {
      //console.log("");
      setFormInfo({
        ...INITIAL_STATE,
        role: roleList && roleList.length ? roleList[0]["_id"] : "",
        permission:
          roleList && roleList.length && roleList[0]["permissionIds"]
            ? roleList[0]["permissionIds"]
            : [],
      });
    }
  }, [isUpdate, staffInfo]);

  const FormSchema = Yup.object().shape({
    name: Yup.string().required("Staff name is required."),
    email: Yup.string().required("Email Id is required."),
    contactNo: Yup.string().required("Contact Number is required."),
    password: !isUpdate
      ? Yup.string()
          .required("Password is required.")
          .min(6, "Password must be at least 6 characters long.")
          .max(20, "Password must be at most 20 characters long.")
      : Yup.string(),
    confirmPassword: Yup.string().when("password", {
      is: (password) => password && password.length > 0,
      then: (schema) =>
        schema
          .oneOf([Yup.ref("password"), null], "Passwords must match.")
          .required("Confirm password is required."),
      otherwise: (schema) => schema,
    }),
    address: Yup.string(),
    imageurl: Yup.string(),
    role: Yup.string(),
    permission: Yup.array(),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInfo,
    validationSchema: FormSchema,
    onSubmit: (data) => {
      console.log({ data });
      if (isUpdate && staffInfo && staffInfo._id) {
        // "id":"66879885dc5c28258d4d14fd",
        // "name":"udpatedAdmin",
        // "email":"admin@gmail.com",
        // "role":"66867010a1d34a503a65ac12",
        // "password":"newestadminneew",
        // "imageurl":"xyz.png",
        // "contactNo":151515,
        // "address":"xyz plavce",
        // "empolyeeId":"xyz"
        dispatch(
          updateStaffs({
            id: staffInfo._id,
            name: data.name,
            email: data.email,
            role: data.role,
            password: isUpdate ? undefined : data.password,
            imageurl: data.imageurl,
            contactNo: data.contactNo,
            address: data.address,
            empolyeeId: data.empolyeeId,
          })
        ).then(() => {
          const idsString = roleList.map((role) => role._id).join(",");
          dispatch(getStaffsList({ role: idsString }));
          dispatch(hideDialog());
        });
      } else {
        dispatch(
          createStaffs({
            name: data.name,
            email: data.email,
            role: data.role,
            password: isUpdate ? undefined : data.password,
            imageurl: data.imageurl,
            contactNo: data.contactNo,
            address: data.address,
            empolyeeId: data.empolyeeId,
          })
        ).then(() => {
          const idsString = roleList.map((role) => role._id).join(",");
          dispatch(getStaffsList({ role: idsString }));
          dispatch(hideDialog());
        });
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;
  console.log({ errors, touched, values });

  return (
    <FormikProvider value={formik}>
      <Form
        id="contactForm"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        className="signin-form"
      >
        <div className="row m-0">
          <div className="row m-0">
            <div className="profile-img-wrap edit-img">
              <img
                className="inline-block"
                src="/react/template/static/media/avatar-02.62b90545c23b82880b99.jpg"
                alt="user"
              />
              <div className="fileupload btn">
                <span className="btn-text">edit</span>
                <input className="upload" type="file" />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group ">
                <label className="col-form-label">
                  Name <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" {...getFieldProps("name")} />
                <p className="error-msg text-danger">{touched.name && errors.name}</p>
              </div>
            </div>
            {/* <div className="row"> */}
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <label className="col-form-label">
                  Email Id<span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="email"
                  {...getFieldProps("email")}
                  readOnly={isUpdate ? true : false}
                />
                <p className="error-msg text-danger">{touched.email && errors.email}</p>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <label className="col-form-label">
                  Contact Number<span className="text-danger">*</span>
                </label>
                <input className="form-control" type="number" {...getFieldProps("contactNo")} />
                <p className="error-msg text-danger">{touched.contactNo && errors.contactNo}</p>
              </div>
            </div>
          </div>

          {/* <div className="col-md-4">
            <div className="form-group mb-3">
              <label className="col-form-label"> Profile Image</label>
              <div className="mt-3">
                <UploadImage
                  {...{
                    fileList:
                      values.imageurl !== ""
                        ? [
                            {
                              uid: "-1",
                              name: "image.png",
                              status: "done",
                              url: values.imageurl,
                            },
                          ]
                        : [],
                    setFileList: (list) => {
                      var newList = [];
                      for (let i = 0; i < list.length; i++) {
                        let listInfo = list[i];
                        if (
                          listInfo.status === "done" &&
                          listInfo.response &&
                          listInfo.response.status === 200
                        ) {
                          newList.push({
                            name: listInfo.name,
                            status: listInfo.status,
                            size: listInfo.size,
                            type: listInfo.type,
                            uid: listInfo.uid,
                            url: apiURL + listInfo.response.data,
                          });
                        } else {
                          newList.push(listInfo);
                        }
                      }
                      setFieldValue("imageurl", newList.length ? newList[0]["url"] : "");
                    },
                    imageUrl: values.imageurl,
                    setImageUrl: (url) => {
                      setFieldValue("imageurl", url);
                    },
                  }}
                />
              </div>
            </div>
          </div> */}
          <div className="col-md-12">
            <div className="row m-0">
              {!isUpdate && (
                <div className="col-md-6">
                  <div className="form-group ">
                    <label className="col-form-label">
                      Password <span className="text-danger">*</span>
                    </label>
                    <input className="form-control" type="text" {...getFieldProps("password")} />
                    <p className="error-msg text-danger">{touched.password && errors.password}</p>
                  </div>
                </div>
              )}

              {!isUpdate && (
                <div className="col-md-6">
                  <div className="form-group ">
                    <label className="col-form-label">
                      Confirm Password <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      {...getFieldProps("confirmPassword")}
                    />
                    <p className="error-msg text-danger">
                      {touched.confirmPassword && errors.confirmPassword}
                    </p>
                  </div>
                </div>
              )}
              <div className="col-md-12">
                <div className="form-group ">
                  <label className="col-form-label">Address</label>
                  <textarea className="form-control" type="text" {...getFieldProps("address")} />
                  <p className="error-msg text-danger">{touched.address && errors.address}</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-block  ">
                  <label className="col-form-label">
                    Role<span className="text-danger">*</span>
                  </label>
                  <SelectCustomLable
                    className="pb-3 text-capitalize"
                    placeholder="Select Role"
                    showSearch={true}
                    options={roleList}
                    // options={roleList.map((option) => ({
                    //   _id: option._id,
                    //   label: option.name,
                    // }))}
                    value={values.role}
                    onChange={(value) => {
                      if (value !== "select") {
                        setFieldValue("role", value);
                      }
                    }}
                    onBlur={() => {
                      handleBlur({ target: { name: "role" } });
                    }}
                    disabled={true}
                  />
                  <p className="error-msg text-danger">{touched.role && errors.role}</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-block  ">
                  <label className="col-form-label">
                    Permission<span className="text-danger">*</span>
                  </label>
                  <SelectMultipleCustom
                    className="pb-3 text-capitalize"
                    placeholder="Select Role"
                    showSearch={true}
                    options={permissionList}
                    value={values.permission}
                    onChange={(value) => {
                      if (value !== "select") {
                        setFieldValue("permission", value);
                      }
                    }}
                    onBlur={() => {
                      handleBlur({ target: { name: "permission" } });
                    }}
                    disabled={true}
                  />
                  <p className="error-msg text-danger">{touched.permission && errors.permission}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="submit-section m-3">
          <button type="submit" className="btn btn-primary submit-btn">
            {isUpdate && staffInfo && staffInfo._id ? "Update" : "Submit"}
          </button>
        </div>
      </Form>
    </FormikProvider>
  );
}

export default FormView;
