import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import { useDispatch } from "react-redux";
import { courseImport } from "../../appRedux/actions/courses";

import { InboxOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Dragger } = Upload;
const ImportFile = () => {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleUpload = () => {
    // const formData = new FormData();
    console.log({ fileList, file: fileList[0] });
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file);
    });
    setUploading(true);
    console.log({ formData });
    dispatch(courseImport(formData))
      .then((data) => {
        setFileList([]);
        // message.success("Upload successfully.");
        // navigate("/course/list");
        // Step 2: Create a Blob from the CSV data
        //   {
        //     "code": 200,
        //     "message": "OK",
        //     "status": true,
        //     "results": "No error in the file"
        // }
        console.log({ data });
        if (data && data.status) {
          message.success("Upload successfully.");
          navigate("/course/list");
        } else if (data && data.message) {
          message.error(data.message);
        } else {
          const url = window.URL.createObjectURL(new Blob([data], { type: "text/csv" }));
          // Step 3: Create a link to download the Blob
          const a = document.createElement("a");
          a.href = url;
          a.download = "error-file.csv";
          document.body.appendChild(a);
          a.click();
          a.remove();

          // Revoke the object URL after the download is complete
          window.URL.revokeObjectURL(url);
          message.error("Upload failed.");
        }
        // setLoader(false);
      })
      .catch(() => {
        message.error("Upload failed.");
      })
      .finally(() => {
        setUploading(false);
      });
  };
  const validateFile = (file) => {
    const isCsv = file.type === "text/csv";
    if (!isCsv) {
      message.error("You can only upload CSV files!");
      return false;
    }

    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("File must be smaller than 5MB!");
      return false;
    }

    return true;
  };
  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      //   setFileList([...fileList, file]);
      if (validateFile(file)) {
        setFileList([file]);
      }
      return false;
    },
    fileList,
  };
  useEffect(() => {
    console.log({ fileList });
  }, [fileList]);
  return (
    <>
      {/* <Upload {...props}>
        <Button icon={<UploadOutlined />}>Select File</Button>
      </Upload> */}
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag CSV file to this area to upload</p>
        <p className="ant-upload-hint">Upload single or bulk courses CSV.</p>
      </Dragger>
      {/* <Button
        type="primary"
        onClick={handleUpload}
        disabled={fileList.length === 0}
        loading={uploading}
        style={{
          marginTop: 16,
        }}
      >
        {uploading ? "Uploading" : "Start Upload"}
      </Button> */}

      <div className="submit-section">
        <button
          type="submit"
          className="btn btn-primary submit-btn"
          onClick={handleUpload}
          disabled={fileList.length === 0}
          loading={uploading}
        >
          {uploading ? "Uploading" : "Submit"}
        </button>
      </div>
    </>
  );
};
export default ImportFile;
