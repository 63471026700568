export const metaTags = {
  normal: [
    {
      name: "title",
      property: "",
      content: "",
      placeholder: "Page title here",
    },
    {
      name: "description",
      property: "",
      content: "",
      placeholder: "Page description here",
    },
    {
      name: "keywords",
      property: "",
      content: "",
      placeholder: "Page keywords here",
    },
    { name: "author", property: "", content: "", placeholder: "Author name" },
    { name: "charset", property: "", content: "", placeholder: "UTF-8" },
    {
      name: "viewport",
      property: "",
      content: "",
      placeholder: "width=device-width, initial-scale=1, shrink-to-fit=no",
    },
    {
      name: "robots",
      property: "",
      content: "",
      placeholder:
        "index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1",
    },
  ],
  openGraph: [
    { name: "", property: "og:locale", content: "", placeholder: "en_US" },
    { name: "", property: "og:type", content: "", placeholder: "article" },
    {
      name: "",
      property: "og:url",
      content: "",
      placeholder: "https://example.com",
    },
    {
      name: "",
      property: "og:site_name",
      content: "",
      placeholder: "Website name",
    },
    {
      name: "",
      property: "article:publisher",
      content: "",
      placeholder: "Publisher URL",
    },
    {
      name: "",
      property: "article:published_time",
      content: "",
      placeholder: "Published time",
    },
    {
      name: "",
      property: "article:modified_time",
      content: "",
      placeholder: "Modified time",
    },
    { name: "", property: "og:image", content: "", placeholder: "Image URL" },
    {
      name: "",
      property: "og:image:width",
      content: "",
      placeholder: "Image width",
    },
    {
      name: "",
      property: "og:image:height",
      content: "",
      placeholder: "Image height",
    },
    {
      name: "",
      property: "og:image:type",
      content: "",
      placeholder: "Image type (e.g., image/png)",
    },
  ],
  twitter: [
    {
      name: "twitter:card",
      property: "",
      content: "",
      placeholder: "summary_large_image",
    },
    {
      name: "twitter:creator",
      property: "",
      content: "",
      placeholder: "@username",
    },
    {
      name: "twitter:site",
      property: "",
      content: "",
      placeholder: "@sitename",
    },
    {
      name: "twitter:label1",
      property: "",
      content: "",
      placeholder: "Label 1",
    },
    { name: "twitter:data1", property: "", content: "", placeholder: "Data 1" },
    {
      name: "twitter:label2",
      property: "",
      content: "",
      placeholder: "Label 2",
    },
    { name: "twitter:data2", property: "", content: "", placeholder: "Data 2" },
  ],
};
