import {
  SIGNIN_USER_ERROR,
  SIGNIN_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  SIGNOUT_AUTH_USER_SUCCESS,
  ALL_CLEAR_SUCCESS,
  AUTH_LEVEL,
  TOKEN_REMOVE,
  CODE_VERIFY,
} from "../actionTypes/auth";
import { UPDATE_PROFILE } from "../actionTypes/profile";
export const AUTH_INIT_STATE = {
  authLevel: 1,
  email: "",
  verifyType: "",
  code: "",
  user: JSON.parse(localStorage.getItem("user")) || {},
  accessToken: localStorage.getItem("accessToken"),
  routeLevel: JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user")).accessLevel
    : 0 || 0,
  userType: JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user")).userType
    : [] || [],
};

function AuthReducer(state = AUTH_INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_PROFILE: {
      localStorage.setItem("user", JSON.stringify({...state.user,...payload}));
      return {
        ...state,
        user:{...state.user,...payload},
      };
    }
    case AUTH_LEVEL: {
      return {
        ...state,
        authLevel: payload,
      };
    }
    case CODE_VERIFY: {
      return {
        ...state,
        // tempToken: payload.tempToken,
        code: payload.code,
        email: null,
        verifyType: payload.type,
      };
    }
    case TOKEN_REMOVE: {
      localStorage.removeItem("tempEmail");
      return {
        ...state,
        email: null,
        tempToken: null,
        verifyType: null,
      };
    }

    case SIGNIN_USER_SUCCESS: {
      state.initURL = "/";
      console.log({ payload });
      localStorage.setItem("accessToken", payload.accessToken);
      localStorage.setItem("user", JSON.stringify(payload.user));
      // localStorage.setItem("education", JSON.stringify(payload.education));
      return {
        ...state,
        user: payload.user,
        // education: payload.education,
        accessToken: payload.accessToken,
        routeLevel: payload.user.role,
        userType: [payload.user.role],
      };
    }

    case SIGNUP_USER_SUCCESS: {
      //console.log({ payload });
      // localStorage.setItem("tempEmail", payload.email);
      return {
        ...state,
        tempToken: payload.tempToken,
        email: payload.email,
        verifyType: payload.type,
      };
    }

    case SIGNIN_USER_ERROR: {
      //console.log({ payload });
      return {
        ...state,
        loader: false,
        showMessage: true,
        messageStatus: "error",
        message: payload.userMessage,
        status: payload.status,
        errorMessage: payload.userMessage,
      };
    }

    case SIGNOUT_AUTH_USER_SUCCESS: {
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("switchOn");
      return {
        ...state,
        user: {},
        accessToken: null,
      };
    }

    case ALL_CLEAR_SUCCESS: {
      return {
        ...state,
        user: {},
        accessToken: null,
        routeLevel: 0,
        userType: [],
      };
    }
    default:
      return state;
  }
}

export default AuthReducer;
