import React, { useState, useEffect, Fragment } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";

// import Router from 'next/router'
import { ToastContainer } from "react-toastify";
import Header from "./header";
import Sidebar from "./sidebar";
import OffCanvas from "./OffCanvas";
import { useDispatch } from "react-redux";
import { hideLoader } from "../../appRedux/actions/common";
import { useSelector } from "react-redux";
// import {   Header, Footer } from "..";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [loader, setLoader] = useState(true);
  const { loader, accessToken, user } = useSelector((state) => ({
    showMessage: state.common.showMessage,
    messageStatus: state.common.messageStatus,
    message: state.common.message,
    loader: state.common.loader,
    accessToken: state.auth.accessToken,
    routeLevel: state.auth.routeLevel,
    user: state.auth.user,
  }));
  useEffect(() => {
    setTimeout(() => {
      // setLoader(false);
      dispatch(hideLoader());
    }, 1000);
  }, []);

  useEffect(() => {
    // if (showMessage) dispatch(hideMessage());
    if (loader) dispatch(hideLoader());
    if (!accessToken || accessToken === null) navigate("/auth/login", { replace: true });
  }, [dispatch, navigate, loader, accessToken]);
  return (
    <Fragment>
      {/*   
      {loader && <Preloader />} */}
      <Header />
      <Sidebar />
      <OffCanvas />
      {/* <ToastContainer /> */}
      <Outlet />
      {/* <GoTop scrollStepInPx="100" delayInMs="10.50" /> */}
      {/* <Footer /> */}
      {/* <Copyright key={Math.random()} /> */}{" "}
    </Fragment>
  );
};

export default Layout;
