// import React from "react";
// import ReactDOM from "react-dom/client";
// import Approuter from "./Routes/Approuter";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import { Provider } from "react-redux";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";

// import reportWebVitals from "./reportWebVitals";
import store from "./appRedux/store";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/css/line-awesome.min.css";
import "./assets/css/antdstyle.css";
import "./assets/scss/main.scss";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <>
//     <Approuter />
//   </>
// );

createRoot(document.getElementById("root")).render(
  <HelmetProvider>
    <BrowserRouter basename="/">
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </HelmetProvider>
);
