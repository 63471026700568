export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_KEY = "UPDATE_USER_PROFILE_KEY";
export const UPDATE_USER_PERSONAL_INFORMATIONS = "UPDATE_USER_PERSONAL_INFORMATIONS";
export const UPDATE_USER_EMERGENCY_CONTACT = "UPDATE_USER_EMERGENCY_CONTACT";
export const UPDATE_USER_BANK_INFORMATIONS = "UPDATE_USER_BANK_INFORMATIONS";
export const UPDATE_USER_FAMILY_INFORMATIONS = "UPDATE_USER_FAMILY_INFORMATIONS";
export const UPDATE_USER_EDUCATION_INFORMATIONS = "UPDATE_USER_EDUCATION_INFORMATIONS";
export const UPDATE_USER_EXPERIENCE_INFORMATIONS = "UPDATE_USER_EXPERIENCE_INFORMATIONS";

export const SET_USER_EDUCATION_INFORMATIONS = "SET_USER_EDUCATION_INFORMATIONS";
export const SET_USER_EXPERIENCE_INFORMATIONS = "SET_USER_EXPERIENCE_INFORMATIONS";
export const GET_USER_EDUCATION_INFORMATIONS = "GET_USER_EDUCATION_INFORMATIONS";
export const GET_USER_EXPERIENCE_INFORMATIONS = "GET_USER_EXPERIENCE_INFORMATIONS";
export const CREATE_USER_EDUCATION_INFORMATIONS = "CREATE_USER_EDUCATION_INFORMATIONS";
export const CREATE_USER_EXPERIENCE_INFORMATIONS = "CREATE_USER_EXPERIENCE_INFORMATIONS";
export const GET_USER_FAMILY_INFORMATIONS = "GET_USER_FAMILY_INFORMATIONS";
export const CREATE_USER_FAMILY_INFORMATIONS = "CREATE_USER_FAMILY_INFORMATIONS";
export const SET_USER_FAMILY_INFORMATIONS = "SET_USER_FAMILY_INFORMATIONS";
export const DELETE_USER_INFORMATIONS = "DELETE_USER_INFORMATIONS";
export const GET_USER_PROJECTS = "GET_USER_PROJECTS";
export const GET_USER_INFO_SETTING = "GET_USER_INFO_SETTING";
export const UPDATE_USER_INFO_SETTING = "UPDATE_USER_INFO_SETTING";
