

import React, { useEffect, useState } from 'react';
import { FieldArray } from 'formik';
import { hideDialog } from '../../appRedux/actions/common';
import { useDispatch } from 'react-redux';

const AddMetaTag = ({ metaTags, values }) => {

    const dispatch = useDispatch();
    const [selectedTags, setSelectedTags] = useState([]);
    // Sync selectedTags with Formik's values.metaTags
    useEffect(() => {
        // Set selectedTags based on Formik's values.meta
        if (values.meta && values.meta.length > 0) {
            setSelectedTags(values.meta);
        }
    }, [values.meta]);
    console.log({ values, selectedTags })
    const handleCheckboxChange = (tag, isChecked) => {
        if (isChecked) {
            // Add the tag to the selectedTags array if it is not already selected
            setSelectedTags((prevTags) => [...prevTags, tag]);
        } else {
            // Remove the tag from the selectedTags array
            setSelectedTags((prevTags) =>
                prevTags.filter(
                    (selectedTag) =>
                        selectedTag.name !== tag.name || selectedTag.property !== tag.property
                )
            );
        }
    };

    const handleApply = (push) => {
        // Push the selected tags into the Formik field when the Apply button is clicked
        selectedTags.forEach((tag) => {
            const isAlreadyAdded = values.meta.some(
                (meta) =>
                    meta.name === tag.name && meta.property === tag.property
            );
            if (!isAlreadyAdded) {
                push({
                    name: tag.name || '',
                    property: tag.property || '',
                    content: '',
                });
            }
        });
    };
    // const upperCaseName = (name) => {
    //     return name.toUpperCase().replace(/ /g, ":")
    // }
    const upperCaseName = (value) => {
        return value
            .split(/[:_]/) // Split by ':' or '_' into an array
            .map((part) => part.charAt(0).toUpperCase() + part.slice(1)) // Capitalize each part
            .join(" "); // Join them with spaces
    };
    return (
        <FieldArray
            name="meta"
            render={({ push }) => (
                <div>
                    {Object.keys(metaTags).map((section) => (
                        <div key={section} className="mb-4">
                            <h4 className="text-primary">{upperCaseName(section)}</h4>
                            <div className="row">
                                {/* Render checkboxes */}
                                {metaTags[section].map((tag, index) => {

                                    const isChecked = selectedTags.some(
                                        (selectedTag) =>
                                            selectedTag.name === tag.name && selectedTag.property === tag.property
                                    );
                                    // console.log({ selectedTags, isChecked })
                                    return (
                                        <div key={`${section}-${index}`} className="col-4 mb-3">
                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`${section}-${index}`}
                                                    checked={isChecked}
                                                    onChange={(e) =>
                                                        handleCheckboxChange(tag, e.target.checked)
                                                    }
                                                    disabled={["title", "description"].includes(tag.name)}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor={`${section}-${index}`}
                                                >
                                                    {upperCaseName(tag.name || tag.property || tag.httpEquiv)}
                                                </label>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ))}

                    <div className="submit-section mb-5">
                        <button type="button" className="btn btn-primary submit-btn" onClick={() => {
                            handleApply(push);

                            dispatch(hideDialog());
                        }}>
                            Apply
                        </button>
                    </div>

                </div>
            )}
        />
    );
};

export default AddMetaTag;
