import React, { useEffect, Fragment } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { hideLoader } from "../../appRedux/actions/common";

const AuthLayout = (props) => {
  const { children } = props;
  const navigate = useNavigate();

  const initURL = useSelector((state) => state.common.initURL);
  const authUser = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const [loader, setLoader] = useState(true);
  const { loader, accessToken, user } = useSelector((state) => ({
    showMessage: state.common.showMessage,
    messageStatus: state.common.messageStatus,
    message: state.common.message,
    loader: state.common.loader,
    accessToken: state.auth.accessToken,
    routeLevel: state.auth.routeLevel,
    user: state.auth.user,
  }));
  useEffect(() => {
    if (initURL.includes("/auth") || initURL === "/") {
      // auth/verify-otp
      if (initURL.includes("/auth/verify-otp")) {
        setTimeout(() => {
          navigate(initURL);
        }, 200);
      } else {
        // setTimeout(() => {
        //   navigate(initURL, { replace: true });
        // }, 500);
      }
    }
  }, [initURL]);

  useEffect(() => {
    // if (showMessage) dispatch(hideMessage());
    if (loader) dispatch(hideLoader());
    if (accessToken && accessToken !== null) navigate("/", { replace: true });
  }, [dispatch, navigate, loader, accessToken]);
  console.log("login", accessToken);
  return (
    <Fragment>
      <Outlet />
    </Fragment>
  );
};

export default AuthLayout;
