export const FAQ_CREATE = "FAQ_CREATE";
export const FAQ_CREATE_SUCCESS = "FAQ_CREATE_SUCCESS";
export const FAQ_CREATE_ERROR = "FAQ_CREATE_ERROR";
export const FAQ_LIST = "FAQ_LIST";
export const FAQ_LIST_SUCCESS = "FAQ_LIST_SUCCESS";
export const FAQ_LIST_ERROR = "FAQ_LIST_ERROR";
export const FAQ_INFO = "FAQ_INFO";
export const FAQ_INFO_SUCCESS = "FAQ_INFO_SUCCESS";
export const FAQ_INFO_ERROR = "FAQ_INFO_ERROR";
export const FAQ_UPDATE_SUCCESS = "FAQ_UPDATE_SUCCESS";
export const FAQ_UPDATE_ERROR = "FAQ_UPDATE_ERROR";
export const FAQ_DELETE_SUCCESS = "FAQ_DELETE_SUCCESS";
export const FAQ_FILTER_HANDLING = "FAQ_FILTER_HANDLING";
export const CLEAR_FAQ_INFO = "CLEAR_FAQ_INFO";

export const FAQ_FILTER_SUCCESS = "FAQ_FILTER_SUCCESS";
