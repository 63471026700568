import React, { useEffect, useState } from "react";
import {  FormView, PagesHeader } from "../../components/Languages";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {  getLanguageInfo } from "../../appRedux/actions/languages";
import Page from "../../components/Common/Page";
import { useParams } from "react-router-dom";

const LanguageUpdate = () => {
  const { languageId } = useParams();
  const dispatch = useDispatch();
  const [initialFetchComplete, setInitialFetchComplete] = useState(false);

  const [viewStatus, setViewStatus] = useState("listView"); //"listView", "gridView"
  const languageList = useSelector((state) => state.languages.languageList);
  console.log({ languageList });

  useEffect(() => {
    dispatch(getLanguageInfo(languageId));
  }, [languageId]);
 
  return (
    <Page title="Language">
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <PagesHeader
            pageTitle={"Language Update"}
            homePageUrl="/"
            homePageText="Dashboard"
            innerPageUrl={"/language/list"}
            innerPageText={"Language List"}
            activePageText={"Update Language"}
            floatRight={""}
          />
          <div className="card">
            <div className="card-body">
              <FormView isUpdate={true} />
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </Page>
  );
};

export default LanguageUpdate;
