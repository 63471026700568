import { number } from "yup";

export const currencyConverter = (price, priceDetail, selectedCurrency, currencyData) => {
  console.log({ currencyData });
  const FIND_INR = currencyData.find((data) => data.name === "INR") || 83;
  console.log({ FIND_INR });
  const INR_TO_USD = FIND_INR.rate || 83;
  // const INR_TO_USD = currencyData["INR"] || 83;
  if (priceDetail) {
    const { symbol, currency, amount, price_string, currency_symbol } = priceDetail;

    price = price && price !== "" && price !== null ? price : amount;
    if (price && price !== "") {
      if (selectedCurrency.currency === "INR") {
        if (currency === "USD") {
          return (price * INR_TO_USD).toFixed(2);
        } else {
          return price;
        }
      } else {
        if (currency === "INR") {
          return (price / INR_TO_USD).toFixed(2);
        } else {
          return price;
        }
      }
    } else {
      return price;
    }
  } else {
    return price;
  }
};
export const currencyConverterForm = (price, priceDetail, currencyData) => {
  console.log({ currencyData });
  const FIND_INR = currencyData.find((data) => data.name === "INR") || 83;
  console.log({ FIND_INR });
  const INR_TO_USD = FIND_INR.rate || 83;
  if (priceDetail) {
    const { currency, amount } = priceDetail;

    price = price && price !== "" && price !== null ? price : amount;
    if (price && price !== "") {
      if (currency === "USD") {
        return (price / INR_TO_USD).toFixed(2);
      } else {
        // const roundedUp = Math.ceil(number)
        const number = (price * INR_TO_USD).toFixed(2);
        const roundedDown = Math.floor(number);
        // console.log(roundedDown); // Outputs: 4999

        const roundedUp = Math.ceil(number);
        // console.log(roundedUp); // Outputs: 5000

        // Custom rounding based on decimal value
        const decimalPart = number - roundedDown;

        const customRounded = decimalPart > 0.5 ? roundedUp : roundedDown;
        return customRounded;
      }
    } else {
      return price;
    }
  } else {
    return price;
  }
};
