import React, { useEffect, useState } from "react";

import { connect, useDispatch, useSelector } from "react-redux";
import { Table, Tag } from "antd";
import ReactStars from "react-stars";
// import "antd/dist/antd.css";
// import { itemRender, onShowSizeChange } from "../../paginationfunction";

import { Link } from "react-router-dom";
import { EditInfo, DeleteModal } from ".";
import { apiURL } from "../../utils/config";
// import { getCourseOwners } from "../../appRedux/actions/courseOwners";
import { Avatar_02 } from "../../Routes/ImagePath";
import moment from "moment";
import { currencyConverter } from "../../utils/currency";
import { getAffiliationsList } from "../../appRedux/actions/affiliations";

function ListView({ searchText }) {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const { affiliationList } = useSelector((state) => ({
    affiliationList: state.affiliations.affiliationList,
    queryInfo: state.affiliations.queryInfo,
    totalRecords: state.affiliations.totalRecords,
    selectedCurrency: state.common.selectedCurrency,
    currencyData: state.common.currencyData,
  }));

  useEffect(() => {
    console.log({ searchText });
    if (searchText) {
      const filteredList = affiliationList.filter((affiliation) =>
        affiliation.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setList(filteredList);
    } else {
      setList(affiliationList);
    }
  }, [searchText, affiliationList]);

  useEffect(() => {
    setList(affiliationList);
  }, [affiliationList]);

  const columns = [
    {
      title: "Affiliation Name",
      dataIndex: "name",
      render: (name, record) => (
        <h2 className="table-avatar" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          {name}
        </h2>
      ),
      // width: "30px",
      // sorter: (a, b) => a.name.length - b.name.length,
    },

    {
      title: "Affiliation Code",
      dataIndex: "code",
      align: "center",
      render: (code, record) => (
        <h2 className="table-avatar" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          {code}
        </h2>
      ),
    },

    {
      title: "Affiliation Image",
      dataIndex: "imageUrl",
      align: "center",
      render: (image, record) => (
        <h2 className="table-avatar" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          <Link to={`/affiliation/${record.id}`}>
            <img
              alt=""
              src={image ? image : Avatar_02}
              style={{
                width: "auto",
                height: "55px",
                maxWidth: "300px",
                objectFit: "cover",
                borderRadius: "4px",
                padding: "8px",
              }}
            />
          </Link>
        </h2>
      ),
    },

    {
      title: "Action",
      align: "center",
      // fixed: "right",
      render: (text, record) => (
        <div className="dropdown dropdown-action text-center">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <EditInfo info={record} key={"edit_info"} />

            <DeleteModal info={record} />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="row m-0 mt-3">
      <div className="col-md-12 p-0" id="table-responsive">
        <div className="table-responsive" id="setMinheight">
          <Table
            className="table-striped"
            pagination={true}
            // pagination={{
            //   total: totalRecords,
            //   pageSizeOptions: ["5", "10", "20", "50"],
            //   showSizeChanger: true,
            //   showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
            //   onShowSizeChange: (current, pageSize) => {
            //     console.log({ current, pageSize });
            //     dispatch(
            //       getAffiliationsList({
            //         ...queryInfo,
            //         page: current,
            //         size: pageSize,
            //       })
            //     );
            //   }, //onShowSizeChange,
            //   itemRender: itemRender,
            // }}
            // onChange={(pagination, filters, sorter) =>
            //   handleTableChange(pagination, filters, sorter)
            // }
            style={{ overflowX: "auto" }}
            columns={columns}
            // bordered
            dataSource={list}
            rowKey={(record) => record.id}
            // onChange={this.handleTableChange}
          />
        </div>
      </div>
    </div>
  );
}

export default ListView;
