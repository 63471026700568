import React, { useEffect, useState } from "react";

import { FloatInput, FloatSelectObject } from "../Floating";
import { useDispatch, useSelector } from "react-redux";
import { getCoursesList } from "../../appRedux/actions/courses";
import FloatSelectSingle from "../Floating/FloatSelectSingle";

function Filter({ initialFetchComplete }) {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [platform, setPlatform] = useState([]);
  const [category, setCategory] = useState([]);
  const [affiliated, setAffiliated] = useState("all");

  const { queryInfo, courseFilters, setOldQueryInfo } = useSelector((state) => ({
    queryInfo: state.courses.queryInfo,
    courseFilters: state.courses.courseFilters,
    setOldQueryInfo: state.courses.setOldQueryInfo,
  }));
  const [categoriesList, setCategoriesList] = useState(courseFilters.category);
  const [modeList, setModeList] = useState(courseFilters.mode);
  const [platformList, setPlatformList] = useState(courseFilters.platform);
  const [affiliationList, setAffiliationList] = useState(courseFilters.affiliation);
  const [courseTypeList, setCourseTypeList] = useState(courseFilters.course_type);
  const [languageList, setLanguageList] = useState(courseFilters.language);
  const [ratingList, setRatingList] = useState(courseFilters.rating);
  const [levelList, setLevelList] = useState(courseFilters.level);

  useEffect(() => {
    setCategoriesList(courseFilters.category);
    setModeList(courseFilters.mode);
    setPlatformList(courseFilters.platform);
    setAffiliationList(courseFilters.affiliation);
    setCourseTypeList(courseFilters.course_type);
    setLanguageList(courseFilters.language);
    setRatingList(courseFilters.rating);
    setLevelList(courseFilters.level);
  }, [courseFilters]);
  // useEffect(() => {
  //   if (setOldQueryInfo) {
  //     setSearchText(queryInfo.title ? queryInfo.title : "");
  //     setPlatform(queryInfo.merchant ? [queryInfo.merchant] : []);
  //     setCategory(queryInfo.category ? [queryInfo.category] : []);
  //   }
  // }, [queryInfo, setOldQueryInfo]);
  useEffect(() => {
    if (initialFetchComplete) {
      const timeout = setTimeout(() => {
        dispatch(
          getCoursesList({
            ...queryInfo,
            title: searchText === "" ? undefined : searchText,
            merchant: platform.length === 0 ? undefined : platform.join(","),
            category: category.length === 0 ? undefined : category.join(","),
            affiliated: affiliated === "all" ? undefined : affiliated,
          })
        );
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [searchText, category, platform, affiliated]);

  return (
    <div className="row filter-row">
      <div className="col-sm-6 col-md-4 pb-2">
        <FloatInput
          label="Course "
          value={searchText}
          name="searchText"
          id="inputCustom"
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
      </div>
      <div className="col-sm-6 col-md-2  pb-2">
        <div className="form-group form-focus select-focus">
          <FloatSelectObject
            label="Platform"
            // placeholder="Email here please"
            value={platform}
            name="platform"
            id="inputCustom"
            tag2="code"
            onChange={(value) => {
              setPlatform(value);
            }}
            showSearch={true}
            options={platformList}
          />
        </div>
      </div>

      <div className="col-sm-6 col-md-2 pl-1 pr-1 pb-2">
        <div className="form-group form-focus select-focus">
          <FloatSelectObject
            label="Category"
            // placeholder="Email here please"
            value={category}
            name="category"
            id="inputCustom"
            tag2="_id"
            showSearch={true}
            onChange={(value) => {
              setCategory(value);
            }}
            // showSearch={true}
            options={categoriesList}
          />
        </div>
      </div>
      {/* 1. All
2. Impact
3. Direct */}
      <div className="col-sm-6 col-md-2 pl-1 pr-1 pb-2">
        <div className="form-group form-focus select-focus">
          <FloatSelectSingle
            label="Affiliated"
            // placeholder="Email here please"
            value={affiliated}
            name="affiliated"
            id="inputCustom"
            tag2="code"
            // showSearch={true}
            onChange={(value) => {
              setAffiliated(value);
            }}
            options={[
              { code: "all", name: "All" },
              { code: "impact", name: "Impact" },
              { code: "direct", name: "Direct" },
            ]}
          />
        </div>
      </div>
      {/* <div className="col-sm-6 col-md-2 "></div> */}
      <div className="col-sm-6 col-md-2 ">
        <button
          className="btn btn-success btn-block full-width w-100"
          onClick={() => {
            setSearchText("");
            setCategory([]);
            setPlatform([]);
            setAffiliated("all");
          }}
        >
          Clear
        </button>
      </div>
    </div>
  );
}

Filter.propTypes = {};

export default Filter;
