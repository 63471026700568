import React, { useEffect, Fragment } from "react";
import { ToastContainer, toast } from "react-toastify";
import { connect, useSelector } from "react-redux";
import { message as ToastMessage } from "antd";
// routes
import Router from "./Routes";
// components
import ScrollToTop from "./components/ScrollToTop";

// import Loading from "./components/Loading";

// const onLine = navigator.onLine;
function App({ accessToken, user, component: Component, ...rest }, props) {
  const messageStatus = useSelector((state) => state.common.messageStatus);
  const message = useSelector((state) => state.common.message);
  const showMessage = useSelector((state) => state.common.showMessage);
  useEffect(() => {
    console.log({ showMessage, message, messageStatus });
    if (showMessage && message && message !== "") {
      // openNotification(messageStatus, message);
      if (messageStatus === "error") {
        ToastMessage.error(message);
      } else {
        ToastMessage.success(message);
      }
    }
  }, [showMessage, message, messageStatus]);

  return (
    <Fragment>
      <React.Suspense fallback={<div>Loading...</div>}>
        <ScrollToTop />
        {/* <ToastContainer /> */}
        {/* <Loading /> */}
        <Router />
      </React.Suspense>
    </Fragment>
  );
}

const mapStatetoProps = (state) => {
  // console.log({ state });
  return {};
};

export default connect(mapStatetoProps)(App);
