import React, { useEffect, useState } from "react";

import { FloatInput } from "../Floating";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliationsList } from "../../appRedux/actions/affiliations";

function Filter({ initialFetchComplete,searchText, setSearchText }) {
  const dispatch = useDispatch();
const { queryInfo } = useSelector((state) => ({
    queryInfo: state.affiliations.queryInfo
  }));
 
  
  // useEffect(() => {
  //   if (initialFetchComplete) {
  //     const timeout = setTimeout(() => {
  //       dispatch(
  //         getAffiliationsList({
  //           ...queryInfo,
  //           title: searchText === "" ? undefined : searchText,
  //           page: 1,
  //         })
  //       );
  //     }, 500);
  //     return () => {
  //       clearTimeout(timeout);
  //     };
  //   }
  // }, [searchText]);


  
  return (
    <div className="row filter-row">
      <div className="col-sm-12 col-md-5 pb-2 ">
        <FloatInput
          label="Affiliation "
          value={searchText}
          name="searchText"
          id="inputCustom"
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
      </div>
       <div className="col-sm-6 col-md-2  pb-2">
       
      </div>
      <div className="col-sm-6 col-md-3 pl-1 pr-1 pb-2">
       
      </div> 
      {/* <div className="col-sm-6 col-md-2 "></div> */}
      <div className="col-sm-12 col-md-2 ">
        <button
          className="btn btn-success btn-block full-width w-100"
          onClick={() => {
            setSearchText("");
          
          }}
        >
          Clear
        </button>
      </div>
    </div>
  );
}

Filter.propTypes = {};

export default Filter;
