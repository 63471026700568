import React from "react";
import DefaultEditor from "react-simple-wysiwyg";

const Texteditor = () => {
  const [values, setValue] = React.useState(
    `<p>According to Indeed.com in August 2022, the average annual salary for Technical Support is $51,758, though it can go considerably higher. The need for technology is constantly growing, as is the job market for tech support professionals.</p><p>In this course you’ll get an insider’s view into IT Support work. Learn about IT Support roles and levels, the support escalation matrix, ticketing systems, common support tools, and remote support software. </p><p>To help you with moving into the field, the course covers tech support career opportunities and career pathways from experienced, expert Information Technology professionals. </p><p>By the end of this course, you will be able to:</p><p>- Describe and develop a customer service and support mindset.</p><p>- Explore the features and benefits of ticketing systems.</p><p>- Identify support tools and systems used in technical support.</p><p>- Define the levels of IT support and the escalation matrix. </p><p>- Explore various tech support roles and responsibilities, including service-level agreement responsibilities.</p><p>- Recognize the career paths and progression in Technical Support.</p><p>This course provides several real-world inspired activities to demonstrate your skills in the areas of support tools, resolving IT issues, escalations and ticketing.</p><p>These skills will help you engage prospective employers and ask insightful questions that demonstrate your knowledge of the day-to-day role-related responsibilities.</p>`
  );
  function onChange(e) {
    console.log({ values: e.target.value });
    setValue(e.target.value);
  }
  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="content-page-header">
            <h3>Text Editor</h3>
          </div>
        </div>
        {/* /Page Header */}
        <div className="row">
          {/* Editor */}
          <div className="col-md-12">
            {/* <div className="card">
              <div className="card-header">
                <h5 className="card-title">Editor</h5>
              </div>
              <div className="card-body">
                <div id="summernote">
                  <DefaultEditor value={values} onChange={onChange} />
                </div>
              </div>
            </div> */}
            <div id="summernote">
              <DefaultEditor value={values} onChange={onChange} />
            </div>
          </div>
          {/* /Editor */}
        </div>
      </div>
    </div>
  );
};

export default Texteditor;
