import React, { useState } from "react";
import { Upload, message } from "antd";
import ImgCrop from "antd-img-crop";
import { apiURL } from "../../utils/config";
import { useSelector } from "react-redux";
import "./uploadImage.scss";
const UploadImage = ({ fileList, setFileList, imageUrl = null, setImageUrl, aspect = 4 / 2 }) => {
  // const [imageUrl, setImageUrl] = useState(null);
  console.log({ fileList });
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  return (
    <ImgCrop rotationSlider aspect={aspect}>
      <Upload
        name={"image"}
        action={`${apiURL}/v1/course/imageUpload`}
        listType="picture-card"
        fileList={fileList}
        onPreview={false}
        onRemove={false}
        onChange={false}
        disabled
        // onChange={onChange}
        // onPreview={onPreview}
        // onSuccess={(response, file) => {
        //   console.log("onSuccess response:", response);
        //   if (response && response.status === true) {
        //     setImageUrl(response.results);
        //     message.success(`${file.name} file uploaded successfully.`);
        //   }
        // }}
        // onError={(error) => {
        //   console.log("onError:", error);
        //   message.error("Upload failed.");
        // }}
      >
        {/* {fileList.length < 1 && "+ Upload"} */}
      </Upload>
    </ImgCrop>
  );
};
export default UploadImage;
