import {
  CATEGORY_FILTER_SUCCESS,
  CATEGORY_CREATE_SUCCESS,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_INFO_SUCCESS,
  CATEGORY_UPDATE_SUCCESS,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_FILTER_HANDLING,
  CATEGORY_SEARCH_NAME,
  CLEAR_CATEGORY_INFO,
  CATEGORY_LIST,
} from "../actionTypes/categories";
import {
  ON_SHOW_MESSAGE,
  INIT_URL,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER,
  HIDE_DIALOG,
} from "../actionTypes/common";
import { userSignOut } from "./auth";
// import AllDataService from '../services/all.service';

import axiosHttp from "../services/axiosCommon";

// export const getCategoryFilter = () => async (dispatch) => {
//   try {
//     const promises = [
//       axiosHttp.get("/v1/filtration"),
//       axiosHttp.get("/v1/affiliationFilter"),
//       axiosHttp.get("/v1/categoryTypeFilter"),
//       axiosHttp.get("/v1/categoryFilter"),
//     ];

//     const response = await Promise.all(promises);
//     const results = response.map((res) => res.data.status && res.data.results);
//     console.log({ results });
//     if (results.length === 0) {
//       dispatch({
//         type: ON_SHOW_MESSAGE,
//         payload: {
//           message: "Some error has occurred",
//           status: 500,
//           error: true,
//         },
//       });
//     } else {
//       dispatch({
//         type: ON_HIDE_LOADER,
//       });
//       dispatch({
//         type: CATEGORY_FILTER_SUCCESS,
//         payload: {
//           ...results[0]["data"],
//           ...results[1]["data"],
//           ...results[2]["data"],
//           ...results[3]["data"],
//         },
//       });
//     }
//   } catch (err) {
//     const { response } = err;
//     if (response && response.status === 401) {
//     } else {
//       dispatch({
//         type: ON_SHOW_MESSAGE,
//         payload: {
//           message: "Some error has occurred",
//           status: 500,
//           error: true,
//         },
//       });
//     }
//     return Promise.reject(err);
//   }
// };
// export const getCourseFilter = () => async (dispatch) => {
//   try {
//     const result = await axiosHttp.get("/v1/filtration");
//     const { data } = result;
//     if (!data.status) {
//       dispatch({
//         type: ON_SHOW_MESSAGE,
//         payload: data,
//       });
//     } else {
//       dispatch({
//         type: COURSE_FILTER_SUCCESS,
//         payload: data.results,
//       });
//     }

//     return Promise.resolve(data);
//   } catch (err) {
//     const { response } = err;
//     if (response && response.status === 401) {
//     } else {
//       dispatch({
//         type: ON_SHOW_MESSAGE,
//         payload: {
//           message: "Some error has occurred",
//           status: 500,
//           error: true,
//         },
//       });
//     }
//     return Promise.reject(err);
//   }
// };

export const getCategories = (paramsObj) => async (dispatch) => {
  try {
    const result = await axiosHttp.get("/categories/list", {
      params: paramsObj,
    });
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: CATEGORY_LIST,
        payload: data.data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const getCategoriesList = (queryInfo) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.get("/v1/category", {
      params: queryInfo,
    });
    const { data } = result;
    console.log({ responceData: data });
    if (!data.status && data.code !== 200) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: "/categories/list",
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: CATEGORY_LIST_SUCCESS,
        payload: { data: data.results, queryInfo },
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const getCategoryInfo = (categoryId) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.get("/v1/category/" + categoryId);
    const { data } = result;
    if (!data.status) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: ON_HIDE_LOADER,
      });
      // dispatch({
      //   type: COURSE_INFO_SUCCESS,
      //   payload: data,
      // });

      dispatch({
        type: CATEGORY_INFO_SUCCESS,
        payload: data.results,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      // dispatch(
      //   userSignOut({
      //     changeMessage: true,
      //     payload: {
      //       message: response.data.message,
      //       status: response.status,
      //       error: true,
      //     },
      //   })
      // );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          message: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const setCategoriesInfo = (info) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  dispatch({
    type: CATEGORY_INFO_SUCCESS,
    payload: info,
  });
};
export const createCategories = (infoData) => async (dispatch) => {
  console.log({ infoData });
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.post("/v1/category", infoData);
    const { data } = result;
    if (!data.status) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      return Promise.reject(data);
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      return Promise.resolve(data);
    }
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};

export const updateCategories = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  const { categoryId } = infoData;
  try {
    const result = await axiosHttp.put("/v1/category/", infoData);
    const { data } = result;
    if (!data.status) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      return Promise.reject(data);
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      // dispatch({ type: INIT_URL, payload: "/category/list" });
      return Promise.resolve(infoData);
    }
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const deleteCategories = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const { categoryId, queryInfo, status } = infoData;
    const result = await axiosHttp.delete("/v1/category", { data: { categoryId: categoryId } });
    // const result = await axiosHttp.delete("/courses", infoData);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      // dispatch({
      //   type: INIT_URL,
      //   payload: "/coursess/list",
      // });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch(getCategoriesList(queryInfo));
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};

export const onChangeSearchName = (info) => async (dispatch) => {
  dispatch({
    type: CATEGORY_SEARCH_NAME,
    payload: info,
  });
};

export const onChangeFilterHandling = (info) => async (dispatch) => {
  dispatch({
    type: CATEGORY_FILTER_HANDLING,
    payload: info,
  });
};
export const clearcategoriesInfo = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CATEGORY_INFO,
  });
};
