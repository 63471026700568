import { apiURL } from "../../utils/config";
import {
  CURRENCY_DATA,
  CHANGE_CURRENCY,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  ON_HIDE_MESSAGE,
  ON_SHOW_MESSAGE,
  ON_HIDE_MESSAGE_IMAGE,
  ON_SHOW_MESSAGE_IMAGE,
  //   SUCCESS_MESSAGE,
  INIT_URL,
  SHOW_DIALOG,
  HIDE_DIALOG,
  SHOW_DIALOG_INNER,
  HIDE_DIALOG_INNER,
  SEARCH_NAME,
  SWITCH_TO,
  // switchTo
} from "../actionTypes/common";

import axios from "axios";

export const getFreeCurrencyApi = () => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axios.get(
      apiURL + "/v1/currency"
      // "https://api.freecurrencyapi.com/v1/latest?apikey=fca_live_2Ai2VMlueK641vGTXyv9PtbUUdWtfh0xqGAzlLwt"
    );
    const { data } = result;
    if (!data.status || data.code !== 200) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: CURRENCY_DATA,
        payload:data.results ,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      // dispatch(
      //   userSignOut({
      //     changeMessage: true,
      //     payload: {
      //       message: response.data.message,
      //       status: response.status,
      //       error: true,
      //     },
      //   })
      // );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          message: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const changeCurrency = (currency) => async (dispatch) => {
  dispatch({
    type: CHANGE_CURRENCY,
    payload: currency,
  });
};
export const onChangeSearchName = (info) => async (dispatch) => {
  dispatch({
    type: SEARCH_NAME,
    payload: info,
  });
};
export const initURL = (url) => async (dispatch) => {
  dispatch({
    type: INIT_URL,
    payload: url,
  });
};

export const showLoader = () => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
};

export const hideLoader = () => async (dispatch) => {
  dispatch({
    type: ON_HIDE_LOADER,
  });
};

export const showMessage = (info) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_MESSAGE,
    payload: info,
  });
};
export const hideMessage = () => async (dispatch) => {
  dispatch({
    type: ON_HIDE_MESSAGE,
  });
};
export const showMessageImage = (info) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_MESSAGE_IMAGE,
    payload: info,
  });
};
export const hideMessageImage = () => async (dispatch) => {
  dispatch({
    type: ON_HIDE_MESSAGE_IMAGE,
  });
};
export const showDialog = (info) => async (dispatch) => {
  dispatch({
    type: SHOW_DIALOG,
    payload: info,
  });
};
export const hideDialog = () => async (dispatch) => {
  dispatch({
    type: HIDE_DIALOG,
  });
};
export const showDialogInner = (info) => async (dispatch) => {
  dispatch({
    type: SHOW_DIALOG_INNER,
    payload: info,
  });
};
export const hideDialogInner = () => async (dispatch) => {
  dispatch({
    type: HIDE_DIALOG_INNER,
  });
};
export const switchToEmployee = (info) => async (dispatch) => {
  dispatch({
    type: SWITCH_TO,
    payload: info,
  });
};
