import React, { useEffect, useState } from "react";

import { FloatInput, FloatSelect, FloatSelectObject } from "../Floating";
import { useDispatch, useSelector } from "react-redux";
import { getStaffsList } from "../../appRedux/actions/staffs";

function Filter({ initialFetchComplete,searchText, setSearchText }) {
  const dispatch = useDispatch();
 
  const { queryInfo } = useSelector((state) => ({
    queryInfo: state.staffs.queryInfo,
  }));
 
 

  return (
    <div className="row filter-row">
      <div className="col-sm-6 col-md-4 pb-2">
        <FloatInput
          label="Staff "
          value={searchText}
          name="searchText"
          id="inputCustom"
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
      </div>
      <div className="col-sm-6 col-md-3  pb-2">
        
      </div>
      <div className="col-sm-6 col-md-3 pl-1 pr-1 pb-2">
       
      </div>
      {/* <div className="col-sm-6 col-md-2 "></div> */}
      <div className="col-sm-6 col-md-2 ">
        <button
          className="btn btn-success btn-block full-width w-100"
          onClick={() => {
            setSearchText("");
          }}
        >
          Clear
        </button>
      </div>
    </div>
  );
}

Filter.propTypes = {};

export default Filter;
