import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider, Field } from "formik";

import { Input, Select as SelectAnt } from "antd";
import {
  createAffiliations,
  getAffiliationsList,
  updateAffiliations,
} from "../../appRedux/actions/affiliations";
import { UploadImage } from ".";
import { apiURL } from "../../utils/config";
import { hideDialog } from "../../appRedux/actions/common";

const { Option } = SelectAnt;
const INITIAL_STATE = {
  name: "",
  code: "",
  imageUrl: "",
};
function FormView(props) {
  const { affiliationInfo } = useSelector((state) => ({
    affiliationInfo: state.affiliations.affiliationInfo,
  }));

  // const handleDateChange1 = (date) => {
  //   setSelectedDate1(date);
  // };
  const { isUpdate } = props;
  const [formInfo, setFormInfo] = useState(INITIAL_STATE);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // dispatch(hideMessage());
    if (isUpdate && affiliationInfo && affiliationInfo._id) {
      setFormInfo({
        ...INITIAL_STATE,
        ...affiliationInfo,
      });
    } else {
      //console.log("");
      setFormInfo({
        ...INITIAL_STATE,
      });
    }
  }, [isUpdate, affiliationInfo]);

  const FormSchema = Yup.object().shape({
    name: Yup.string().required("affiliation name is required."),
    code: Yup.string().required("Affiliation code is required."),
    imageUrl: Yup.string().required("Affiliation image is required."),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInfo,
    validationSchema: FormSchema,
    onSubmit: (data) => {
      console.log({ data });
      if (isUpdate && affiliationInfo && affiliationInfo._id) {
        dispatch(
          updateAffiliations({
            ...data,
            affiliationId: affiliationInfo._id,
            _id: undefined,
          })
        ).then(() => {
          dispatch(getAffiliationsList());
          dispatch(hideDialog());
        });
      } else {
        dispatch(
          createAffiliations({
            ...data,
          })
        ).then(() => {
          dispatch(getAffiliationsList());
          dispatch(hideDialog());
        });
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;
  console.log({ errors, touched, values });

  return (
    <FormikProvider value={formik}>
      <Form
        id="contactForm"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        className="signin-form"
      >
        {/* <div>
          <label className="col-form-label">
            <b>Course Details:</b>
          </label>
        </div> */}

        <div className="row m-0">
          <div className="col-md-6">
            <div className="row m-0">
              <div className="col-md-12">
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Name <span className="text-danger">*</span>
                  </label>
                  <input className="form-control" type="text" {...getFieldProps("name")} />
                  <p className="error-msg text-danger">{touched.name && errors.name}</p>
                </div>
              </div>

              <div className="col-sm-12">
                <div className="form-group">
                  <label className="col-form-label">
                    Code<span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    {...getFieldProps("code")}
                    readOnly={isUpdate}
                    disabled={isUpdate}
                  />
                  <p className="error-msg text-danger">{touched.code && errors.code}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-3">
              <label className="col-form-label"> Affiliation Image</label>
              <div>
                <UploadImage
                  {...{
                    fileList:
                      values.imageUrl !== ""
                        ? [
                            {
                              uid: "-1",
                              name: "image.png",
                              status: "done",
                              url: values.imageUrl,
                            },
                          ]
                        : [],
                    setFileList: (list) => {
                      var newList = [];
                      for (let i = 0; i < list.length; i++) {
                        let listInfo = list[i];
                        if (
                          listInfo.status === "done" &&
                          listInfo.response &&
                          listInfo.response.status === 200
                        ) {
                          newList.push({
                            name: listInfo.name,
                            status: listInfo.status,
                            size: listInfo.size,
                            type: listInfo.type,
                            uid: listInfo.uid,
                            url: apiURL + listInfo.response.data,
                          });
                        } else {
                          newList.push(listInfo);
                        }
                      }
                      // setFieldValue(
                      //   "affiliationImage",
                      //   newList.length ? newList[0]["url"] : ""
                      // );
                    },
                    image: values.imageUrl,
                    setImageUrl: (url) => {
                      setFieldValue("imageUrl", url);
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="submit-section mb-5">
          <button type="submit" className="btn btn-primary submit-btn">
            {isUpdate && affiliationInfo && affiliationInfo._id ? "Update" : "Submit"}
          </button>
        </div>
      </Form>
    </FormikProvider>
  );
}

export default FormView;
