import {
    CATEGORY_LIST,
    CATEGORY_CREATE_SUCCESS,
    CATEGORY_LIST_SUCCESS,
    CATEGORY_INFO_SUCCESS,
    CATEGORY_FILTER_HANDLING,
    CATEGORY_SEARCH_NAME,
    CATEGORY_UPDATE_SUCCESS,
    // CATEGORY_CATEGORY_SUCCESS,
    // CATEGORY_CATEGORY_CREATE_SUCCESS,
    // CATEGORY_CATEGORY_UPDATE_SUCCESS,
    CLEAR_CATEGORY_INFO,
    // CATEGORY_CATEGORY_INFO,
    CATEGORY_FILTER_SUCCESS,
  } from "../actionTypes/categories";
  
  // import {
  //   COLUMN_HANDLING,
  //   _CATEGORY_DATA,
  //   _CATEGORY_FILTER_DATA,
  //   _LANGUAGE_DATA,
  //   _MERCHANT_DATA,
  // } from "../../_mocks_/category";
  // import { COLUMN_HANDLING } from "../../_mocks_/course";
  
  const INIT_STATE = {
    // categoryFilters: _CATEGORY_FILTER_DATA,
    categoryList: [],
    isSuccess: false,
    categories: [],
    categoryInfo: {},
    categories: [],
    categoryInfo: {},
    queryInfo: {
      page: 1,
      size: 10,
      next: false,
    },
    totalRecords: 0,
    page: 1,
    pages: 0,
    // columnHandling: COLUMN_HANDLING,
    searchName: "",
    searchStatus: false,
    categoryType: "Active",
    isUpdate: false,
    isCategory: true,
    isInfo: false,
    currentStep: 0,
    isEdit: false,
  };
  
  function CategoryReducer(state = INIT_STATE, action) {
    const { type, payload } = action;
  
    switch (type) {
      // case PLATFORM_FILTER_SUCCESS: {
      //   console.log({ payload });
      //   return {
      //     ...state,
      //     // // platformFilters: { ..._PLATFORM_FILTER_DATA, ...payload },
      //   };
      // }
      // case PLATFORM_CATEGORY_INFO: {
      //   return {
      //     ...state,
      //     categoryInfo: payload,
      //   };
      // }
      case CLEAR_CATEGORY_INFO: {
        return {
          ...state,
          categoryInfo: {},
        };
      }
      case CATEGORY_LIST: {
        console.log({ payload });
        return {
          ...state,
          categories: payload,
        };
      }
      // case PLATFORM_CATEGORY_SUCCESS: {
      //   console.log({ payload });
      //   return {
      //     ...state,
      //     categories: payload,
      //     isCategory: false,
      //   };
      // }
      // case PLATFORM_CATEGORY_CREATE_SUCCESS: {
      //   console.log({ payload });
      //   return {
      //     ...state,
      //     categories: { payload, ...state.categories },
      //     isCategory: false,
      //   };
      // }
      // case PLATFORM_CATEGORY_UPDATE_SUCCESS: {
      //   var list = state.categories.map((row) => (row._id === payload._id ? payload : row));
      //   // console.log({ payload, info });
      //   return {
      //     ...state,
      //     categories: list.filter((row) => row.active),
      //     isCategory: false,
      //   };
      // }
      case CATEGORY_LIST_SUCCESS: {
        return {
          ...state,
          searchStatus: false,
          categoryList: payload.data,
          queryInfo: {
            ...payload.queryInfo,
          },
          totalRecords: payload.count,
          pages: payload.pages,
          isSuccess: false,
          isCategory: true,
          // page: payload.queryInfo.page || 0
        };
      }
  
      case CATEGORY_CREATE_SUCCESS: {
        return {
          ...state,
          categoryList: [payload, ...state.categoryList],
          categoryInfo: payload,
          currentStep: payload.currentStep + 1,
          isSuccess: true,
          isEdit: payload && payload._id ? true : false,
        };
      }
  
      case CATEGORY_UPDATE_SUCCESS: {
        console.log({ payload });
        return {
          ...state,
          categoryList: state.categoryList.map(
            (obj) => (payload._id === obj._id ? payload : obj)
            // data.columnHandling.map((row) => obj.id === row)
          ),
          categoryInfo: payload,
          currentStep: payload.currentStep + 1,
          isSuccess: true,
          isUpdate: true,
        };
      }
  
      case CATEGORY_INFO_SUCCESS: {
        console.log({ payload });
        return {
          ...state,
          categoryInfo: payload,
          isInfo: true,
          isEdit: true,
        };
      }
      case CATEGORY_FILTER_HANDLING: {
        console.log({ payload });
        return {
          ...state,
          columnHandling: payload.columnHandling,
          categoryType: payload.categoryType,
        };
      }
      case CATEGORY_SEARCH_NAME: {
        console.log({ payload });
        return {
          ...state,
          searchName: payload,
          searchStatus: true,
          queryInfo: { page: 1, size: state.queryInfo.size },
        };
      }
  
      default:
        return state;
    }
  }
  
  export default CategoryReducer;
  