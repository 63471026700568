import {
  SIGNOUT_AUTH_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  ALL_CLEAR_SUCCESS,
  TOKEN_REMOVE,
  AUTH_LEVEL,
} from "../actionTypes/auth";
import { ON_SHOW_MESSAGE, INIT_URL, ON_HIDE_MESSAGE, ON_HIDE_LOADER } from "../actionTypes/common";

import axiosHttp from "../services/axiosCommon";

export const authLevelChannge = (info) => async (dispatch) => {
  dispatch({
    type: AUTH_LEVEL,
    payload: info,
  });
};
export const tokenRemove = () => async (dispatch) => {
  dispatch({
    type: TOKEN_REMOVE,
  });
};

export const userSignIn = (user) => async (dispatch) => {
  try {
    const result = await axiosHttp.post("/v2/admin/login", user);
    const { data } = result;
    console.log({ data });
    if (!data.status) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { error: !data.status, userMessage: data.message },
      });
      return Promise.reject();
    } else {
      // dispatch({
      //   type: ON_SHOW_MESSAGE,
      //   payload: data,
      // });

      return Promise.resolve(data.data.accessToken);
    }
  } catch (err) {
    const { response } = err;
    const { data } = response;
    if (response && response.status === 401) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: response.data.message,
          status: response.status,
          error: true,
        },
      });
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const adminMe = (accessToken) => async (dispatch) => {
  console.log({ accessToken });
  try {
    const result = await axiosHttp.get("/v2/admin/me", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json", // Adding this in case your server requires it
      },
    });
    const { data } = result;
    if (!data.status) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      return Promise.reject();
    } else {
      dispatch({
        type: INIT_URL,
        payload: "/",
      });

      dispatch({
        type: SIGNIN_USER_SUCCESS,
        payload: { user: data.results, accessToken },
      });
      return Promise.resolve(data);
    }
  } catch (err) {
    const { response } = err;
    const { data } = response;
    if (data && data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { ...data },
      });
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};

export const userSignUp = (user) => async (dispatch) => {
  try {
    const result = await axiosHttp.post("/v2/admin/AdminSign", user);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: "/verify-code",
      });
      dispatch({
        type: ON_HIDE_LOADER,
      });

      // dispatch({
      //   type: UPDATE_PROFILE_KEY,
      //   payload: data.data,
      // });
      // dispatch({
      //   type: UPDATE_PROFILE_EDUCATION,
      //   payload: data.education,
      // });
      dispatch({
        type: SIGNUP_USER_SUCCESS,
        payload: { ...data.data },
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    const { data } = response;
    if (data && data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { ...data },
      });
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};

export const userSignOut = (info) => async (dispatch) => {
  console.log("userSignOut--------");
  dispatch({
    type: INIT_URL,
    payload: "/auth/login",
  });

  dispatch({
    type: SIGNOUT_AUTH_USER_SUCCESS,
  });
  // dispatch({
  //   type: SIGNOUT_PROFILE_USER_SUCCESS,
  // });
  localStorage.removeItem("user");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("switchOn");
  window.localStorage.clear();
  localStorage.clear();
  if (info && info.changeMessage === true) {
    dispatch({
      type: ON_SHOW_MESSAGE,
      payload: info.payload,
    });
  } else {
    dispatch({
      type: ON_SHOW_MESSAGE,
      payload: {
        userMessage: "Logout successfully",
        status: 200,
        error: true,
      },
    });
  }
  const timeout = setTimeout(() => {
    dispatch({
      type: ON_HIDE_MESSAGE,
    });
    dispatch({
      type: ALL_CLEAR_SUCCESS,
    });
  }, 3000);

  dispatch({ type: ON_HIDE_LOADER });
  return () => {
    clearTimeout(timeout);
  };

  // try {
  //   const result = await axiosHttp.post('/logout', {});
  //   const { data } = result;
  // dispatch({
  //   type: INIT_URL,
  //   payload: "/login"
  // });
  // dispatch({
  //   type: ON_SHOW_MESSAGE,
  //   payload: { userMessage: 'Logout successfully', status: 200, error: false }
  // });
  // dispatch({
  //   type: SIGNOUT_AUTH_USER_SUCCESS
  // });
  // if (data.error) {
  //   dispatch({
  //     type: ON_SHOW_MESSAGE,
  //     payload: data
  //   });
  // } else {
  //   localStorage.setItem('accessToken', data.authToken);
  //   localStorage.setItem('user', JSON.stringify(data.data));
  //   dispatch({
  //     type: INIT_URL,
  //     payload: "/login"
  //   });
  //   dispatch({
  //     type: ON_SHOW_MESSAGE,
  //     payload: data
  //   });
  //   dispatch({
  //     type: SIGNIN_USER_SUCCESS,
  //     payload: data
  //   });
  // }

  //   return Promise.resolve(data);
  // } catch (err) {
  //   dispatch({
  //     type: ON_SHOW_MESSAGE,
  //     payload: { userMessage: 'Request failed', status: 500, error: true }
  //   });
  //   return Promise.reject(err);
  // }
};
