import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { hideDialogInner, showDialog } from "../../appRedux/actions/common";
import "./modal.css";
function DialogInnerModal({ bodyView, infoId = "01" }) {
  const dispatch = useDispatch();
  const { dialog, title, size, hideHeader, id } = useSelector((state) => ({
    dialog: state.common.dialogInner,
    title: state.common.title,
    size: state.common.size,
    hideHeader: state.common.hideHeader,
    id: state.common.id || "01",
  }));
  console.log({ dialog, title, size, hideHeader, id, infoId }, "============");

  // useEffect(() => {
  //   // Create a new <div> element
  //   const btnClose = document.getElementsByClassName("btn-close");

  //   // Create a <span> element
  //   const newSpan = document.createElement("span");

  //   // Add HTML content to the <span> element
  //   newSpan.innerHTML = '<span aria-hidden="true">×</span>';

  //   // Append the <span> element to the <div> element
  //   btnClose.appendChild(newSpan);

  //   // Append the new <div> element to the body
  //   document.body.appendChild(btnClose);

  //   // Clean up by removing the new <div> element when the component unmounts
  //   return () => {
  //     document.body.removeChild(btnClose);
  //   };
  // }, []); // Empty dependency array means this effect runs only once, similar to componentDidMount

  return (
    <Modal
      centered
      isOpen={dialog && id === infoId}
      size={size}
      className="custom-modal custom-modal-button"
      // key={Math.random()}
    >
      {!hideHeader && (
        <ModalHeader toggle={() => dispatch(hideDialogInner())}>{title} </ModalHeader>
      )}
      <ModalBody>{bodyView}</ModalBody>
    </Modal>
  );
}

export default DialogInnerModal;
