import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormView, DialogModal } from ".";
import { hideDialog, showDialog } from "../../appRedux/actions/common";
import { deleteCategories } from "../../appRedux/actions/categories";

//
const BodyView = ({ info }) => {
  const dispatch = useDispatch();
  const { queryInfo } = useSelector((state) => ({
    queryInfo: state.categories.queryInfo,
  }));

  return (
    <Fragment>
      {/* <p>Are you sure want to delete?</p> */}
      <div className="form-header">
        <h3>{info.status === "inactive" ? "Activate" : "Remove"} Category </h3>
        <p>
          Are you sure want to {info.status === "inactive" ? "activate" : "remove"} this category?
        </p>
      </div>
      <div className="modal-btn delete-action">
        <div className="row">
          <div className="col-6">
            <button
              onClick={() => dispatch(hideDialog())}
              className="btn btn-primary continue-btn"
              style={{ width: "100%" }}
            >
              Disagree
            </button>
          </div>
          <div className="col-6">
            <button
              onClick={() => {
                dispatch(hideDialog());
                // navigate(linkTo, { replace: true });
                dispatch(
                  deleteCategories({
                    categoryId: info._id,
                    queryInfo,
                    // status: info.status === "inactive" ? "active" : "inactive",
                  })
                );
              }}
              className="btn btn-primary cancel-btn"
              style={{ width: "100%" }}
            >
              Agree
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

function DeleteModal({ info }) {
  // const { info } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { dialog } = useSelector((state) => ({
    dialog: state.common.dialog,
  }));
  useEffect(() => {
    if (!dialog) {
      setOpen(dialog);
    }
  }, [dialog]);
  return (
    <Fragment>
      <button
        className="dropdown-item"
        onClick={() => {
          setOpen(true);
          dispatch(showDialog({ title: "", hideHeader: true }));
        }}
        // onClick={() => console.log("delete", record)}
        key={"delete_category"}
      >
        <i className="fa fa-trash m-r-5" /> Remove
      </button>
      {open && <DialogModal bodyView={<BodyView info={info} />}></DialogModal>}
    </Fragment>
  );
}

export default DeleteModal;
