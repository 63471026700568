export const LEVEL_CREATE = "LEVEL_CREATE";
export const LEVEL_CREATE_SUCCESS = "LEVEL_CREATE_SUCCESS";
export const LEVEL_CREATE_ERROR = "LEVEL_CREATE_ERROR";
export const LEVEL_LIST = "LEVEL_LIST";
export const LEVEL_LIST_SUCCESS = "LEVEL_LIST_SUCCESS";
export const LEVEL_LIST_ERROR = "LEVEL_LIST_ERROR";
export const LEVEL_INFO = "LEVEL_INFO";
export const LEVEL_INFO_SUCCESS = "LEVEL_INFO_SUCCESS";
export const LEVEL_INFO_ERROR = "LEVEL_INFO_ERROR";
export const LEVEL_UPDATE_SUCCESS = "LEVEL_UPDATE_SUCCESS";
export const LEVEL_UPDATE_ERROR = "LEVEL_UPDATE_ERROR";
export const LEVEL_DELETE_SUCCESS = "LEVEL_DELETE_SUCCESS";
export const LEVEL_FILTER_HANDLING = "LEVEL_FILTER_HANDLING";
export const LEVEL_SEARCH_NAME = "LEVEL_SEARCH_NAME";
export const LEVELE_LEVEL_SUCCESS = "LEVEL_LEVEL_SUCCESS";
export const LEVEL_LEVEL_CREATE_SUCCESS = "LEVEL_LEVEL_CREATE_SUCCESS";
export const LEVEL_LEVEL_UPDATE_SUCCESS = "LEVEL_LEVEL_UPDATE_SUCCESS";
export const CLEAR_LEVEL_INFO = "CLEAR_LEVEL_INFO";
export const LEVEL_LEVEL_INFO = "LEVEL_LEVEL_INFO";

export const LEVEL_FILTER_SUCCESS = "LEVEL_FILTER_SUCCESS";
