import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Checkbox, ConfigProvider, Table, Tooltip, message } from "antd";
import ReactStars from "react-stars";
import { Link } from "react-router-dom";
import { EditInfo, DeleteModal } from ".";
import { currencyConverter } from "../../utils/currency";
import { getCoursesList } from "../../appRedux/actions/courses";

import { extractDomainAndFirstSegment } from "../../utils/courseUrl";

function ListView(props) {
  const dispatch = useDispatch();
  const {
    courseList,
    queryInfo,
    totalRecords,
    selectedCurrency,
    currencyData,
  } = useSelector((state) => ({
    courseList: state.courses.courseList,
    queryInfo: state.courses.queryInfo,
    totalRecords: state.courses.totalRecords,
    selectedCurrency: state.common.selectedCurrency,
    currencyData: state.common.currencyData,
  }));

  const handleTableChange = (pagination, filters, sorter) => {
    console.log({ pagination, filters, sorter });
    const { current, pageSize } = pagination;
    dispatch(
      getCoursesList({
        ...queryInfo,
        page: current,
        size: pageSize,
      })
    );
  };
  const handleCopy = (website_url) => {
    const textToCopy = website_url;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        message.success("URL copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        message.error("Failed to copy URL");
      });
  };
  const columns = [
    {
      key: 1,
      title: "Title",
      dataIndex: "title",
      render: (title, record) => (
        <h2
          className="table-avatar"
          style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
        >
          <Link to={`/course/${record._id}`}>{title}</Link>
        </h2>
      ),
    },
    {
      key: 2,
      title: "Platforms",
      dataIndex: "merchant",
      align: "center",
      render: (merchant, record) => (
        <p className="px-3  m-0 text-capitalize btn btn-white btn-sm btn-rounded">
          {merchant}{" "}
        </p>
      ),
    },

    {
      key: 4,
      title: "Category",
      dataIndex: "category",
      align: "center",
      render: (category, record) => (
        <p className="px-3 py-2 m-0 text-capitalize">
          {category && category.name ? category.name : ""}{" "}
        </p>
      ),
    },
    {
      key: 5,
      title: "Affiliation",
      dataIndex: "affiliation",
      render: (affiliation, record) => (
        <p className="px-3 py-2 m-0">{affiliation && affiliation.name} </p>
      ),
    },
    {
      key: 5,
      title: "Affiliated",
      dataIndex: "affiliated",
      align: "center",
      render: (affiliated, record) => (
        <p
          title={affiliated}
          className={`px-3 py-2 m-0 badge ${
            affiliated || record.affilated
              ? "  bg-inverse-success"
              : " bg-inverse-danger"
          }`}
        >
          {affiliated || record.affilated ? "Impact" : "Direct"}{" "}
        </p>
      ),
    },
    {
      key: 10,
      title: "Url",
      dataIndex: "website_url",
      align: "center",
      render: (website_url, record) => (
        <Tooltip placement="top" title={website_url}>
          <p
            // title={website_url}
            className="px-3 py-2 m-0 "
            onClick={() => handleCopy(website_url)}
            style={{ cursor: "pointer", fontSize: "12px" }}
          >
            {extractDomainAndFirstSegment(website_url)}
          </p>{" "}
        </Tooltip>
      ),
    },

    {
      key: 7,
      title: "Level",
      dataIndex: "level",
      align: "center",
      render: (level, record) => (
        <p className="px-3 py-2 m-0 text-capitalize">
          {level && level.name ? level.name : ""}{" "}
        </p>
      ),
    },
    {
      key: 8,
      title: "Language",
      dataIndex: "language",
      align: "center",
      render: (language, record) => (
        <p className="px-3 py-2 m-0 text-capitalize">{language} </p>
      ),
    },
    {
      key: 9,
      title: "Course Type",
      dataIndex: "course_type",
      align: "center",
      render: (course_type, record) => (
        <p className="px-3 py-2 m-0 text-capitalize">
          {course_type && course_type.name ? course_type.name : ""}{" "}
        </p>
      ),
    },

    {
      key: 11,
      title: "Course Duration",
      dataIndex: "duration",
      align: "center",
    },

    {
      key: 3,
      title: "Ratings",
      dataIndex: "rating",
      // align: "center",
      render: (rating, record) => (
        <ReactStars
          count={5}
          // onChange={ratingChanged}
          value={
            rating && rating !== ""
              ? parseFloat(`${rating}`.replace(" stars", ""))
              : 0
          }
          // size={24}
          edit={false}
          color2={"#ffd700"}
        />
      ),
    },

    {
      key: 6,
      title: "Mode",
      dataIndex: "mode",
      align: "center",
      render: (mode, record) => (
        <p className="px-3 py-2 m-0 text-capitalize">
          {mode && mode.name ? mode.name : ""}{" "}
        </p>
      ),
    },
    {
      key: 12,
      title: "Price",
      dataIndex: "price",
      render: (affiliation, course) => {
        const price = currencyConverter(
          course.price,
          course.price_detail,
          selectedCurrency,
          currencyData
        );
        const discount = parseFloat() + parseFloat(price);
        currencyConverter(
          course.discount,
          course.price_detail,
          selectedCurrency,
          currencyData
        );

        return (
          <p className="px-3 py-2 m-0">
            {price && price !== "" && price !== "0" && price !== 0
              ? `${selectedCurrency.symbol}${price}`
              : "Free"}
            <p
              style={{
                color: "#AFAFAF",
                textDecoration: "line-through",
                textDecorationColor: "#AFAFAF",
              }}
            >
              {discount
                ? `${selectedCurrency.symbol}${discount.toFixed(2)}`
                : ""}
            </p>{" "}
          </p>
        );
      },
    },
    {
      key: 13,
      title: "Action",
      align: "center",
      render: (text, record) => (
        <div className="dropdown dropdown-action text-center">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <EditInfo info={record} key={"edit_info"} />

            <DeleteModal info={record} />
          </div>
        </div>
      ),
    },
  ];
  const defaultCheckedList = columns.map((item) => item.key);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const options = columns
    .map(({ key, title }) => {
      if (
        !(
          title === "Title" ||
          title === "Action" ||
          title === "Platforms" ||
          title === "Affiliation"
        )
      ) {
        return {
          label: title,
          value: key,
        };
      }
      return undefined;
    })
    .filter((option) => option !== undefined);

  const newColumns = columns.map((item) => {
    if (
      !(
        item.title === "Title" ||
        item.title === "Action" ||
        item.title === "Platforms" ||
        item.title === "Affiliation"
      )
    ) {
      return { ...item, hidden: !checkedList.includes(item.key) };
    } else {
      return { ...item, hidden: false };
    }
  });
  // const newColumns = columns.map((item) => ({
  //   ...item,
  //   hidden: !checkedList.includes(item.key),
  // }));

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };
  return (
    <div className="row m-0 mt-3">
      <div className="col-md-12 p-0" id="table-responsive">
        <div className="mb-3 pb-2">
          <Checkbox.Group
            value={checkedList}
            options={options}
            onChange={(value) => {
              setCheckedList(value);
            }}
          />
        </div>

        <div className="table-responsive" id="setMinheight">
          {/* <ConfigProvider> */}
          <Table
            className="table-striped"
            pagination={{
              total: totalRecords,
              // defaultCurrent={6}
              current: queryInfo.page,
              pageSizeOptions: ["5", "10", "20", "50"],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              onShowSizeChange: (current, pageSize) => {
                console.log({ current, pageSize });
                dispatch(
                  getCoursesList({
                    ...queryInfo,
                    page: current,
                    size: pageSize,
                  })
                );
              }, //onShowSizeChange,
              itemRender: itemRender,
            }}
            onChange={(pagination, filters, sorter) =>
              handleTableChange(pagination, filters, sorter)
            }
            style={{ overflowX: "auto" }}
            columns={newColumns}
            // bordered
            dataSource={courseList}
            rowKey={(record) => record._id}
            // onChange={this.handleTableChange}
          />
          {/* </ConfigProvider> */}
        </div>
      </div>
    </div>
  );
}

export default ListView;
