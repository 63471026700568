import React, { useState } from "react";

import { DatePicker, Space, Select } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Option } = Select;
// picker="month"
const FloatDatePickerObject = (props) => {
  const [focus, setFocus] = useState(false);
  let {
    label,
    value,
    placeholder,
    required,
    id,
    onChange,
    options,
    showSearch,
    tag = "name",
    // value,
    // onChange,
    onBlur,
    dateFormat = "DD/MM/YYYY",
    disabled,
    disabledDate,
  } = props;

  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

  const requiredMark = required ? <span className="text-danger">*</span> : null;
  console.log({ value });
  return (
    <div
      className="float-label"
      id={id}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <DatePicker
        // defaultValue={[
        //   moment("2015/01/01", dateFormat),
        //   moment("2015/01/01", dateFormat),
        // ]}
        // format={dateFormat}
        placeholder={""} //{["", ""]}
        disabledDate={disabledDate}
        disabled={disabled}
        value={value && value !== "Invalid Date" && moment(value, dateFormat)}
        onChange={(date, dateString, id) => {
          console.log({ date, dateString, id });
          onChange(dateString);
        }}
        onBlur={onBlur}
        format={dateFormat}
        style={{
          width: "100%",
          height: "44px",
          border: "1px solid #ced4da",
          borderRadius: ".25rem",
          boxShadow: "none",
        }}
      />
      {/* <Select
        showSearch={showSearch}
        style={{ width: "100%" }}
        value={value}
        onChange={onChange}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
        }
      >
        {options.map((item, key) => {
          return (
            <Option key={Math.random()} value={item._id}>
              {item[tag]}
            </Option>
          );
        })}
      </Select> */}

      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  );
};

export default FloatDatePickerObject;
