import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useFormik, FormikProvider, Form, Field } from "formik";
import { updateProfile } from "../../appRedux/actions/profile";
import { ProfileIconFill } from "../../Routes/ImagePath";
import { ImageUpload } from ".";

const INITIAL_STATE = {
  name: "",
  employeeId: "",
  contactNo: "",
  email: "",
  dateOfBirth: "",
  address: "",
  gender: "",
  image: "",
};

const FormView = ({}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [formInfo, setFormInfo] = useState(INITIAL_STATE);

  useEffect(() => {
    if (user) {
      setFormInfo({
        ...INITIAL_STATE,
        ...user,
      });
    }
  }, [user]);

  const FormSchema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    employeeId: Yup.string().required("Employee ID is required."),
    contactNo: Yup.string().required("Contact No is required."),
    email: Yup.string().email("Invalid email format").required("Email is required."),
    dateOfBirth: Yup.date().required("Birthday is required."),
    gender: Yup.string().required("Gender is required."),
    address: Yup.string().required("Address is required."),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInfo,
    validationSchema: FormSchema,
    onSubmit: (data) => {
      dispatch(updateProfile({ ...data, id: data._id, _id: undefined }));
    },
  });

  const { values, errors, touched, handleSubmit, getFieldProps, setFieldValue, isSubmitting } =
    formik;

  return (
    <div>
      <div className="profile-img-wrap edit-img">
        <img
          className="inline-block"
          src={values && values.image ? values.image : ProfileIconFill}
          alt="user"
        />
        <div className="fileupload btn">
          <span className="btn-text">edit</span>{" "}
          <ImageUpload setImageUrl={(url) => setFieldValue("image", url)} />
        </div>
      </div>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <div className="row m-0">
            <div className="col-md-6">
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Name <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" {...getFieldProps("name")} />
                {touched.name && errors.name && (
                  <p className="error-msg text-danger">{errors.name}</p>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Employee ID <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  {...getFieldProps("employeeId")}
                  readOnly
                  disabled
                />
                {touched.employeeId && errors.employeeId && (
                  <p className="error-msg text-danger">{errors.employeeId}</p>
                )}
              </div>
            </div>
          </div>
          <div className="row m-0">
            <div className="col-md-6">
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Contact No <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" {...getFieldProps("contactNo")} />
                {touched.contactNo && errors.contactNo && (
                  <p className="error-msg text-danger">{errors.contactNo}</p>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Email <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="email"
                  {...getFieldProps("email")}
                  readOnly
                  disabled
                />
                {touched.email && errors.email && (
                  <p className="error-msg text-danger">{errors.email}</p>
                )}
              </div>
            </div>
          </div>
          <div className="row m-0">
            <div className="col-md-6">
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Birthday <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="date" {...getFieldProps("dateOfBirth")} />
                {touched.dateOfBirth && errors.dateOfBirth && (
                  <p className="error-msg text-danger">{errors.dateOfBirth}</p>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Gender <span className="text-danger">*</span>
                </label>
                <Field as="select" className="form-control" name="gender">
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Field>
                {touched.gender && errors.gender && (
                  <p className="error-msg text-danger">{errors.gender}</p>
                )}
              </div>
            </div>
          </div>
          <div className="input-block mb-3">
            <label className="col-form-label">
              Address <span className="text-danger">*</span>
            </label>
            <textarea className="form-control" type="text" {...getFieldProps("address")} />
            <p className="error-msg text-danger">{touched.address && errors.address}</p>
          </div>
          <div className="submit-section mb-5">
            <button type="submit" className="btn btn-primary submit-btn" disabled={isSubmitting}>
              Update
            </button>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default FormView;
