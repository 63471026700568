import React from "react";
import DefaultEditor from "react-simple-wysiwyg";

const TextEditor = ({ description, onChange, height }) => {
  let values = description;

  if (description.includes("<div") || description.includes("<p")) {
    values = description;
  } else {
    const aboutUsList =
      description && description !== ""
        ? description.includes("/n")
          ? description.includes("/n,")
            ? description.split("/n,")
            : description.split("/n")
          : description.split("*")
        : "";
    if (aboutUsList.length) {
      const formattedDescription = aboutUsList.map((line) => `<p>${line.trim()}</p>`).join("");
      values = formattedDescription;
    } else {
      values = aboutUsList;
    }
  }
  return (
    <div id="summernote">
      <DefaultEditor
        value={values}
        onChange={(e) => onChange(e.target.value)}
        containerProps={{ style: { height: height } }}
      />
    </div>
  );
};

export default TextEditor;
