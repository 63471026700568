import React, { useEffect, useState } from "react";
import { Filter, FloatRight, FormView, PagesHeader } from "../../components/Courses";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCourseFilter, getCoursesList } from "../../appRedux/actions/courses";
import Page from "../../components/Common/Page";
import { getFreeCurrencyApi } from "../../appRedux/actions/common";
import MetaTagForm from "../../components/Courses/MetaTagForm";

const CourseCreate = () => {
  const dispatch = useDispatch();
  const [initialFetchComplete, setInitialFetchComplete] = useState(false);

  const [viewStatus, setViewStatus] = useState("listView"); //"listView", "gridView"
  const courseList = useSelector((state) => state.courses.courseList);
  console.log({ courseList });

  useEffect(() => {
    dispatch(getCourseFilter({}));

    dispatch(getFreeCurrencyApi());
  }, [dispatch]);
  return (
    <Page title="Course">
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <PagesHeader
            pageTitle={"Course Create"}
            homePageUrl="/"
            homePageText="Dashboard"
            innerPageUrl={"/course/list"}
            innerPageText={"Course List"}
            activePageText={"Add New Course"}
            floatRight={""}
          />
          <div className="card">
            <div className="card-body">
              <FormView />
            </div>
          </div>

        </div>
        {/* /Page Content */}
      </div>
    </Page>
  );
};

export default CourseCreate;
