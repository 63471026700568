import React, { useEffect } from "react";
// import axios from "axios";
import Charts from "./charts";
// import Reports from "./Reports";
// import Statistics from "./statistics";
// import InvoiceTable from "./invoiceTable";
// import PaymentTable from "./paymentTable";
// import ClientTable from "./clientTable";
// import RecentTable from "./recentTable";
import Page from "../../components/Common/Page";
import PagesHeader from "../../components/Common/PagesHeader";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getDashboard } from "../../appRedux/actions/dashboard";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const { count } = useSelector((state) => ({
    count: state.dashboard.count,
  }));

  useEffect(() => {
    dispatch(getDashboard({}));
    // dispatch(getCoursesList({ page: 1, size: 10, sortByUpdatedAt: "desc" })).then(() => {});
  }, []);
  return (
    <Page title="Dashboard">
      {/* <div className="main-wrapper"> */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <PagesHeader
            pageTitle={"Welcome Admin!"}
            // homePageUrl="/"
            // homePageText="Welcome Admin!"
            // innerPageUrl={""}
            // innerPageText={""}
            activePageText={"Welcome Admin!"}
            floatRight={
              ""
              // <FloatRight viewStatus={viewStatus} onChange={(status) => setViewStatus(status)} />
            }
          />
          {/* <Breadcrumbs maintitle="Welcome Admin!" title="Dashboard" /> */}
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3" key={11}>
              <div className="card dash-widget">
                <div className="card-body">
                  <span className={`dash-widget-icon fa fa-book`} />
                  <div className="dash-widget-info">
                    <h3>{count.course || 0}</h3>
                    <span>Courses</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3" key={12}>
              <div className="card dash-widget">
                <div className="card-body">
                  <span className={`dash-widget-icon fa fa-cubes`} />
                  <div className="dash-widget-info">
                    <h3>{count.platform || 0}</h3>
                    <span>Platforms</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3" key={13}>
              <div className="card dash-widget">
                <div className="card-body">
                  <span className={`dash-widget-icon fa fa-university`} />
                  <div className="dash-widget-info">
                    <h3>{count.affiliation || 0}</h3>
                    <span>Affiliation</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3" key={14}>
              <div className="card dash-widget">
                <div className="card-body">
                  <span className={`dash-widget-icon fa fa-user`} />
                  <div className="dash-widget-info">
                    <h3>{count.enrolled || 0}</h3>
                    <span>Enrolled</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Charts */}
          <Charts />
          {/* /Charts */}
          {/* <Reports /> */}
          {/* <Statistics /> */}
          {/* <div className="row">
            <InvoiceTable />
            <PaymentTable />
          </div>

          <div className="row">
            <ClientTable />
            <RecentTable />
          </div> */}
        </div>
      </div>
      {/* </div> */}
    </Page>
  );
};

export default AdminDashboard;
