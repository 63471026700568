import {
  LANGUAGE_FILTER_SUCCESS,
  LANGUAGE_CREATE_SUCCESS,
  LANGUAGE_LIST_SUCCESS,
  LANGUAGE_INFO_SUCCESS,
  LANGUAGE_UPDATE_SUCCESS,
  LANGUAGE_DELETE_SUCCESS,
  LANGUAGE_FILTER_HANDLING,
  LANGUAGE_SEARCH_NAME,
  CLEAR_LANGUAGE_INFO,
  LANGUAGE_LIST,
} from "../actionTypes/languages";
import {
  ON_SHOW_MESSAGE,
  INIT_URL,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER,
  HIDE_DIALOG,
} from "../actionTypes/common";
import { userSignOut } from "./auth";
// import AllDataService from '../services/all.service';

import axiosHttp from "../services/axiosCommon";

export const getLanguageFilter = () => async (dispatch) => {
  try {
    const promises = [
      axiosHttp.get("/v1/filtration"),
      axiosHttp.get("/v1/affiliationFilter"),
      axiosHttp.get("/v1/languageTypeFilter"),
      axiosHttp.get("/v1/categoryFilter"),
    ];

    const response = await Promise.all(promises);
    const results = response.map((res) => res.data.status && res.data.results);
    console.log({ results });
    if (results.length === 0) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          message: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    } else {
      dispatch({
        type: ON_HIDE_LOADER,
      });
      dispatch({
        type: LANGUAGE_FILTER_SUCCESS,
        payload: {
          ...results[0]["data"],
          ...results[1]["data"],
          ...results[2]["data"],
          ...results[3]["data"],
        },
      });
    }
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          message: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
// export const getCourseFilter = () => async (dispatch) => {
//   try {
//     const result = await axiosHttp.get("/v1/filtration");
//     const { data } = result;
//     if (!data.status) {
//       dispatch({
//         type: ON_SHOW_MESSAGE,
//         payload: data,
//       });
//     } else {
//       dispatch({
//         type: COURSE_FILTER_SUCCESS,
//         payload: data.results,
//       });
//     }

//     return Promise.resolve(data);
//   } catch (err) {
//     const { response } = err;
//     if (response && response.status === 401) {
//     } else {
//       dispatch({
//         type: ON_SHOW_MESSAGE,
//         payload: {
//           message: "Some error has occurred",
//           status: 500,
//           error: true,
//         },
//       });
//     }
//     return Promise.reject(err);
//   }
// };

export const getLanguages = (paramsObj) => async (dispatch) => {
  try {
    const result = await axiosHttp.get("/languages/list", {
      params: paramsObj,
    });
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: LANGUAGE_LIST,
        payload: data.data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const getLanguagesList = (queryInfo) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.get("/v1/language", {
      params: queryInfo,
    });
    const { data } = result;
    console.log({ responceData: data });
    if (!data.status && data.code !== 200) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: "/languages/list",
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: LANGUAGE_LIST_SUCCESS,
        payload: { ...data.results, queryInfo },
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const getLanguageInfo = (languageId) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.get("/v1/language/" + languageId);
    const { data } = result;
    if (!data.status) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: ON_HIDE_LOADER,
      });
      // dispatch({
      //   type: COURSE_INFO_SUCCESS,
      //   payload: data,
      // });

      dispatch({
        type: LANGUAGE_INFO_SUCCESS,
        payload: data.results,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      // dispatch(
      //   userSignOut({
      //     changeMessage: true,
      //     payload: {
      //       message: response.data.message,
      //       status: response.status,
      //       error: true,
      //     },
      //   })
      // );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          message: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const setLanguagesInfo = (info) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  dispatch({
    type: LANGUAGE_INFO_SUCCESS,
    payload: info,
  });
};
export const createLanguages = (infoData) => async (dispatch) => {
  console.log({ infoData });
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.post("/v1/language", infoData);
    const { data } = result;
    if (!data.status) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      return Promise.reject(data);
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      return Promise.resolve(data);
    }
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const languageImport = (infoData) => async (dispatch) => {
  console.log({ infoData });
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    console.log({ axiosHttp });
    const result = await axiosHttp.post("/v1/importData", infoData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      return Promise.reject(data);
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: LANGUAGE_CREATE_SUCCESS,
        payload: { ...data.data },
      });
      dispatch({
        type: HIDE_DIALOG,
      });
      return Promise.resolve(data);
    }
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const updateLanguages = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  const { languageId } = infoData;
  try {
    const result = await axiosHttp.put("/v1/language/" + languageId, infoData);
    const { data } = result;
    if (!data.status) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      return Promise.reject(data);
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({ type: INIT_URL, payload: "/language/list" });
      return Promise.resolve(infoData);
    }
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const deleteLanguages = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const { languageId, queryInfo, status } = infoData;
    const result = await axiosHttp.put("/v1/languages/" + languageId, { status });
    // const result = await axiosHttp.delete("/courses", infoData);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      // dispatch({
      //   type: INIT_URL,
      //   payload: "/coursess/list",
      // });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch(getLanguagesList(queryInfo));
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};

export const onChangeSearchName = (info) => async (dispatch) => {
  dispatch({
    type: LANGUAGE_SEARCH_NAME,
    payload: info,
  });
};

export const onChangeFilterHandling = (info) => async (dispatch) => {
  dispatch({
    type: LANGUAGE_FILTER_HANDLING,
    payload: info,
  });
};
export const clearlanguagesInfo = () => async (dispatch) => {
  dispatch({
    type: CLEAR_LANGUAGE_INFO,
  });
};
