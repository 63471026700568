import {
  COURSETYPE_FILTER_SUCCESS,
  COURSETYPE_CREATE_SUCCESS,
  COURSETYPE_LIST_SUCCESS,
  COURSETYPE_INFO_SUCCESS,
  COURSETYPE_UPDATE_SUCCESS,
  COURSETYPE_DELETE_SUCCESS,
  COURSETYPE_FILTER_HANDLING,
  COURSETYPE_SEARCH_NAME,
  CLEAR_COURSETYPE_INFO,
  COURSETYPE_LIST,
} from "../actionTypes/coursetypes";
import {
  ON_SHOW_MESSAGE,
  INIT_URL,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER,
  HIDE_DIALOG,
} from "../actionTypes/common";
import { userSignOut } from "./auth";
// import AllDataService from '../services/all.service';

import axiosHttp from "../services/axiosCommon";

// export const getCoursetypeFilter = () => async (dispatch) => {
//   try {
//     const promises = [
//       axiosHttp.get("/v1/filtration"),
//       axiosHttp.get("/v1/affiliationFilter"),
//       axiosHttp.get("/v1/coursetypeTypeFilter"),
//       axiosHttp.get("/v1/coursetypeFilter"),
//     ];

//     const response = await Promise.all(promises);
//     const results = response.map((res) => res.data.status && res.data.results);
//     console.log({ results });
//     if (results.length === 0) {
//       dispatch({
//         type: ON_SHOW_MESSAGE,
//         payload: {
//           message: "Some error has occurred",
//           status: 500,
//           error: true,
//         },
//       });
//     } else {
//       dispatch({
//         type: ON_HIDE_LOADER,
//       });
//       dispatch({
//         type: COURSETYPE_FILTER_SUCCESS,
//         payload: {
//           ...results[0]["data"],
//           ...results[1]["data"],
//           ...results[2]["data"],
//           ...results[3]["data"],
//         },
//       });
//     }
//   } catch (err) {
//     const { response } = err;
//     if (response && response.status === 401) {
//     } else {
//       dispatch({
//         type: ON_SHOW_MESSAGE,
//         payload: {
//           message: "Some error has occurred",
//           status: 500,
//           error: true,
//         },
//       });
//     }
//     return Promise.reject(err);
//   }
// };
// export const getCourseFilter = () => async (dispatch) => {
//   try {
//     const result = await axiosHttp.get("/v1/filtration");
//     const { data } = result;
//     if (!data.status) {
//       dispatch({
//         type: ON_SHOW_MESSAGE,
//         payload: data,
//       });
//     } else {
//       dispatch({
//         type: COURSE_FILTER_SUCCESS,
//         payload: data.results,
//       });
//     }

//     return Promise.resolve(data);
//   } catch (err) {
//     const { response } = err;
//     if (response && response.status === 401) {
//     } else {
//       dispatch({
//         type: ON_SHOW_MESSAGE,
//         payload: {
//           message: "Some error has occurred",
//           status: 500,
//           error: true,
//         },
//       });
//     }
//     return Promise.reject(err);
//   }
// };

export const getCoursetypes = (paramsObj) => async (dispatch) => {
  try {
    const result = await axiosHttp.get("/coursetypes/list", {
      params: paramsObj,
    });
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: COURSETYPE_LIST,
        payload: data.data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const getCoursetypesList = (queryInfo) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.get("/v1/courseType", {
      params: queryInfo,
    });
    const { data } = result;
    console.log({ responceData: data });
    if (!data.status && data.code !== 200) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: "/coursetypes/list",
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: COURSETYPE_LIST_SUCCESS,
        payload: { data: data.results, queryInfo },
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const getCoursetypeInfo = (coursetypeId) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.get("/v1/courseType/" + coursetypeId);
    const { data } = result;
    if (!data.status) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: ON_HIDE_LOADER,
      });
      // dispatch({
      //   type: COURSE_INFO_SUCCESS,
      //   payload: data,
      // });

      dispatch({
        type: COURSETYPE_INFO_SUCCESS,
        payload: data.results,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      // dispatch(
      //   userSignOut({
      //     changeMessage: true,
      //     payload: {
      //       message: response.data.message,
      //       status: response.status,
      //       error: true,
      //     },
      //   })
      // );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          message: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const setCoursetypesInfo = (info) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  dispatch({
    type: COURSETYPE_INFO_SUCCESS,
    payload: info,
  });
};
export const createCoursetypes = (infoData) => async (dispatch) => {
  console.log({ infoData });
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.post("/v1/courseType", infoData);
    const { data } = result;
    if (!data.status) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      return Promise.reject(data);
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      return Promise.resolve(data);
    }
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};

export const updateCoursetypes = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.put("/v1/courseType", infoData);
    const { data } = result;
    if (!data.status) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      return Promise.reject(data);
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      return Promise.resolve(infoData);
    }
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const deleteCoursetypes = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const { courseTypeId } = infoData;
    console.log({ courseTypeId, infoData });
    // const result = await axiosHttp.delete("/v1/courseType", {
    //   courseTypeId: courseTypeId,
    // });
    const result = await axiosHttp.delete("/v1/courseType", { data: infoData });
    const { data } = result;
    if (!data.status) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      return Promise.reject(data);
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      return Promise.resolve(data);
    }
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};

export const onChangeSearchName = (info) => async (dispatch) => {
  dispatch({
    type: COURSETYPE_SEARCH_NAME,
    payload: info,
  });
};

export const onChangeFilterHandling = (info) => async (dispatch) => {
  dispatch({
    type: COURSETYPE_FILTER_HANDLING,
    payload: info,
  });
};
export const clearcoursetypesInfo = () => async (dispatch) => {
  dispatch({
    type: CLEAR_COURSETYPE_INFO,
  });
};
