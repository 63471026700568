import React, { useEffect, useState } from "react";
import { CourseInfo, Filter, FloatRight, FormView, PagesHeader } from "../../components/Courses";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCourseFilter, getCourseInfo, getCoursesList } from "../../appRedux/actions/courses";
import Page from "../../components/Common/Page";
import { getFreeCurrencyApi } from "../../appRedux/actions/common";
import { useNavigate, useParams } from "react-router-dom";
const IframeComponent = ({ src, width, height }) => {
  return (
    <iframe
      src={src}
      width={width}
      height={height}
      frameBorder="0"
      allowFullScreen
      sandbox="allow-scripts allow-same-origin"
      title="iframe"
    ></iframe>
  );
};

const CourseDetails = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const courseList = useSelector((state) => state.courses.courseList);

  useEffect(() => {
    dispatch(getCourseInfo(courseId));
  }, [courseId]);
  useEffect(() => {
    dispatch(getCourseFilter({}));

    dispatch(getFreeCurrencyApi());
  }, [dispatch]);
  return (
    <Page title="Course">
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <PagesHeader
            pageTitle={"Course Details"}
            homePageUrl="/"
            homePageText="Dashboard"
            innerPageUrl={"/course/list"}
            innerPageText={"Course List"}
            activePageText={"Course Details"}
            floatRight={
              <button
                className="btn add-btn  m-r-5"
                onClick={() => {
                  navigate(`/course/update/${courseId}`);
                  // setOpen(true);
                  // dispatch(showDialog({ title: "Add Course", size: "lg" }));
                }}
              >
                <i className="fa fa-pencil" /> Update Course
              </button>
            }
          />
          <div className="card">
            <div className="card-body">
              <CourseInfo />
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </Page>
  );
};

export default CourseDetails;
