import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { AddInfo, DownloadCSV } from ".";
import FullScreenComponent from "./FullScreenComponent";
function FloatRight(props) {
  const { viewStatus, onChange } = props;
  const navigate = useNavigate();

  return (
    <div className="d-flex title-head">
      {/* <div className="view-icons">
        <button className="grid-view btn btn-link">
          <i className="las la-redo-alt" />
        </button>
        <FullScreenComponent />
        <button className="list-view btn btn-link active-filter" id="filter_search">
          <i className="las la-filter" />
        </button>
      </div> */}
      <div className="form-sort">
        <AddInfo />
        <DownloadCSV />
        {/* <button
          className="  btn add-btn  mb-2"
          data-bs-toggle="modal"
          data-bs-target="#add_contact"
          //   href="/course/list"
        >
          <i className="fa fa-plus" /> Add Course
        </button>
        <button
          className="  btn add-btn   mx-2 mb-2"
          data-bs-toggle="modal"
          data-bs-target="#export"
          //   href="/course/list"
        >
          <i className="las la-file-export" />
          Export
        </button> */}
      </div>
    </div>
  );
}

export default FloatRight;
