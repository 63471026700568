export const COURSE_CREATE = "COURSE_CREATE";
export const COURSE_CREATE_SUCCESS = "COURSE_CREATE_SUCCESS";
export const COURSE_CREATE_ERROR = "COURSE_CREATE_ERROR";
export const COURSE_LIST = "COURSE_LIST";
export const COURSE_LIST_SUCCESS = "COURSE_LIST_SUCCESS";
export const COURSE_LIST_ERROR = "COURSE_LIST_ERROR";
export const COURSE_INFO = "COURSE_INFO";
export const COURSE_INFO_SUCCESS = "COURSE_INFO_SUCCESS";
export const COURSE_INFO_ERROR = "COURSE_INFO_ERROR";
export const COURSE_UPDATE_SUCCESS = "COURSE_UPDATE_SUCCESS";
export const COURSE_UPDATE_ERROR = "COURSE_UPDATE_ERROR";
export const COURSE_DELETE_SUCCESS = "COURSE_DELETE_SUCCESS";
export const COURSE_FILTER_HANDLING = "COURSE_FILTER_HANDLING";
export const COURSE_SEARCH_NAME = "COURSE_SEARCH_NAME";
export const COURSE_CATEGORY_SUCCESS = "COURSE_CATEGORY_SUCCESS";
export const COURSE_CATEGORY_CREATE_SUCCESS = "COURSE_CATEGORY_CREATE_SUCCESS";
export const COURSE_CATEGORY_UPDATE_SUCCESS = "COURSE_CATEGORY_UPDATE_SUCCESS";
export const CLEAR_COURSE_INFO = "CLEAR_COURSE_INFO";
export const COURSE_CATEGORY_INFO = "COURSE_CATEGORY_INFO";

export const COURSE_FILTER_SUCCESS = "COURSE_FILTER_SUCCESS";
