import React, { useEffect, useState } from "react";

import { connect, useDispatch, useSelector } from "react-redux";
import { Table, Tag } from "antd";
import ReactStars from "react-stars";
// import "antd/dist/antd.css";
// import { itemRender, onShowSizeChange } from "../../paginationfunction";

import { Link } from "react-router-dom";
import { EditInfo, DeleteModal } from ".";
import { apiURL } from "../../utils/config";
// import { getCourseOwners } from "../../appRedux/actions/courseOwners";
import { Avatar_02 } from "../../Routes/ImagePath";
import moment from "moment";
import { currencyConverter } from "../../utils/currency";
import { getCoursetypesList } from "../../appRedux/actions/coursetypes";

function ListView({ searchText }) {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const { levelList } = useSelector((state) => ({
    levelList: state.level.levelList,
    queryInfo: state.level.queryInfo,
    totalRecords: state.level.totalRecords,
    selectedCurrency: state.common.selectedCurrency,
    currencyData: state.common.currencyData,
  }));

  useEffect(() => {
    console.log({ searchText });
    if (searchText) {
      const filteredList = levelList.filter((level) =>
        level.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setList(filteredList);
    } else {
      setList(levelList);
    }
  }, [searchText, levelList]);

  useEffect(() => {
    setList(levelList);
  }, [levelList]);

  const columns = [
    {
      title: "Level Name",
      dataIndex: "name",
      render: (name, record) => (
        <h2
          className="table-avatar"
          style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
        >
          {name}
        </h2>
      ),
      // width: "30px",
      // sorter: (a, b) => a.name.length - b.name.length,
    },

    {
      title: "Level Code",
      dataIndex: "code",
      align: "center",
      render: (code, record) => (
        <h2
          className="table-avatar"
          style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
        >
          {code}
        </h2>
      ),
    },

    {
      title: "Action",
      align: "center",
      // fixed: "right",
      render: (text, record) => (
        <div
          className="dropdown dropdown-action text-center"
          style={{ padding: "8px" }}
        >
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <EditInfo info={record} key={"edit_info"} />

            <DeleteModal info={record} />
          </div>
        </div>
      ),
    },
  ];

  const itemRender = (_, type, originalElement) => {
    console.log(type, "a", originalElement);
    // if (type === "prev") {
    //   return (
    //     <i
    //       class="fa fa-angle-double-left"
    //       data-bs-toggle="tooltip"
    //       title="fa fa-angle-double-left"
    //     ></i>
    //   );
    // }
    // if (type === "next") {
    //   return (
    //     <i
    //       class="fa fa-angle-double-right"
    //       data-bs-toggle="tooltip"
    //       title="fa fa-angle-double-right"
    //     ></i>
    //   );
    // }
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };
  return (
    <div className="row m-0 mt-3">
      <div className="col-md-12 p-0" id="table-responsive">
        <div className="table-responsive" id="setMinheight">
          <Table
            className="table-striped"
            pagination={true}
            // pagination={{
            //   total: totalRecords,
            //   pageSizeOptions: ["5", "10", "20", "50"],
            //   showSizeChanger: true,
            //   showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
            //   onShowSizeChange: (current, pageSize) => {
            //     console.log({ current, pageSize });
            //     dispatch(
            //       getCoursetypesList({
            //         ...queryInfo,
            //         page: current,
            //         size: pageSize,
            //       })
            //     );
            //   }, //onShowSizeChange,
            //   itemRender: itemRender,
            // }}
            // onChange={(pagination, filters, sorter) =>
            //   handleTableChange(pagination, filters, sorter)
            // }
            style={{ overflowX: "auto" }}
            columns={columns}
            // bordered
            dataSource={list}
            rowKey={(record) => record.id}
            // onChange={this.handleTableChange}
          />
        </div>
      </div>
    </div>
  );
}

export default ListView;
