import { applyMiddleware, legacy_createStore as createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
// npm i react-router-dom redux react-redux redux-thunk axios react-dom react-helmet redux-devtools-extension
const initialState = {};

const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

// import { configureStore } from "@reduxjs/toolkit";
// import { userSlice } from "./user"; // Make sure this import is correct

// const reducer = {
//   user: userSlice.reducer, // Access the 'reducer' property of the userSlice
// };

// const store = configureStore({
//   reducer: { ...rootReducer, ...reducer },
//   devTools: true,
// });

// export default store;
