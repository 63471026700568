import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormView, DialogModal } from ".";
import { showDialog } from "../../appRedux/actions/common";
import { setCategoriesInfo } from "../../appRedux/actions/categories";
import { useNavigate } from "react-router-dom";

function EditInfo({ info }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { dialog } = useSelector((state) => ({
    dialog: state.common.dialog,
  }));
  console.log({ info, dialog, open });

  useEffect(() => {
    console.log({ info, dialog, open }, "-----99");
    if (!dialog) {
      console.log({ info, dialog, open }, "---");
      setOpen(dialog);
    }
  }, [dialog]);
  const onClick = (info1) => {
    setOpen(true);
    dispatch(setCategoriesInfo(info1));
    dispatch(showDialog({ title: "Update Category", size: "lg", id: info1.id }));
  };

  return (
    <Fragment>
      <button
        className="dropdown-item"
        onClick={() =>onClick(info)}
        key={"edit_info"}
      >
        <i className="fa fa-pencil m-r-5" /> Edit
        {/* <i className="fa fa-pencil m-r-5" /> Edit */}
      </button>
      {open && (
        <DialogModal
          infoId={info.id}
          key={Math.random()}
          bodyView={<FormView isUpdate={true} reJoin={!info.active} />}
        ></DialogModal>
      )}
    </Fragment>
  );
}

export default EditInfo;
