import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useRoutes } from "react-router-dom";

// import Layout from "./layouts";

// pages
import {
  ForgotPasswordPage,
  LoginPage,
  RegistrationPage,
  SetNewPasswordPage,
  OtpPage,
  //   GoogleCallback,
  VerifyEmailPage,
  VerifyOtpPage,
} from "../authPage";

import PageNotFound from "../pages/PageNotFound.jsx";
// import FAQ from "./pages/faq";
// import PrivacyPolicy from "./pages/privacyPolicy";
// import TermsConditions from "./pages/termsConditions";
// import ContactUs from "./pages/contactUs";
// import AboutUs from "./pages/aboutUs";
// import AuthLayout from "./layouts/authLayout";
import AdminDashboard from "../pages/AdminDashboard/index.jsx";
import Profile from "../pages/Profile/Profile";
// import OffCanvas from "../../components/OffCanvas";
import { AuthLayout, DashboardLayout } from "../layouts/index.js";
import CourseList from "../pages/Course/List.jsx";
import Texteditor from "../pages/Texteditor.jsx";
import CourseCreate from "../pages/Course/Create.jsx";
import CourseUpdate from "../pages/Course/Update.jsx";
import CourseImport from "../pages/Course/Import.jsx";
import CourseDetails from "../pages/Course/Details.jsx";
import PLatformList from "../pages/Platform/List.jsx";
import AffiliationList from "../pages/Affiliation/List.jsx";
import CategoryList from "../pages/Category/List.jsx";
import LanguageList from "../pages/Language/List.jsx";
import LanguageCreate from "../pages/Language/Create.jsx";
import LanguageUpdate from "../pages/Language/Update.jsx";
import LevelList from "../pages/Level/List.jsx";
import ModeList from "../pages/Mode/List.jsx";
import PrivacyPolicyList from "../pages/PrivacyPolicy/List.jsx";
import FAQList from "../pages/Faq/List.jsx";
import CoursetypeList from "../pages/CourseType/List.jsx";
import StaffList from "../pages/Staff/List.jsx";
import StaffCreate from "../pages/Staff/Create.jsx";
import StaffUpdate from "../pages/Staff/Update.jsx";
import UserList from "../pages/User/List.jsx";

// import FarmOwnerList from "../pages/Course/Owner/List.jsx";

// ----------------------------------------------------------------------
const ROUTE_MENU = [];

export default function Router() {
  const COMMON_ROUTE = [
    {
      path: "/auth",
      element: <AuthLayout />,
      children: [
        { path: "login", element: <LoginPage /> },
        { path: "signup", element: <RegistrationPage /> },
        // { path: "google-auth", element: <GoogleCallback /> },
        { path: "verify-email", element: <VerifyEmailPage /> },
        { path: "verify-otp/:userId", element: <VerifyOtpPage /> },
        { path: "forgot-password", element: <ForgotPasswordPage /> },
        { path: "set-new-password/:token", element: <SetNewPasswordPage /> },
      ],
    },
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { path: "", element: <AdminDashboard /> },
        { path: "/", element: <AdminDashboard /> },
        { path: "/", element: <AdminDashboard /> },
        { path: "/editor", element: <Texteditor /> },

        {
          path: "/profile",
          element: <Profile />,
        },
        {
          path: "/course",
          children: [
            { path: "list", element: <CourseList /> },
            { path: "create", element: <CourseCreate /> },
            { path: "import", element: <CourseImport /> },
            { path: "update/:courseId", element: <CourseUpdate /> },
            { path: ":courseId", element: <CourseDetails /> },
          ],
        },

        {
          path: "/platform",
          children: [
            { path: "list", element: <PLatformList /> },
            // { path: ":courseId", element: <CourseDetails /> },
          ],
        },

        {
          path: "/affiliation",
          children: [
            { path: "list", element: <AffiliationList /> },
            // { path: ":courseId", element: <CourseDetails /> },
          ],
        },

        {
          path: "/category",
          children: [
            { path: "list", element: <CategoryList /> },
            // { path: "create", element: <CategoryCreate /> },
            // { path: "update/:categoryId", element: < CategoryUpdate/> },
            // { path: "import", element: <CategoryImport /> },
            // { path: ":courseId", element: <CourseDetails /> },
          ],
        },

        {
          path: "/coursetype",
          children: [
            { path: "list", element: <CoursetypeList /> },
            // { path: ":courseId", element: <CourseDetails /> },
          ],
        },
        {
          path: "/mode",
          children: [
            { path: "list", element: <ModeList /> },
            // { path: ":courseId", element: <CourseDetails /> },
          ],
        },
        {
          path: "/level",
          children: [
            { path: "list", element: <LevelList /> },
            // { path: ":courseId", element: <CourseDetails /> },
          ],
        },

        {
          path: "/language",
          children: [
            { path: "list", element: <LanguageList /> },
            { path: "create", element: <LanguageCreate /> },
            { path: "update/:languageId", element: <LanguageUpdate /> },
            // { path: ":courseId", element: <CourseDetails /> },
          ],
        },

        {
          path: "/staff",
          children: [
            { path: "list", element: <StaffList /> },
            { path: "create", element: <StaffCreate /> },
            { path: "update/:courseId", element: <StaffUpdate /> },
          ],
        },
        {
          path: "/users",
          children: [{ path: "list", element: <UserList /> }],
        },

        // { path: "/about-us", element: <AboutUs /> },
        // { path: "/contact-us", element: <ContactUs /> },

        {
          path: "/privacy-policy",
          children: [
            { path: "list", element: <PrivacyPolicyList /> },
            // { path: ":courseId", element: <CourseDetails /> },
          ],
        },

        {
          path: "/faq",
          children: [
            { path: "list", element: <FAQList /> },
            // { path: ":courseId", element: <CourseDetails /> },
          ],
        },

        // { path: "/terms-conditions", element: <TermsConditions /> },

        { path: "*", element: <PageNotFound /> },
      ],
    },
    { path: "*", element: <PageNotFound replace /> },
  ];

  return useRoutes(COMMON_ROUTE);
  // return useRoutes(FILTER_ROUTE.concat(COMMON_ROUTE));
}
