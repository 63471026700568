export const LANGUAGE_CREATE = "LANGUAGE_CREATE";
export const LANGUAGE_CREATE_SUCCESS = "LANGUAGE_CREATE_SUCCESS";
export const LANGUAGE_CREATE_ERROR = "LANGUAGE_CREATE_ERROR";
export const LANGUAGE_LIST = "LANGUAGE_LIST";
export const LANGUAGE_LIST_SUCCESS = "LANGUAGE_LIST_SUCCESS";
export const LANGUAGE_LIST_ERROR = "LANGUAGE_LIST_ERROR";
export const LANGUAGE_INFO = "LANGUAGE_INFO";
export const LANGUAGE_INFO_SUCCESS = "LANGUAGE_INFO_SUCCESS";
export const LANGUAGE_INFO_ERROR = "LANGUAGE_INFO_ERROR";
export const LANGUAGE_UPDATE_SUCCESS = "LANGUAGE_UPDATE_SUCCESS";
export const LANGUAGE_UPDATE_ERROR = "LANGUAGE_UPDATE_ERROR";
export const LANGUAGE_DELETE_SUCCESS = "LANGUAGE_DELETE_SUCCESS";
export const LANGUAGE_FILTER_HANDLING = "LANGUAGE_FILTER_HANDLING";
export const LANGUAGE_SEARCH_NAME = "LANGUAGE_SEARCH_NAME";
export const LANGUAGEE_CATEGORY_SUCCESS = "LANGUAGE_CATEGORY_SUCCESS";
export const LANGUAGE_CATEGORY_CREATE_SUCCESS = "LANGUAGE_CATEGORY_CREATE_SUCCESS";
export const LANGUAGE_CATEGORY_UPDATE_SUCCESS = "LANGUAGE_CATEGORY_UPDATE_SUCCESS";
export const CLEAR_LANGUAGE_INFO = "CLEAR_LANGUAGE_INFO";
export const LANGUAGE_CATEGORY_INFO = "LANGUAGE_CATEGORY_INFO";

export const LANGUAGE_FILTER_SUCCESS = "LANGUAGE_FILTER_SUCCESS";
