import {
  STAFF_FILTER_SUCCESS,
  STAFF_CREATE_SUCCESS,
  STAFF_LIST_SUCCESS,
  STAFF_INFO_SUCCESS,
  STAFF_UPDATE_SUCCESS,
  STAFF_DELETE_SUCCESS,
  STAFF_FILTER_HANDLING,
  STAFF_SEARCH_NAME,
  CLEAR_STAFF_INFO,
  STAFF_LIST,
  STAFF_ROLE_LIST_SUCCESS,
  STAFF_PERMISSION_FILTER_SUCCESS,
} from "../actionTypes/staffs";
import {
  ON_SHOW_MESSAGE,
  INIT_URL,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER,
  HIDE_DIALOG,
} from "../actionTypes/common";
import { userSignOut } from "./auth";
// import AllDataService from '../services/all.service';

import axiosHttp from "../services/axiosCommon";

export const getStaffRoleList = () => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.get("/v2/admin/get-role");
    const { data } = result;
    console.log({ responceData: data });
    if (!data.status && data.code !== 200) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      return Promise.reject(data);
    } else {
      dispatch({
        type: STAFF_ROLE_LIST_SUCCESS,
        payload: data.results,
      });
      return Promise.resolve(data.results);
    }
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const getStaffPermissionList = (queryInfo) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.get("/v2/admin/get-permission", {
      params: queryInfo,
    });
    const { data } = result;
    console.log({ responceData: data });
    if (!data.status || data.code !== 200) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: STAFF_PERMISSION_FILTER_SUCCESS,
        payload: data.results ? data.results : [],
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const getstaffs = (paramsObj) => async (dispatch) => {
  try {
    const result = await axiosHttp.get("/staffs/list", {
      params: paramsObj,
    });
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: STAFF_LIST,
        payload: data.data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};

export const getStaffsList = (queryInfo) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.get("/v2/admin/get-AllUser", {
      params: queryInfo,
    });
    const { data } = result;
    console.log({ responceData: data });
    if (!data.status && data.code !== 200) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: "/staffs/list",
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: STAFF_LIST_SUCCESS,
        payload: { data: data.results, queryInfo },
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const getStaffInfo = (staffId) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.get("/v1/get-StaffData/" + staffId);
    const { data } = result;
    if (!data.status) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: ON_HIDE_LOADER,
      });
      // dispatch({
      //   type: COURSE_INFO_SUCCESS,
      //   payload: data,
      // });

      dispatch({
        type: STAFF_INFO_SUCCESS,
        payload: data.results,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      // dispatch(
      //   userSignOut({
      //     changeMessage: true,
      //     payload: {
      //       message: response.data.message,
      //       status: response.status,
      //       error: true,
      //     },
      //   })
      // );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          message: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const setStaffsInfo = (info) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  dispatch({
    type: STAFF_INFO_SUCCESS,
    payload: info,
  });
};
export const createStaffs = (infoData) => async (dispatch) => {
  console.log({ infoData });
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.post("/v2/admin/register", infoData);
    const { data } = result;
    if (!data.status || data.code !== 200) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      return Promise.reject(data);
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      return Promise.resolve(data);
    }
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const updateStaffs = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.put("/v2/admin/update-UserProfile", infoData);
    const { data } = result;
    if (!data.status) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      return Promise.reject(data);
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      // dispatch({ type: INIT_URL, payload: "/staff/list" });
      return Promise.resolve(infoData);
    }
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const deleteStaffs = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const { staffId, queryInfo, status } = infoData;
    const result = await axiosHttp.delete("/v2/admin/delete-UserProfile", {
      data: { id: staffId },
    });
    // const result = await axiosHttp.delete("/courses", infoData);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch(getStaffsList(queryInfo));
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};

export const onChangeSearchName = (info) => async (dispatch) => {
  dispatch({
    type: STAFF_SEARCH_NAME,
    payload: info,
  });
};

export const onChangeFilterHandling = (info) => async (dispatch) => {
  dispatch({
    type: STAFF_FILTER_HANDLING,
    payload: info,
  });
};
export const clearstaffsInfo = () => async (dispatch) => {
  dispatch({
    type: CLEAR_STAFF_INFO,
  });
};
