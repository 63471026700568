import {
  AFFILIATION_FILTER_SUCCESS,
  AFFILIATION_CREATE_SUCCESS,
  AFFILIATION_LIST_SUCCESS,
  AFFILIATION_INFO_SUCCESS,
  AFFILIATION_UPDATE_SUCCESS,
  AFFILIATION_DELETE_SUCCESS,
  AFFILIATION_FILTER_HANDLING,
  AFFILIATION_SEARCH_NAME,
  CLEAR_AFFILIATION_INFO,
  AFFILIATION_LIST,
} from "../actionTypes/affiliations";
import {
  ON_SHOW_MESSAGE,
  INIT_URL,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER,
  HIDE_DIALOG,
} from "../actionTypes/common";
import { userSignOut } from "./auth";
// import AllDataService from '../services/all.service';

import axiosHttp from "../services/axiosCommon";

export const getaffiliations = (paramsObj) => async (dispatch) => {
  try {
    const result = await axiosHttp.get("/affiliations/list", {
      params: paramsObj,
    });
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: AFFILIATION_LIST,
        payload: data.data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};

export const getAffiliationsList = (queryInfo) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.get("/v1/affiliation", {
      params: queryInfo,
    });
    const { data } = result;
    console.log({ responceData: data });
    if (!data.status && data.code !== 200) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: "/affiliations/list",
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: AFFILIATION_LIST_SUCCESS,
        payload: { data: data.results, queryInfo },
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const getAffiliationInfo = (affiliationId) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.get("/v1/affiliation/" + affiliationId);
    const { data } = result;
    if (!data.status) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: ON_HIDE_LOADER,
      });
      // dispatch({
      //   type: COURSE_INFO_SUCCESS,
      //   payload: data,
      // });

      dispatch({
        type: AFFILIATION_INFO_SUCCESS,
        payload: data.results,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          message: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const setAffiliationsInfo = (info) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  dispatch({
    type: AFFILIATION_INFO_SUCCESS,
    payload: info,
  });
};
export const createAffiliations = (infoData) => async (dispatch) => {
  console.log({ infoData });
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.post("/v1/affiliation", infoData);
    const { data } = result;
    if (!data.status) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      return Promise.reject(data);
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      return Promise.resolve(data);
    }
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const updateAffiliations = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  const { affiliationId } = infoData;
  try {
    const result = await axiosHttp.put("/v1/affiliation", infoData);
    const { data } = result;
    if (!data.status) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      return Promise.reject(data);
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      // dispatch({ type: INIT_URL, payload: "/affiliation/list" });
      return Promise.resolve(infoData);
    }
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const deleteAffiliations = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const { affiliationId, queryInfo, status } = infoData;
    const result = await axiosHttp.delete("/v1/affiliation", {
      data: { affiliationId: affiliationId },
    });
    // const result = await axiosHttp.delete("/courses", infoData);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch(getAffiliationsList(queryInfo));
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};

export const onChangeSearchName = (info) => async (dispatch) => {
  dispatch({
    type: AFFILIATION_SEARCH_NAME,
    payload: info,
  });
};

export const onChangeFilterHandling = (info) => async (dispatch) => {
  dispatch({
    type: AFFILIATION_FILTER_HANDLING,
    payload: info,
  });
};
export const clearaffiliationsInfo = () => async (dispatch) => {
  dispatch({
    type: CLEAR_AFFILIATION_INFO,
  });
};
