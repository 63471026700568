export const INIT_URL = "INIT_URL";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_MESSAGE = "ON_HIDE_MESSAGE";
export const ON_SHOW_MESSAGE = "ON_SHOW_MESSAGE";
export const SUCCESS_MESSAGE = "SUCCESS_MESSAGE";
export const ERROR_MESSAGE = "ERROR_MESSAGE";
export const SHOW_DIALOG = "SHOW_DIALOG";
export const HIDE_DIALOG = "HIDE_DIALOG";
export const SHOW_DIALOG_INNER = "SHOW_DIALOG_INNER";
export const HIDE_DIALOG_INNER = "HIDE_DIALOG_INNER";
export const ON_HIDE_MESSAGE_IMAGE = "ON_HIDE_MESSAGE_IMAGE";
export const ON_SHOW_MESSAGE_IMAGE = "ON_SHOW_MESSAGE_IMAGE";
export const SEARCH_NAME = "SEARCH_NAME";
export const SWITCH_TO = "SWITCH_TO";
export const CURRENCY_DATA = "CURRENCY_DATA";
export const CHANGE_CURRENCY = "CHANGE_CURRENCY";
