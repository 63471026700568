import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormView, DialogModal } from ".";
import { showDialog } from "../../appRedux/actions/common";
import { setFaqsInfo } from "../../appRedux/actions/faqs";
import { useNavigate } from "react-router-dom";

function EditInfo({ info, type }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { dialog } = useSelector((state) => ({
    dialog: state.common.dialog,
  }));
  console.log({ info, dialog, open });

  useEffect(() => {
    console.log({ info, dialog, open }, "-----99");
    if (!dialog) {
      setOpen(dialog);
      console.log({ info, dialog, open }, "---");
    }
  }, [dialog]);
  // useEffect(() => {
  //   console.log({ info, dialog, open }, "-----99");
  //   if (!dialog) {
  //     console.log({ info, dialog, open }, "---");
  //     setOpen(dialog);
  //   }
  // }, []);
  const onClick = (info1) => {
    setOpen(true);
    dispatch(setFaqsInfo(info1));
    dispatch(
      showDialog({
        title: type === "faq" ? "Add Faq" : "Add PrivacyPolicy",
        size: "lg",
        id: info1._id,
      })
    );
  };

  return (
    <Fragment>
      <button
        className="dropdown-item"
        onClick={() => {
          onClick(info);
          // setOpen(true);
          // dispatch(
          //   showDialog({
          //     title: type === "faq" ? "Update Faq" : "Update PrivacyPolicy",
          //     size: "lg",
          //   })
          // );
        }}
        key={"edit_info"}
      >
        <i className="fa fa-pencil m-r-5" /> Edit
        {/* <i className="fa fa-pencil m-r-5" /> Edit */}
      </button>
      {open && (
        <DialogModal
          infoId={info._id}
          key={Math.random()}
          bodyView={<FormView type={type} isUpdate={true} />}
        ></DialogModal>
      )}
    </Fragment>
  );
}

export default EditInfo;
