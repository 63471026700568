export const COURSETYPE_CREATE = "COURSETYPE_CREATE";
export const COURSETYPE_CREATE_SUCCESS = "COURSETYPE_CREATE_SUCCESS";
export const COURSETYPE_CREATE_ERROR = "COURSETYPE_CREATE_ERROR";
export const COURSETYPE_LIST = "COURSETYPE_LIST";
export const COURSETYPE_LIST_SUCCESS = "COURSETYPE_LIST_SUCCESS";
export const COURSETYPE_LIST_ERROR = "COURSETYPE_LIST_ERROR";
export const COURSETYPE_INFO = "COURSETYPE_INFO";
export const COURSETYPE_INFO_SUCCESS = "COURSETYPE_INFO_SUCCESS";
export const COURSETYPE_INFO_ERROR = "COURSETYPE_INFO_ERROR";
export const COURSETYPE_UPDATE_SUCCESS = "COURSETYPE_UPDATE_SUCCESS";
export const COURSETYPE_UPDATE_ERROR = "COURSETYPE_UPDATE_ERROR";
export const COURSETYPE_DELETE_SUCCESS = "COURSETYPE_DELETE_SUCCESS";
export const COURSETYPE_FILTER_HANDLING = "COURSETYPE_FILTER_HANDLING";
export const COURSETYPE_SEARCH_NAME = "COURSETYPE_SEARCH_NAME";
export const COURSETYPEE_COURSETYPE_SUCCESS = "COURSETYPE_COURSETYPE_SUCCESS";
export const COURSETYPE_COURSETYPE_CREATE_SUCCESS = "COURSETYPE_COURSETYPE_CREATE_SUCCESS";
export const COURSETYPE_COURSETYPE_UPDATE_SUCCESS = "COURSETYPE_COURSETYPE_UPDATE_SUCCESS";
export const CLEAR_COURSETYPE_INFO = "CLEAR_COURSETYPE_INFO";
export const COURSETYPE_COURSETYPE_INFO = "COURSETYPE_COURSETYPE_INFO";

export const COURSETYPE_FILTER_SUCCESS = "COURSETYPE_FILTER_SUCCESS";
