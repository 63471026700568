import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider, Field } from "formik";

import { Input, Select as SelectAnt } from "antd";
import {
  createCategories,
  getCategoriesList,
  updateCategories,
} from "../../appRedux/actions/categories";
import { hideDialog } from "../../appRedux/actions/common";

const { Option } = SelectAnt;
const INITIAL_STATE = {
  name: "",
  code: "",
};
function FormView(props) {
  const { categoryInfo } = useSelector((state) => ({
    categoryInfo: state.categories.categoryInfo,
  }));

  const { isUpdate, reJoin } = props;
  const [formInfo, setFormInfo] = useState(INITIAL_STATE);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // dispatch(hideMessage());
    if (isUpdate && categoryInfo && categoryInfo._id) {
      setFormInfo({
        ...INITIAL_STATE,
        ...categoryInfo,
      });
    } else {
      //console.log("");
      setFormInfo({
        ...INITIAL_STATE,
      });
    }
  }, [isUpdate, categoryInfo]);

  const FormSchema = Yup.object().shape({
    name: Yup.string().required("Category name is required."),
    code: Yup.string().required("Category code is required."),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInfo,
    validationSchema: FormSchema,
    onSubmit: (data) => {
      console.log({ data });
      if (isUpdate && categoryInfo && categoryInfo._id) {
        dispatch(
          updateCategories({
            ...data,
            categoryId: categoryInfo._id,

            _id: undefined,
          })
        ).then(() => {
          // navigate("/category/list");
          dispatch(getCategoriesList());
          dispatch(hideDialog());
        });
      } else {
        dispatch(
          createCategories({
            ...data,
          })
        ).then(() => {
          dispatch(getCategoriesList());
          dispatch(hideDialog());
        });
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;
  console.log({ errors, touched, values });

  return (
    <FormikProvider value={formik}>
      <Form
        id="contactForm"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        className="signin-form"
      >
        {/* <div>
          <label className="col-form-label">
            <b>Course Details:</b>
          </label>
        </div> */}

        <div className="row m-0">
          <div className="col-md-12">
            <div className="input-block mb-3">
              <label className="col-form-label">
                Name <span className="text-danger">*</span>
              </label>
              <input className="form-control" type="text" {...getFieldProps("name")} />
              <p className="error-msg text-danger">{touched.name && errors.name}</p>
            </div>
          </div>

          <div className="col-sm-12">
            <div className="form-group">
              <label className="col-form-label">
                Code<span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                {...getFieldProps("code")}
                readOnly={isUpdate}
                disabled={isUpdate}
              />
              <p className="error-msg text-danger">{touched.code && errors.code}</p>
            </div>
          </div>
        </div>
        <div className="submit-section mb-5">
          <button type="submit" className="btn btn-primary submit-btn">
            {isUpdate && categoryInfo && categoryInfo._id ? "Update" : "Submit"}
          </button>
        </div>
      </Form>
    </FormikProvider>
  );
}

export default FormView;
