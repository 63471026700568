import {
    LANGUAGE_LIST,
    LANGUAGE_CREATE_SUCCESS,
    LANGUAGE_LIST_SUCCESS,
    LANGUAGE_INFO_SUCCESS,
    LANGUAGE_FILTER_HANDLING,
    LANGUAGE_SEARCH_NAME,
    LANGUAGE_UPDATE_SUCCESS,
    // LANGUAGE_CATEGORY_SUCCESS,
    // LANGUAGE_CATEGORY_CREATE_SUCCESS,
    // LANGUAGE_CATEGORY_UPDATE_SUCCESS,
    CLEAR_LANGUAGE_INFO,
    // LANGUAGE_CATEGORY_INFO,
    PLATFORM_FILTER_SUCCESS,
  } from "../actionTypes/languages";
  
  // import {
  //   COLUMN_HANDLING,
  //   _CATEGORY_DATA,
  //   _PLATFORM_FILTER_DATA,
  //   _LANGUAGE_DATA,
  //   _MERCHANT_DATA,
  // } from "../../_mocks_/platform";
  // import { COLUMN_HANDLING } from "../../_mocks_/course";
  
  const INIT_STATE = {
    // platformFilters: _PLATFORM_FILTER_DATA,
    languageList: [],
    isSuccess: false,
    languages: [],
    languageInfo: {},
    categories: [],
    categoryInfo: {},
    queryInfo: {
      page: 1,
      size: 10,
      next: false,
    },
    totalRecords: 0,
    page: 1,
    pages: 0,
    // columnHandling: COLUMN_HANDLING,
    searchName: "",
    searchStatus: false,
    languageType: "Active",
    isUpdate: false,
    isCategory: true,
    isInfo: false,
    currentStep: 0,
    isEdit: false,
  };
  
  function LanguageReducer(state = INIT_STATE, action) {
    const { type, payload } = action;
  
    switch (type) {
      // case PLATFORM_FILTER_SUCCESS: {
      //   console.log({ payload });
      //   return {
      //     ...state,
      //     // // platformFilters: { ..._PLATFORM_FILTER_DATA, ...payload },
      //   };
      // }
      // case PLATFORM_CATEGORY_INFO: {
      //   return {
      //     ...state,
      //     categoryInfo: payload,
      //   };
      // }
      case CLEAR_LANGUAGE_INFO: {
        return {
          ...state,
          languageInfo: {},
        };
      }
      case LANGUAGE_LIST: {
        console.log({ payload });
        return {
          ...state,
          languages: payload,
        };
      }
      // case PLATFORM_CATEGORY_SUCCESS: {
      //   console.log({ payload });
      //   return {
      //     ...state,
      //     categories: payload,
      //     isCategory: false,
      //   };
      // }
      // case PLATFORM_CATEGORY_CREATE_SUCCESS: {
      //   console.log({ payload });
      //   return {
      //     ...state,
      //     categories: { payload, ...state.categories },
      //     isCategory: false,
      //   };
      // }
      // case PLATFORM_CATEGORY_UPDATE_SUCCESS: {
      //   var list = state.categories.map((row) => (row._id === payload._id ? payload : row));
      //   // console.log({ payload, info });
      //   return {
      //     ...state,
      //     categories: list.filter((row) => row.active),
      //     isCategory: false,
      //   };
      // }
      case LANGUAGE_LIST_SUCCESS: {
        return {
          ...state,
          searchStatus: false,
          languageList: payload.data,
          queryInfo: {
            ...payload.queryInfo,
          },
          totalRecords: payload.count,
          pages: payload.pages,
          isSuccess: false,
          isCategory: true,
          // page: payload.queryInfo.page || 0
        };
      }
  
      case LANGUAGE_CREATE_SUCCESS: {
        return {
          ...state,
          languageList: [payload, ...state.languageList],
          languageInfo: payload,
          currentStep: payload.currentStep + 1,
          isSuccess: true,
          isEdit: payload && payload._id ? true : false,
        };
      }
  
      case LANGUAGE_UPDATE_SUCCESS: {
        console.log({ payload });
        return {
          ...state,
          languageList: state.languageList.map(
            (obj) => (payload._id === obj._id ? payload : obj)
            // data.columnHandling.map((row) => obj.id === row)
          ),
          languageInfo: payload,
          currentStep: payload.currentStep + 1,
          isSuccess: true,
          isUpdate: true,
        };
      }
  
      case LANGUAGE_INFO_SUCCESS: {
        console.log({ payload });
        return {
          ...state,
          languageInfo: payload,
          isInfo: true,
          isEdit: true,
        };
      }
      case LANGUAGE_FILTER_HANDLING: {
        console.log({ payload });
        return {
          ...state,
          columnHandling: payload.columnHandling,
          languageType: payload.languageType,
        };
      }
      case LANGUAGE_SEARCH_NAME: {
        console.log({ payload });
        return {
          ...state,
          searchName: payload,
          searchStatus: true,
          queryInfo: { page: 1, size: state.queryInfo.size },
        };
      }
  
      default:
        return state;
    }
  }
  
  export default LanguageReducer;
  