import React, { useEffect, useState } from "react";
import { Filter, FloatRight, ListView, PagesHeader } from "../../components/Courses";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCourseFilter, getCoursesList } from "../../appRedux/actions/courses";
import Page from "../../components/Common/Page";
import { getFreeCurrencyApi } from "../../appRedux/actions/common";

const CourseList = () => {
  const dispatch = useDispatch();
  const [initialFetchComplete, setInitialFetchComplete] = useState(false);

  const [viewStatus, setViewStatus] = useState("listView"); //"listView", "gridView"
  const courseList = useSelector((state) => state.courses.courseList);
  const setOldQueryInfo = useSelector((state) => state.courses.setOldQueryInfo);
  const queryInfo = useSelector((state) => state.courses.queryInfo);
  console.log({ setOldQueryInfo, queryInfo });

  useEffect(() => {
    console.log({ setOldQueryInfo, queryInfo }, "inner");

    dispatch(getCourseFilter({}));
    dispatch(
      // getCoursesList(setOldQueryInfo ? queryInfo : { page: 1, size: 10, sortByUpdatedAt: "desc" })
      getCoursesList({ page: 1, size: 10, sortByUpdatedAt: "desc" })
    ).then(() => {
      setInitialFetchComplete(true);
    });
    dispatch(getFreeCurrencyApi());
  }, []);
  return (
    <Page title="Course">
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <PagesHeader
            pageTitle={"Course List"}
            homePageUrl="/"
            homePageText="Dashboard"
            // innerPageUrl={""}
            // innerPageText={""}
            activePageText={"Course's"}
            floatRight={
              <FloatRight viewStatus={viewStatus} onChange={(status) => setViewStatus(status)} />
            }
          />
          {/* /Page Header */}
          <Filter {...{ initialFetchComplete }} />
          {/* <EmployeeListFilter /> */}

          <div className="card">
            <div className="card-body">
              <ListView />
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </Page>
  );
};

export default CourseList;
