import {
  COURSE_FILTER_SUCCESS,
  COURSE_CREATE_SUCCESS,
  COURSE_LIST_SUCCESS,
  COURSE_INFO_SUCCESS,
  COURSE_UPDATE_SUCCESS,
  COURSE_DELETE_SUCCESS,
  COURSE_FILTER_HANDLING,
  COURSE_SEARCH_NAME,
  COURSE_CATEGORY_SUCCESS,
  COURSE_CATEGORY_CREATE_SUCCESS,
  COURSE_CATEGORY_UPDATE_SUCCESS,
  CLEAR_COURSE_INFO,
  COURSE_CATEGORY_INFO,
  COURSE_LIST,
} from "../actionTypes/courses";
import {
  ON_SHOW_MESSAGE,
  INIT_URL,
  ON_SHOW_LOADER,
  ON_HIDE_LOADER,
  HIDE_DIALOG,
} from "../actionTypes/common";
import { userSignOut } from "./auth";
// import AllDataService from '../services/all.service';

import axiosHttp from "../services/axiosCommon";

export const getCourseFilter = () => async (dispatch) => {
  try {
    const promises = [
      axiosHttp.get("/v1/filtration"),
      axiosHttp.get("/v1/affiliation"),
      axiosHttp.get("/v1/courseType"),
      axiosHttp.get("/v1/category"),
      axiosHttp.get("/v1/mode"),
      axiosHttp.get("/v1/level"),
    ];

    const response = await Promise.all(promises);
    const results = response.map((res) => res.data.status && res.data.results);
    console.log({ results });
    if (results.length === 0) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          message: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    } else {
      dispatch({
        type: ON_HIDE_LOADER,
      });
      dispatch({
        type: COURSE_FILTER_SUCCESS,
        payload: {
          ...results[0],
          affiliation: results[1] ? results[1] : [],
          courseType: results[2] ? results[2] : [],
          category: results[3] ? results[3] : [],
          mode: results[4] ? results[4] : [],
          level: results[5] ? results[5] : [],
        },
      });
    }
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          message: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};

export const exportCourseCSV = (paramsObj) => async (dispatch) => {
  try {
    const result = await axiosHttp.get("/v1/exportData", {
      params: paramsObj,
      headers: {
        Accept: "text/csv",
      },
      responseType: "blob",
    });
    const { data } = result;
    if (data.error) {
      return Promise.reject(data);
    } else {
      return Promise.resolve(data);
    }
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const getCourses = (paramsObj) => async (dispatch) => {
  try {
    const result = await axiosHttp.get("/courses/list", {
      params: paramsObj,
    });
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: COURSE_LIST,
        payload: data.data,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const getCoursesList = (queryInfo) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.get("/v1/course", {
      params: queryInfo,
    });
    const { data } = result;
    console.log({ responceData: data });
    if (!data.status && data.code !== 200) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: INIT_URL,
        payload: "/coursess/list",
      });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: COURSE_LIST_SUCCESS,
        payload: { ...data.results, queryInfo },
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const getCourseInfo = (courseId) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.get("/v1/course/" + courseId);
    const { data } = result;
    if (!data.status) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: ON_HIDE_LOADER,
      });
      // dispatch({
      //   type: COURSE_INFO_SUCCESS,
      //   payload: data,
      // });

      dispatch({
        type: COURSE_INFO_SUCCESS,
        payload: data.results,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      // dispatch(
      //   userSignOut({
      //     changeMessage: true,
      //     payload: {
      //       message: response.data.message,
      //       status: response.status,
      //       error: true,
      //     },
      //   })
      // );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          message: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const setCoursesInfo = (info) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  dispatch({
    type: COURSE_INFO_SUCCESS,
    payload: info,
  });
};
export const createCourses = (infoData) => async (dispatch) => {
  console.log({ infoData });
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const result = await axiosHttp.post("/v1/course", infoData);
    const { data } = result;
    if (!data.status) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      return Promise.reject(data);
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      return Promise.resolve(data);
    }
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const courseImport = (infoData) => async (dispatch) => {
  console.log({ infoData });
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    console.log({ axiosHttp });
    const result = await axiosHttp.post("/v1/importData", infoData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      // responseType: "blob",
    });
    const { data } = result;
    return Promise.resolve(data);
    // if (data.error) {
    //   dispatch({
    //     type: ON_SHOW_MESSAGE,
    //     payload: data,
    //   });
    //   return Promise.reject(data);
    // } else {
    //   dispatch({
    //     type: ON_SHOW_MESSAGE,
    //     payload: data,
    //   });
    //   dispatch({
    //     type: COURSE_CREATE_SUCCESS,
    //     payload: { ...data.data },
    //   });
    //   dispatch({
    //     type: HIDE_DIALOG,
    //   });
    //   return Promise.resolve(data);
    // }
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const updateCourses = (infoData, queryInfo) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  const { courseId } = infoData;
  try {
    const result = await axiosHttp.put("/v1/course/" + courseId, infoData);
    const { data } = result;
    if (!data.status) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      return Promise.reject(data);
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch({
        type: COURSE_UPDATE_SUCCESS,
        payload: infoData,
      });
      dispatch({ type: INIT_URL, payload: "/course/list" });
      return Promise.resolve(infoData);
    }
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
export const deleteCourses = (infoData) => async (dispatch) => {
  dispatch({
    type: ON_SHOW_LOADER,
  });
  try {
    const { courseId, queryInfo, status } = infoData;
    const result = await axiosHttp.put("/v1/course/" + courseId, { status });
    // const result = await axiosHttp.delete("/courses", infoData);
    const { data } = result;
    if (data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      // dispatch({
      //   type: INIT_URL,
      //   payload: "/coursess/list",
      // });
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
      dispatch(getCoursesList(queryInfo));
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    if (response && response.status === 401) {
      dispatch(
        userSignOut({
          changeMessage: true,
          payload: {
            userMessage: response.data.message,
            status: response.status,
            error: true,
          },
        })
      );
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};

export const onChangeSearchName = (info) => async (dispatch) => {
  dispatch({
    type: COURSE_SEARCH_NAME,
    payload: info,
  });
};

export const onChangeFilterHandling = (info) => async (dispatch) => {
  dispatch({
    type: COURSE_FILTER_HANDLING,
    payload: info,
  });
};
export const clearCoursesInfo = () => async (dispatch) => {
  dispatch({
    type: CLEAR_COURSE_INFO,
  });
};
