import {
    PLATFORM_LIST,
    PLATFORM_CREATE_SUCCESS,
    PLATFORM_LIST_SUCCESS,
    PLATFORM_INFO_SUCCESS,
    PLATFORM_FILTER_HANDLING,
    PLATFORM_SEARCH_NAME,
    PLATFORM_UPDATE_SUCCESS,
    // PLATFORM_CATEGORY_SUCCESS,
    // PLATFORM_CATEGORY_CREATE_SUCCESS,
    // PLATFORM_CATEGORY_UPDATE_SUCCESS,
    CLEAR_PLATFORM_INFO,
    // PLATFORM_CATEGORY_INFO,
    PLATFORM_FILTER_SUCCESS,
  } from "../actionTypes/platforms";
  
  // import {
  //   COLUMN_HANDLING,
  //   _CATEGORY_DATA,
  //   _PLATFORM_FILTER_DATA,
  //   _LANGUAGE_DATA,
  //   _MERCHANT_DATA,
  // } from "../../_mocks_/platform";
  // import { COLUMN_HANDLING } from "../../_mocks_/course";
  
  const INIT_STATE = {
    // platformFilters: _PLATFORM_FILTER_DATA,
    platformList: [],
    isSuccess: false,
    platforms: [],
    platformInfo: {},
    categories: [],
    categoryInfo: {},
    queryInfo: {
      page: 1,
      size: 10,
      next: false,
    },
    totalRecords: 0,
    page: 1,
    pages: 0,
    // columnHandling: COLUMN_HANDLING,
    searchName: "",
    searchStatus: false,
    platformType: "Active",
    isUpdate: false,
    isCategory: true,
    isInfo: false,
    currentStep: 0,
    isEdit: false,
  };
  
  function PlatformReducer(state = INIT_STATE, action) {
    const { type, payload } = action;
  
    switch (type) {
      // case PLATFORM_FILTER_SUCCESS: {
      //   console.log({ payload });
      //   return {
      //     ...state,
      //     // // platformFilters: { ..._PLATFORM_FILTER_DATA, ...payload },
      //   };
      // }
      // case PLATFORM_CATEGORY_INFO: {
      //   return {
      //     ...state,
      //     categoryInfo: payload,
      //   };
      // }
      case CLEAR_PLATFORM_INFO: {
        return {
          ...state,
          platformInfo: {},
        };
      }
      case PLATFORM_LIST: {
        console.log({ payload });
        return {
          ...state,
          platforms: payload,
        };
      }
      // case PLATFORM_CATEGORY_SUCCESS: {
      //   console.log({ payload });
      //   return {
      //     ...state,
      //     categories: payload,
      //     isCategory: false,
      //   };
      // }
      // case PLATFORM_CATEGORY_CREATE_SUCCESS: {
      //   console.log({ payload });
      //   return {
      //     ...state,
      //     categories: { payload, ...state.categories },
      //     isCategory: false,
      //   };
      // }
      // case PLATFORM_CATEGORY_UPDATE_SUCCESS: {
      //   var list = state.categories.map((row) => (row._id === payload._id ? payload : row));
      //   // console.log({ payload, info });
      //   return {
      //     ...state,
      //     categories: list.filter((row) => row.active),
      //     isCategory: false,
      //   };
      // }
      case PLATFORM_LIST_SUCCESS: {
        return {
          ...state,
          searchStatus: false,
          platformList: payload.data,
          queryInfo: {
            ...payload.queryInfo,
          },
          totalRecords: payload.count,
          pages: payload.pages,
          isSuccess: false,
          isCategory: true,
          // page: payload.queryInfo.page || 0
        };
      }
  
      case PLATFORM_CREATE_SUCCESS: {
        return {
          ...state,
          platformList: [payload, ...state.platformList],
          platformInfo: payload,
          currentStep: payload.currentStep + 1,
          isSuccess: true,
          isEdit: payload && payload._id ? true : false,
        };
      }
  
      case PLATFORM_UPDATE_SUCCESS: {
        console.log({ payload });
        return {
          ...state,
          platformList: state.platformList.map(
            (obj) => (payload._id === obj._id ? payload : obj)
            // data.columnHandling.map((row) => obj.id === row)
          ),
          platformInfo: payload,
          currentStep: payload.currentStep + 1,
          isSuccess: true,
          isUpdate: true,
        };
      }
  
      case PLATFORM_INFO_SUCCESS: {
        console.log({ payload });
        return {
          ...state,
          platformInfo: payload,
          isInfo: true,
          isEdit: true,
        };
      }
      case PLATFORM_FILTER_HANDLING: {
        console.log({ payload });
        return {
          ...state,
          columnHandling: payload.columnHandling,
          platformType: payload.platformType,
        };
      }
      case PLATFORM_SEARCH_NAME: {
        console.log({ payload });
        return {
          ...state,
          searchName: payload,
          searchStatus: true,
          queryInfo: { page: 1, size: state.queryInfo.size },
        };
      }
  
      default:
        return state;
    }
  }
  
  export default PlatformReducer;
  