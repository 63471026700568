import React, { useState } from "react";
import { Select } from "antd";

const { Option } = Select;

const FloatSelect = (props) => {
  const [focus, setFocus] = useState(false);
  let {
    label,
    value,
    placeholder,
    required,
    id,
    onChange,
    options,
    showSearch,
    tag = "name",
    tag2 = "_id",
    disabled = false,
  } = props;

  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

  const requiredMark = required ? <span className="text-danger">*</span> : null;
  console.log({ value });
  return (
    <div
      className="float-label"
      id={id}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Select
        className="text-capitalize"
        mode="multiple"
        maxTagCount="responsive"
        showSearch={showSearch}
        style={{ width: "100%" }}
        value={value}
        onChange={onChange}
        optionFilterProp="children"
        // filterOption={(input, option) =>
        //   option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        // }
        // if(tag2 === "_id"){

        // }
        // filterSort={(optionA, optionB) =>
        //   optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
        // }
        disabled={disabled}
      >
        {options.map((item, key) => {
          return (
            <Option key={Math.random()} value={item[tag2]} className="text-capitalize">
              {item[tag]}
            </Option>
          );
        })}
      </Select>

      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  );
};

export default FloatSelect;
