import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
// import "../../assets/css/index.css";

const barchartdata = [
  { y: "2022", "Total Income": 0, "Total Outcome": 0 },
  { y: "2023", "Total Income": 0, "Total Outcome": 0 },
  { y: "2024", "Total Income": 0, "Total Outcome": 0 },
];
const linechartdata = [
  { y: "2022", "Total Sales": 0, "Total Revenue": 0 },
  { y: "2023", "Total Sales": 0, "Total Revenue": 0 },
  { y: "2024", "Total Sales": 0, "Total Revenue": 0 },
];

const Charts = () => {
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6 text-center">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Total Revenue</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={barchartdata}
                      margin={{
                        top: 5,
                        right: 5,
                        left: 5,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid />
                      <XAxis dataKey="y" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="Total Income" fill="#1C0BC" />
                      <Bar dataKey="Total Outcome" fill="#00A79D" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Sales Overview</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart
                      data={linechartdata}
                      margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
                    >
                      <CartesianGrid />
                      <XAxis dataKey="y" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="Total Sales"
                        stroke="#1C0BC"
                        fill="#00c5fb"
                        strokeWidth={3}
                        dot={{ r: 3 }}
                        activeDot={{ r: 7 }}
                      />
                      <Line
                        type="monotone"
                        dataKey="Total Revenue"
                        stroke="#00A79D"
                        fill="#0253cc"
                        strokeWidth={3}
                        dot={{ r: 3 }}
                        activeDot={{ r: 7 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Charts;
