export function getCourseDetailsUrl(name, id) {
  let cleanedName = name
    .replace(/[^a-zA-Z0-9\s]/g, "") // Remove special characters
    .toLowerCase() // Convert to lowercase
    .replace(/\s+/g, "-");
  return `/course/${cleanedName}/${id}`;
}
export function getCourseDetailsMetaUrl(name, id) {
  let cleanedName = name
    .replace(/[^a-zA-Z0-9\s]/g, "") // Remove special characters
    .toLowerCase() // Convert to lowercase
    .replace(/\s+/g, "-");
  return `course/${cleanedName}/${id}`;
}

export function isValidUrl(url) {
  // console.log({ url });
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // Protocol (optional)
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // Domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // Port and path (optional)
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // Query string (optional)
      "(\\#[-a-z\\d_]*)?$",
    "i" // Fragment locator (optional)
  );
  // console.log({ url: urlPattern.test(url) });
  return urlPattern.test(url);
}

export function extractDomainAndFirstSegment(url) {
  // Remove protocol and www
  const cleanedUrl = url && url !== "" ? url.replace(/^(https?:\/\/)?(www\.)?/, "") : "";

  // Extract the domain and the first segment of the path
  const domainAndPath = cleanedUrl.split("?")[0]; // Remove query parameters
  const parts = domainAndPath.split("/"); // Split by '/'

  // Join the domain and the first segment
  const result = parts.slice(0, parts.length >= 3 ? 3 : parts.length).join("/"); // domain, first path segment, second path segment

  return result;
}
