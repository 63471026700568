import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import React, { forwardRef } from "react";
// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = "", ...other }, ref) => (
  <div ref={ref} {...other}>
    <Helmet>
      <title> {title} || Choice Dekho</title>
      <meta property="og:description" content={title} />
      <meta name="description" content={`App Description ${title}`} />
      <meta name="theme-color" content="#008f68" />
    </Helmet>
    {children}
  </div>
));

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
