import {
  STAFF_LIST,
  STAFF_CREATE_SUCCESS,
  STAFF_LIST_SUCCESS,
  STAFF_INFO_SUCCESS,
  STAFF_FILTER_HANDLING,
  STAFF_SEARCH_NAME,
  STAFF_UPDATE_SUCCESS,
  CLEAR_STAFF_INFO,
  STAFF_ROLE_LIST_SUCCESS,
  STAFF_PERMISSION_FILTER_SUCCESS,
} from "../actionTypes/staffs";

const INIT_STATE = {
  // platformFilters: _PLATFORM_FILTER_DATA,
  staffList: [],
  roleList: [],
  permissionList: [],
  isSuccess: false,
  staffs: [],
  staffInfo: {},
  categories: [],
  categoryInfo: {},
  queryInfo: {
    page: 1,
    size: 10,
    next: false,
  },
  totalRecords: 0,
  page: 1,
  pages: 0,
  // columnHandling: COLUMN_HANDLING,
  searchName: "",
  searchStatus: false,
  staffType: "Active",
  isUpdate: false,
  isCategory: true,
  isInfo: false,
  currentStep: 0,
  isEdit: false,
};

function StaffReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case STAFF_ROLE_LIST_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        roleList: payload,
      };
    }
    case STAFF_PERMISSION_FILTER_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        permissionList: payload,
      };
    }
    case CLEAR_STAFF_INFO: {
      return {
        ...state,
        staffInfo: {},
      };
    }
    case STAFF_LIST: {
      console.log({ payload });
      return {
        ...state,
        staffs: payload,
      };
    }
    // case PLATFORM_CATEGORY_SUCCESS: {
    //   console.log({ payload });
    //   return {
    //     ...state,
    //     categories: payload,
    //     isCategory: false,
    //   };
    // }
    // case PLATFORM_CATEGORY_CREATE_SUCCESS: {
    //   console.log({ payload });
    //   return {
    //     ...state,
    //     categories: { payload, ...state.categories },
    //     isCategory: false,
    //   };
    // }
    // case PLATFORM_CATEGORY_UPDATE_SUCCESS: {
    //   var list = state.categories.map((row) => (row._id === payload._id ? payload : row));
    //   // console.log({ payload, info });
    //   return {
    //     ...state,
    //     categories: list.filter((row) => row.active),
    //     isCategory: false,
    //   };
    // }
    case STAFF_LIST_SUCCESS: {
      return {
        ...state,
        searchStatus: false,
        staffList: payload.data,
        queryInfo: {
          ...payload.queryInfo,
        },
        totalRecords: payload.count,
        pages: payload.pages,
        isSuccess: false,
        isCategory: true,
        // page: payload.queryInfo.page || 0
      };
    }

    case STAFF_CREATE_SUCCESS: {
      return {
        ...state,
        staffList: [payload, ...state.staffList],
        staffInfo: payload,
        currentStep: payload.currentStep + 1,
        isSuccess: true,
        isEdit: payload && payload._id ? true : false,
      };
    }

    case STAFF_UPDATE_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        staffList: state.staffList.map(
          (obj) => (payload._id === obj._id ? payload : obj)
          // data.columnHandling.map((row) => obj.id === row)
        ),
        staffInfo: payload,
        currentStep: payload.currentStep + 1,
        isSuccess: true,
        isUpdate: true,
      };
    }

    case STAFF_INFO_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        staffInfo: payload,
        isInfo: true,
        isEdit: true,
      };
    }
    case STAFF_FILTER_HANDLING: {
      console.log({ payload });
      return {
        ...state,
        columnHandling: payload.columnHandling,
        staffType: payload.staffType,
      };
    }
    case STAFF_SEARCH_NAME: {
      console.log({ payload });
      return {
        ...state,
        searchName: payload,
        searchStatus: true,
        queryInfo: { page: 1, size: state.queryInfo.size },
      };
    }

    default:
      return state;
  }
}

export default StaffReducer;
