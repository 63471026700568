import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Avatar_02, headerlogo, ProfileIconFill } from "../../Routes/ImagePath";
import { Link } from "react-router-dom";
import { apiURL } from "../../utils/config";
const ProfileView = (props) => {
  const { user } = useSelector((state) => ({
    user: state.auth.user,
  }));
  return (
    <div className="mt-2">
      <div className="profile-img-wrap edit-img">
        <Link to="/profile">
          <img alt="" src={user && user.image ? user.image : ProfileIconFill} />
        </Link>
      </div>
      <div className="profile-info text-center">
        <Link to="/profile">
          <h3 className="user-name m-t-0 mb-0">{user.name || "Admin"}</h3>
        </Link>
        <h6 className="text-muted">{(user.role && user.role.name) || ""}</h6>
      </div>
    </div>
  );
};

ProfileView.propTypes = {};

export default ProfileView;
