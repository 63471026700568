export const CATEGORY_CREATE = "CATEGORY_CREATE";
export const CATEGORY_CREATE_SUCCESS = "CATEGORY_CREATE_SUCCESS";
export const CATEGORY_CREATE_ERROR = "CATEGORY_CREATE_ERROR";
export const CATEGORY_LIST = "CATEGORY_LIST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_ERROR = "CATEGORY_LIST_ERROR";
export const CATEGORY_INFO = "CATEGORY_INFO";
export const CATEGORY_INFO_SUCCESS = "CATEGORY_INFO_SUCCESS";
export const CATEGORY_INFO_ERROR = "CATEGORY_INFO_ERROR";
export const CATEGORY_UPDATE_SUCCESS = "CATEGORY_UPDATE_SUCCESS";
export const CATEGORY_UPDATE_ERROR = "CATEGORY_UPDATE_ERROR";
export const CATEGORY_DELETE_SUCCESS = "CATEGORY_DELETE_SUCCESS";
export const CATEGORY_FILTER_HANDLING = "CATEGORY_FILTER_HANDLING";
export const CATEGORY_SEARCH_NAME = "CATEGORY_SEARCH_NAME";
export const CATEGORYE_CATEGORY_SUCCESS = "CATEGORY_CATEGORY_SUCCESS";
export const CATEGORY_CATEGORY_CREATE_SUCCESS = "CATEGORY_CATEGORY_CREATE_SUCCESS";
export const CATEGORY_CATEGORY_UPDATE_SUCCESS = "CATEGORY_CATEGORY_UPDATE_SUCCESS";
export const CLEAR_CATEGORY_INFO = "CLEAR_CATEGORY_INFO";
export const CATEGORY_CATEGORY_INFO = "CATEGORY_CATEGORY_INFO";

export const CATEGORY_FILTER_SUCCESS = "CATEGORY_FILTER_SUCCESS";
