import {
    COURSETYPE_LIST,
    COURSETYPE_CREATE_SUCCESS,
    COURSETYPE_LIST_SUCCESS,
    COURSETYPE_INFO_SUCCESS,
    COURSETYPE_FILTER_HANDLING,
    COURSETYPE_SEARCH_NAME,
    COURSETYPE_UPDATE_SUCCESS,
    // COURSETYPE_COURSETYPE_SUCCESS,
    // COURSETYPE_COURSETYPE_CREATE_SUCCESS,
    // COURSETYPE_COURSETYPE_UPDATE_SUCCESS,
    CLEAR_COURSETYPE_INFO,
    // COURSETYPE_COURSETYPE_INFO,
    COURSETYPE_FILTER_SUCCESS,
  } from "../actionTypes/coursetypes";
  
  // import {
  //   COLUMN_HANDLING,
  //   _COURSETYPE_DATA,
  //   _COURSETYPE_FILTER_DATA,
  //   _LANGUAGE_DATA,
  //   _MERCHANT_DATA,
  // } from "../../_mocks_/coursetype";
  // import { COLUMN_HANDLING } from "../../_mocks_/course";
  
  const INIT_STATE = {
    // coursetypeFilters: _COURSETYPE_FILTER_DATA,
    coursetypeList: [],
    isSuccess: false,
    coursetypes: [],
    coursetypeInfo: {},
    coursetypes: [],
    coursetypeInfo: {},
    queryInfo: {
      page: 1,
      size: 10,
      next: false,
    },
    totalRecords: 0,
    page: 1,
    pages: 0,
    // columnHandling: COLUMN_HANDLING,
    searchName: "",
    searchStatus: false,
    coursetypeType: "Active",
    isUpdate: false,
    isCoursetype: true,
    isInfo: false,
    currentStep: 0,
    isEdit: false,
  };
  
  function CoursetypeReducer(state = INIT_STATE, action) {
    const { type, payload } = action;
  
    switch (type) {
      // case PLATFORM_FILTER_SUCCESS: {
      //   console.log({ payload });
      //   return {
      //     ...state,
      //     // // platformFilters: { ..._PLATFORM_FILTER_DATA, ...payload },
      //   };
      // }
      // case PLATFORM_COURSETYPE_INFO: {
      //   return {
      //     ...state,
      //     coursetypeInfo: payload,
      //   };
      // }
      case CLEAR_COURSETYPE_INFO: {
        return {
          ...state,
          coursetypeInfo: {},
        };
      }
      case COURSETYPE_LIST: {
        console.log({ payload });
        return {
          ...state,
          coursetypes: payload,
        };
      }
      // case PLATFORM_COURSETYPE_SUCCESS: {
      //   console.log({ payload });
      //   return {
      //     ...state,
      //     coursetypes: payload,
      //     isCoursetype: false,
      //   };
      // }
      // case PLATFORM_COURSETYPE_CREATE_SUCCESS: {
      //   console.log({ payload });
      //   return {
      //     ...state,
      //     coursetypes: { payload, ...state.coursetypes },
      //     isCoursetype: false,
      //   };
      // }
      // case PLATFORM_COURSETYPE_UPDATE_SUCCESS: {
      //   var list = state.coursetypes.map((row) => (row._id === payload._id ? payload : row));
      //   // console.log({ payload, info });
      //   return {
      //     ...state,
      //     coursetypes: list.filter((row) => row.active),
      //     isCoursetype: false,
      //   };
      // }
      case COURSETYPE_LIST_SUCCESS: {
        return {
          ...state,
          searchStatus: false,
          coursetypeList: payload.data,
          queryInfo: {
            ...payload.queryInfo,
          },
          totalRecords: payload.count,
          pages: payload.pages,
          isSuccess: false,
          isCoursetype: true,
          // page: payload.queryInfo.page || 0
        };
      }
  
      case COURSETYPE_CREATE_SUCCESS: {
        return {
          ...state,
          coursetypeList: [payload, ...state.coursetypeList],
          coursetypeInfo: payload,
          currentStep: payload.currentStep + 1,
          isSuccess: true,
          isEdit: payload && payload._id ? true : false,
        };
      }
  
      case COURSETYPE_UPDATE_SUCCESS: {
        console.log({ payload });
        return {
          ...state,
          coursetypeList: state.coursetypeList.map(
            (obj) => (payload._id === obj._id ? payload : obj)
            // data.columnHandling.map((row) => obj.id === row)
          ),
          coursetypeInfo: payload,
          currentStep: payload.currentStep + 1,
          isSuccess: true,
          isUpdate: true,
        };
      }
  
      case COURSETYPE_INFO_SUCCESS: {
        console.log({ payload });
        return {
          ...state,
          coursetypeInfo: payload,
          isInfo: true,
          isEdit: true,
        };
      }
      case COURSETYPE_FILTER_HANDLING: {
        console.log({ payload });
        return {
          ...state,
          columnHandling: payload.columnHandling,
          coursetypeType: payload.coursetypeType,
        };
      }
      case COURSETYPE_SEARCH_NAME: {
        console.log({ payload });
        return {
          ...state,
          searchName: payload,
          searchStatus: true,
          queryInfo: { page: 1, size: state.queryInfo.size },
        };
      }
  
      default:
        return state;
    }
  }
  
  export default CoursetypeReducer;
  