import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useFormik, Form, FormikProvider } from "formik";

import { createStaffs, getStaffsList, updateStaffs } from "../../appRedux/actions/staffs";
import { hideDialogInner, showDialogInner } from "../../appRedux/actions/common";

const INITIAL_STATE = {
  email: "",

  password: "",
  confirmPassword: "",
};
function FormView(props) {
  const { staffInfo, roleList } = useSelector((state) => ({
    staffInfo: state.staffs.staffInfo,
    roleList: state.staffs.roleList,
  }));

  const { isUpdate } = props;
  const [formInfo, setFormInfo] = useState(INITIAL_STATE);
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(hideMessage());
    if (isUpdate && staffInfo && staffInfo._id) {
      setFormInfo({
        ...INITIAL_STATE,
        ...staffInfo,
        password: "",
      });
    } else {
      //console.log("");
      setFormInfo({
        ...INITIAL_STATE,
      });
    }
  }, [isUpdate, staffInfo]);

  const FormSchema = Yup.object().shape({
    email: Yup.string().required("Email Id is required."),
    password: Yup.string()
      .required("Password is required.")
      .min(6, "Password must be at least 6 characters long.")
      .max(20, "Password must be at most 20 characters long."),

    confirmPassword: Yup.string().when("password", {
      is: (password) => password && password.length > 0,
      then: (schema) =>
        schema
          .oneOf([Yup.ref("password"), null], "Passwords must match.")
          .required("Confirm password is required."),
      otherwise: (schema) => schema,
    }),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInfo,
    validationSchema: FormSchema,
    onSubmit: (data) => {
      console.log({ data });
      if (isUpdate && staffInfo && staffInfo._id) {
        dispatch(
          updateStaffs({
            id: staffInfo._id,
            email: data.email,
            password: isUpdate ? undefined : data.password,
          })
        ).then(() => {
          const idsString = roleList.map((role) => role._id).join(",");
          dispatch(getStaffsList({ role: idsString }));
          dispatch(hideDialogInner());
        });
      } else {
        dispatch(
          createStaffs({
            ...data,
          })
        ).then(() => {
          const idsString = roleList.map((role) => role._id).join(",");
          dispatch(getStaffsList({ role: idsString }));
          dispatch(hideDialogInner());
        });
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;
  console.log({ errors, touched, values });

  return (
    <FormikProvider value={formik}>
      <Form
        id="contactForm"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        className="signin-form"
      >
        {/* <div>
          <label className="col-form-label">
            <b>Staff Details:</b>
          </label>
        </div> */}
        <div className="row m-0">
          <div className="col-md-12">
            <div className="form-group ">
              <label className="col-form-label">
                Reset Password
                <span className="text-danger">*</span>
              </label>
              <input className="form-control" type="text" {...getFieldProps("password")} />
              <p className="error-msg text-danger">{touched.password && errors.password}</p>
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group ">
              <label className="col-form-label">
                Confirm Password{" "}
                {values.password && values.password !== "" ? (
                  <span className="text-danger">*</span>
                ) : (
                  ""
                )}
              </label>
              <input className="form-control" type="text" {...getFieldProps("confirmPassword")} />
              <p className="error-msg text-danger">
                {touched.confirmPassword && errors.confirmPassword}
              </p>
            </div>
          </div>
        </div>
        <div className="submit-section mt-3 mb-3">
          <button type="submit" className="btn btn-primary submit-btn">
            {isUpdate && staffInfo && staffInfo._id ? "Reset Password" : "Submit"}
          </button>
        </div>
      </Form>
    </FormikProvider>
  );
}

export default FormView;
