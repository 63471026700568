import { DASHBOARD_INFO } from "../actionTypes/dashboard";
import { ON_SHOW_MESSAGE, INIT_URL, ON_HIDE_MESSAGE } from "../actionTypes/common";
// import AllDataService from '../services/all.service';
import axiosHttp from "../services/axiosCommon";

export const getDashboard = (queryInfo) => async (dispatch) => {
  try {
    const result = await axiosHttp.get("/v1/count", queryInfo);
    const { data } = result;

    if (!data.status || data.code !== 200) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: data,
      });
    } else {
      dispatch({
        type: DASHBOARD_INFO,
        payload: data.results,
      });
    }

    return Promise.resolve(data);
  } catch (err) {
    const { response } = err;
    const { data } = response;
    if (data && data.error) {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: { ...data },
      });
    } else {
      dispatch({
        type: ON_SHOW_MESSAGE,
        payload: {
          userMessage: "Some error has occurred",
          status: 500,
          error: true,
        },
      });
    }
    return Promise.reject(err);
  }
};
