import {
  MODE_LIST,
  MODE_CREATE_SUCCESS,
  MODE_LIST_SUCCESS,
  MODE_INFO_SUCCESS,
  MODE_FILTER_HANDLING,
  MODE_SEARCH_NAME,
  MODE_UPDATE_SUCCESS,
  // MODE_MODE_SUCCESS,
  // MODE_MODE_CREATE_SUCCESS,
  // MODE_MODE_UPDATE_SUCCESS,
  CLEAR_MODE_INFO,
  // MODE_MODE_INFO,
  MODE_FILTER_SUCCESS,
} from "../actionTypes/mode";

// import {
//   COLUMN_HANDLING,
//   _MODE_DATA,
//   _MODE_FILTER_DATA,
//   _LANGUAGE_DATA,
//   _MERCHANT_DATA,
// } from "../../_mocks_/coursetype";
// import { COLUMN_HANDLING } from "../../_mocks_/course";

const INIT_STATE = {
  // coursetypeFilters: _COURSETYPE_FILTER_DATA,
  modeList: [],
  isSuccess: false,
  modes: [],
  modeInfo: {},
  modes: [],
  modeInfo: {},
  queryInfo: {
    page: 1,
    size: 10,
    next: false,
  },
  totalRecords: 0,
  page: 1,
  pages: 0,
  // columnHandling: COLUMN_HANDLING,
  searchName: "",
  searchStatus: false,
  modeType: "Active",
  isUpdate: false,
  isMode: true,
  isInfo: false,
  currentStep: 0,
  isEdit: false,
};

function modeReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    // case PLATFORM_FILTER_SUCCESS: {
    //   console.log({ payload });
    //   return {
    //     ...state,
    //     // // platformFilters: { ..._PLATFORM_FILTER_DATA, ...payload },
    //   };
    // }
    // case PLATFORM_COURSETYPE_INFO: {
    //   return {
    //     ...state,
    //     coursetypeInfo: payload,
    //   };
    // }
    case CLEAR_MODE_INFO: {
      return {
        ...state,
        modeInfo: {},
      };
    }
    case MODE_LIST: {
      console.log({ payload });
      return {
        ...state,
        modes: payload,
      };
    }
    // case PLATFORM_COURSETYPE_SUCCESS: {
    //   console.log({ payload });
    //   return {
    //     ...state,
    //     coursetypes: payload,
    //     isCoursetype: false,
    //   };
    // }
    // case PLATFORM_COURSETYPE_CREATE_SUCCESS: {
    //   console.log({ payload });
    //   return {
    //     ...state,
    //     coursetypes: { payload, ...state.coursetypes },
    //     isCoursetype: false,
    //   };
    // }
    // case PLATFORM_COURSETYPE_UPDATE_SUCCESS: {
    //   var list = state.coursetypes.map((row) => (row._id === payload._id ? payload : row));
    //   // console.log({ payload, info });
    //   return {
    //     ...state,
    //     coursetypes: list.filter((row) => row.active),
    //     isCoursetype: false,
    //   };
    // }
    case MODE_LIST_SUCCESS: {
      return {
        ...state,
        searchStatus: false,
        modeList: payload.data,
        queryInfo: {
          ...payload.queryInfo,
        },
        totalRecords: payload.count,
        pages: payload.pages,
        isSuccess: false,
        isMode: true,
        // page: payload.queryInfo.page || 0
      };
    }

    case MODE_CREATE_SUCCESS: {
      return {
        ...state,
        modeList: [payload, ...state.modeList],
        modeInfo: payload,
        currentStep: payload.currentStep + 1,
        isSuccess: true,
        isEdit: payload && payload._id ? true : false,
      };
    }

    case MODE_UPDATE_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        modeList: state.modeList.map(
          (obj) => (payload._id === obj._id ? payload : obj)
          // data.columnHandling.map((row) => obj.id === row)
        ),
        modeInfo: payload,
        currentStep: payload.currentStep + 1,
        isSuccess: true,
        isUpdate: true,
      };
    }

    case MODE_INFO_SUCCESS: {
      console.log({ payload });
      return {
        ...state,
        modeInfo: payload,
        isInfo: true,
        isEdit: true,
      };
    }
    case MODE_FILTER_HANDLING: {
      console.log({ payload });
      return {
        ...state,
        columnHandling: payload.columnHandling,
        modeType: payload.modeType,
      };
    }
    case MODE_SEARCH_NAME: {
      console.log({ payload });
      return {
        ...state,
        searchName: payload,
        searchStatus: true,
        queryInfo: { page: 1, size: state.queryInfo.size },
      };
    }

    default:
      return state;
  }
}

export default modeReducer;
