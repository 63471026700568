import React from "react";
import { Select } from "antd";
// import "antd/dist/antd.css";
// import "../antdstyle.css";

const { Option } = Select;

const SelectCustom = (props) => {
  const { value, onChange, options, showSearch, placeholder, className, disabled, size } = props;
  console.log({ props });
  return (
    <div id="selectCustom" className={className}>
      <Select
        size={size}
        showSearch={showSearch}
        style={{ width: "100%" }}
        placeholder={placeholder}
        value={value && value !== "" && value}
        onChange={onChange}
        optionFilterProp="children"
        // filterOption={(input, option) =>
        //   option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        // }
        // filterSort={(optionA, optionB) =>
        //   optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
        // }
        disabled={disabled}
      >
        {options.map((item, key) => {
          return (
            <Option key={Math.random()} value={item}>
              {item}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default SelectCustom;
