import React, { useState } from "react";
import { Upload, message } from "antd";
import { apiURL } from "../../utils/config";
import "./uploadImage.scss";
const UploadImage = ({ fileList, setFileList, imageUrl = null, setImageUrl, aspect = 4 / 2 }) => {
  // const [imageUrl, setImageUrl] = useState(null);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  const onDelete = async (file) => {
    console.log({ file });
    setFileList([]);
    setImageUrl("");
    // try {
    //   await axios.post(`${apiURL}/v1/course/deleteImage`, { fileId: file.uid }, {
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //   });
    //   message.success(`${file.name} file deleted successfully.`);
    //   // Optionally, you can also remove the file from the fileList state
    //   setFileList(fileList.filter(f => f.uid !== file.uid));
    // } catch (error) {
    //   console.error("Delete Error:", error);
    //   message.error("Failed to delete the file.");
    // }
  };
  return (
    <Upload
      name={"image"}
      action={`${apiURL}/v1/course/imageUpload`}
      listType="picture-card"
      fileList={fileList}
      onChange={onChange}
      onPreview={onPreview}
      onRemove={onDelete}
      onSuccess={(response, file) => {
        console.log("onSuccess response:", response);
        if (response && response.status === true) {
          setImageUrl(response.results);
          message.success(`${file.name} file uploaded successfully.`);
        }
      }}
      onError={(error) => {
        console.log("onError:", error);
        message.error("Upload failed.");
      }}
    >
      {fileList.length < 1 && "+ Upload"}
    </Upload>
  );
};
export default UploadImage;
