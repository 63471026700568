import React from "react";

import { connect, useDispatch, useSelector } from "react-redux";
import { Table, Tag } from "antd";
import ReactStars from "react-stars";
// import "antd/dist/antd.css";
// import { itemRender, onShowSizeChange } from "../../paginationfunction";

import { Link } from "react-router-dom";
import { EditInfo, DeleteModal } from ".";
import { apiURL } from "../../utils/config";
// import { getCourseOwners } from "../../appRedux/actions/courseOwners";
import { Avatar_02 } from "../../Routes/ImagePath";
import moment from "moment";
import { currencyConverter } from "../../utils/currency";
import { getLanguagesList } from "../../appRedux/actions/languages";



function ListView(props) {
  const dispatch = useDispatch();
  const { languageList, queryInfo, totalRecords, selectedCurrency, currencyData } = useSelector(
    (state) => ({
      languageList: state.languages.languageList,
      queryInfo: state.languages.queryInfo,
      totalRecords: state.languages.totalRecords,
      selectedCurrency: state.common.selectedCurrency,
      currencyData: state.common.currencyData,
    })
  );

  const handleTableChange = (pagination, filters, sorter) => {
    console.log({ pagination, filters, sorter });
    const { current, pageSize } = pagination;
    dispatch(
      getLanguagesList({
        ...queryInfo,
        page: current,
        size: pageSize,
      })
    );
  };

  const columns = [
    {
      title: "Language Name",
      dataIndex: "name",
      render: (name, record) => (
        <h2 className="table-avatar" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          <Link to={`/language/${record._id}`}>{name}</Link>
        </h2>
      ),
      // width: "30px",
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    
    {
      title: "Language Code",
      dataIndex: "code",
      render: (code, record) => (
        <h2 className="table-avatar" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          <Link to={`/language/${record._id}`}>{code}</Link>
        </h2>
      ),
    },


    {
      title: "Action",
      align: "center",
      // fixed: "right",
      render: (text, record) => (
        <div className="dropdown dropdown-action text-center">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <EditInfo info={record} key={"edit_info"} />

            <DeleteModal info={record} />
          </div>
        </div>
      ),
    },
  ];

  const itemRender = (_, type, originalElement) => {
    console.log(type, "a", originalElement);
    // if (type === "prev") {
    //   return (
    //     <i
    //       class="fa fa-angle-double-left"
    //       data-bs-toggle="tooltip"
    //       title="fa fa-angle-double-left"
    //     ></i>
    //   );
    // }
    // if (type === "next") {
    //   return (
    //     <i
    //       class="fa fa-angle-double-right"
    //       data-bs-toggle="tooltip"
    //       title="fa fa-angle-double-right"
    //     ></i>
    //   );
    // }
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };
  return (
    <div className="row m-0 mt-3">
      <div className="col-md-12 p-0" id="table-responsive">
        <div className="table-responsive" id="setMinheight">
          <Table
            className="table-striped"
            pagination={{
              total: totalRecords,
              pageSizeOptions: ["5", "10", "20", "50"],
              showSizeChanger: true,
              showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              onShowSizeChange: (current, pageSize) => {
                console.log({ current, pageSize });
                dispatch(
                  getLanguagesList({
                    ...queryInfo,
                    page: current,
                    size: pageSize,
                  })
                );
              }, //onShowSizeChange,
              itemRender: itemRender,
            }}
            onChange={(pagination, filters, sorter) =>
              handleTableChange(pagination, filters, sorter)
            }
            style={{ overflowX: "auto" }}
            columns={columns}
            // bordered
            dataSource={languageList}
            rowKey={(record) => record._id}
            // onChange={this.handleTableChange}
          />
        </div>
      </div>
    </div>
  );
}

export default ListView;
