import React, { useEffect, useState } from "react";
import { Avatar_02, ProfileIconFill } from "../../Routes/ImagePath";
import { Link } from "react-router-dom";
import { PagesHeader } from "../../components/Courses";
import { useSelector } from "react-redux";
import { EditInfo } from "../../components/Profile";
import moment from "moment";
const Profile = () => {
  const { user } = useSelector((state) => ({
    user: state.auth.user,
  }));
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    setUserInfo(user);
  }, [user]);

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <PagesHeader
            pageTitle={"Profile"}
            homePageUrl="/"
            homePageText="Dashboard"
            activePageText={"Profile"}
          />
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">
                        <Link to="#">
                          <img
                            alt="User Image"
                            src={user && user.image ? user.image : ProfileIconFill}
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="profile-info-left">
                            <h3 className="user-name m-t-0 mb-0">{userInfo.name}</h3>
                            <h6 className="text-muted">
                              {userInfo.role ? userInfo.role.name : ""}
                            </h6>
                            <small className="text-muted">{userInfo.jobTitle}</small>
                            <div className="staff-id">Employee ID : {userInfo.employeeId}</div>
                            <div className="small doj text-muted">
                              Date of Join : {moment(userInfo.created_at).format("Do, MMM yyy")}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <ul className="personal-info">
                            <li>
                              <div className="title">Phone:</div>
                              <div className="text">
                                <Link to={`tel:${userInfo.contactNo}`}>{userInfo.contactNo}</Link>
                              </div>
                            </li>
                            <li>
                              <div className="title">Email:</div>
                              <div className="text">
                                <Link to={`mailto:${userInfo.email}`}>{userInfo.email}</Link>
                              </div>
                            </li>
                            <li>
                              <div className="title">Birthday:</div>
                              <div className="text">
                                {userInfo.dateOfBirth
                                  ? moment(userInfo.dateOfBirth).format("Do, MMM yyy")
                                  : ""}
                              </div>
                            </li>
                            <li>
                              <div className="title">Address:</div>
                              <div className="text">{userInfo.address}</div>
                            </li>
                            <li>
                              <div className="title">Gender:</div>
                              <div className="text">{userInfo.gender}</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="pro-edit">
                      {/* <Link
                        data-bs-target="#profile_info"
                        data-bs-toggle="modal"
                        className="edit-icon"
                        to="#"
                      >
                        <i className="fa-solid fa-pencil"></i>
                      </Link> */}
                      <EditInfo info={userInfo} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
